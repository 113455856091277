import React, { useEffect } from 'react';
import classNames from 'classnames';
import css from './index.module.scss';
import styled from 'styled-components';

export const SecretAnimation = (props) => {
  const { animeFlag, setAnimeFlag } = props;
  const delay = 0.2;
  useEffect(() => {
    setAnimeFlag(1);
  }, []);

  return (
    <div className={css.secretAnimation}>
      {animeFlag == 1 ? (
        <DisplaySlowly
          txt="秘密のページへようこそ"
          delay={delay}
          onAnimationEnd={() => {
            setAnimeFlag(animeFlag + 1);
          }}
        />
      ) : animeFlag > 1 ? (
        <span className={css.animationText}>秘密のページへようこそ</span>
      ) : (
        ''
      )}
      {animeFlag == 2 ? (
        <DisplaySlowly
          txt="隠しページに気付いたあなたに"
          delay={delay}
          onAnimationEnd={() => {
            setAnimeFlag(animeFlag + 1);
          }}
        />
      ) : animeFlag > 2 ? (
        <span className={css.animationText}>隠しページに気付いたあなたに</span>
      ) : (
        ''
      )}
      {animeFlag == 3 ? (
        <DisplaySlowly
          txt="ベーネレガーロからの挑戦状を送ります。"
          delay={delay}
          onAnimationEnd={() => {
            setAnimeFlag(animeFlag + 1);
          }}
        />
      ) : animeFlag > 3 ? (
        <span className={css.animationText}>ベーネレガーロからの挑戦状を送ります。</span>
      ) : (
        ''
      )}
      {animeFlag == 4 ? (
        <DisplaySlowly
          txt="次の問題にチャレンジして、素敵なティラミスをGETしよう！"
          delay={delay}
          onAnimationEnd={() => {
            setAnimeFlag(animeFlag + 1);
          }}
        />
      ) : animeFlag > 4 ? (
        <span className={css.animationText}>次の問題にチャレンジして、素敵なティラミスをGETしよう！</span>
      ) : (
        ''
      )}
    </div>
  );
};

const AnimationWrap = styled.div`
  padding-top: 10vw;
  ${(props) =>
    props.txt_lst
      .map(
        (_, index) => `
  span:nth-child(${index + 1}) {
  animation-delay: ${(index + 1) * props.delay}s;
}
`
      )
      .join('')}
  ${(props) =>
    `
    opacity: 1;
    animation: AnimationWrap ${props.txt_lst.length * props.delay + 0.5}s forwards;
    `}
`;

const keyFrames = `
@keyframes AnimationWrap {
  0%, 90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
`;

const DisplaySlowly = React.memo(function DisplaySlowly(props) {
  const { txt, delay, onAnimationEnd } = props;
  const txt_lst = txt.split('');
  const txt_map = txt_lst.map((x, key) => (
    <span
      className={classNames(css.animateSlowly)}
      key={key}
      onAnimationEnd={
        key === txt_lst.length - 1
          ? () => {
              onAnimationEnd?.();
            }
          : undefined
      }
    >
      {x}
    </span>
  ));
  return (
    <>
      <AnimationWrap txt_lst={txt_lst} delay={delay} className={css.animateSlowly3}>
        {txt_map}
      </AnimationWrap>
      <style>{keyFrames}</style>
    </>
  );
});
