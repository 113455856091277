import classNames from 'classnames';
import managementCss from './../index.module.scss';

// MEMO: 左側のメニュータブ
export const SelectTab = (props) => {
  const tabs = {
    order: '受注管理',
    customer: '顧客管理',
    marketing: 'マーケティング管理',
    item: '商品管理',
    analysis: '分析管理',
    shop: 'ショップ管理',
    application: 'アプリ管理',
  };
  return (
    <div
      className={classNames(managementCss.tab)}
      onClick={() => {
        props.setTab(props.name);
      }}
    >
      {tabs[props.name]}
    </div>
  );
};
