import { BoxButton, FormHeader, InputBox, InputBox2, InputBoxHalf2, PullDown, TextButton } from './components';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { deliveryState, firstViewState } from './atoms';
import classNames from 'classnames';
import css from '../index.module.scss';
import { errorMessage } from './common';
import styled from 'styled-components';
import { validationExpressions, formatPhoneNum, insertHyphen, insertTwoHyphens } from './form/form';
import { searchPostalCode } from './utils/SearchPostalCode';

const InputAccountInfoCon = styled.div``;
const AddressInfoForm = styled.form`
  padding: 0 24px;
`;
const BottomButtons = styled.div`
  padding: 24px 0;
`;

export function AddressEdit() {
  const navigateToDelivery = useNavigate();
  const [address, setAddress] = useState('郵便番号を入力してください');

  // ハイフン挿入用state
  const [postalCodeInput, setPostalCodeInput] = useState('');
  const [phoneNumInput, setPhoneNumInput] = useState('');

  //事業所番地自動入力用State
  const [address1Input, setAddress1Input] = useState('');

  //ふりがな自動挿入用state
  const [fNameKanaInput, setFNameKanaInput] = useState('');
  const [lNameKanaInput, setLNameKanaInput] = useState('');

  const setKana = {
    fNameKana: function (value) {
      setFNameKanaInput(value);
    },
    lNameKana: function (value) {
      setLNameKanaInput(value);
    },
  };

  const [fNameValidError, setFNameValidError] = useState(false);
  const [lNameValidError, setLNameValidError] = useState(false);
  const [fNameKanaValidError, setFNameKanaValidError] = useState(false);
  const [lNameKanaValidError, setLNameKanaValidError] = useState(false);

  const [phoneNumValidError, setPhoneNumValidError] = useState(false);
  const [postalCodeValidError, setPostalCodeValidError] = useState(false);

  const validError = {
    fName: function (isValid) {
      setFNameValidError(isValid);
    },
    lName: function (isValid) {
      setLNameValidError(isValid);
    },
    fNameKana: function (isValid) {
      setFNameKanaValidError(isValid);
    },
    lNameKana: function (isValid) {
      setLNameKanaValidError(isValid);
    },
    phoneNum: function (isValid) {
      setPhoneNumValidError(isValid);
    },
    postalCode: function (isValid) {
      setPostalCodeValidError(isValid);
    },
  };

  const [delivery, setDelivery] = useRecoilState(deliveryState);

  const [form, setForm] = useState(
    delivery.edit !== null
      ? {
          contents: delivery.addresses[delivery.edit],
        }
      : {
          contents: {
            fName: {},
            lName: {},
            fNameKana: {},
            lNameKana: {},
            phoneNum: {},
            postalCode: {},
            address1: {},
            address2: { isValid: true },
            address3: { isValid: true },
          },
        }
  );

  const validatePostalCode = async (value, dataName) => {
    try {
      const res = await searchPostalCode(value);
      if (res === null) {
        setAddress('郵便番号が正しくありません');
      } else {
        if (res.street_address === null) {
          updateValidly2(dataName, value, res.prefecture + res.city + res.suburb);
        } else {
          updatePostalCodeAndAddress1(value, res.prefecture + res.city + res.suburb, res.street_address);
          setAddress1Input(res.street_address);
        }
        validError[dataName](false);
        setAddress(res.prefecture + res.city + res.suburb);
      }
    } catch (error) {
      validError[dataName](true);
      //送信ボタン無効化
      setForm({
        ...form,
        isValid: false,
        contents: {
          ...form.contents,
          [dataName]: {
            value: value,
            value2: null,
            isValid: false,
          },
        },
      });
      validError[dataName](true);
      setAddress('郵便番号を入力してください');
    }
  };

  useEffect(() => {
    if (form.isValid) return;
    // 全ての項目が有効ならフォーム自体の判定を有効にする
    if (
      Object.keys(form.contents).every(function (key) {
        return form.contents[key]['isValid'];
      })
    ) {
      setForm({ ...form, isValid: true });
    }
  }, [form]);

  // 有効な場合にする処理
  const updateValidly = (dataName, value) => {
    // 新しくフォームのstateに登録したいデータ（stateは上書きしかできないので
    // 前のオブジェクトを展開して変更箇所だけ更新するための記述）
    const validIncludedAfter = {
      ...form,
      contents: {
        ...form.contents,
        [dataName]: {
          value: value,
          isValid: true,
        },
      },
    };
    // 新しい値に更新
    setForm(validIncludedAfter);
  };

  const updateValidly2 = (dataName, value, value2) => {
    // 新しくフォームのstateに登録したいデータ（stateは上書きしかできないので
    // 前のオブジェクトを展開して変更箇所だけ更新するための記述）
    const validIncludedAfter = {
      ...form,
      contents: {
        ...form.contents,
        [dataName]: {
          value: value,
          value2: value2,
          isValid: true,
        },
      },
    };
    // 新しい値に更新
    setForm(validIncludedAfter);
  };

  //MEMO:
  //郵便番号APIのレスポンスに番地が含まれていた場合に、form state内の
  //郵便番号、都道府県～町名、番地を同時に更新するための関数です。
  const updatePostalCodeAndAddress1 = (value, value2, value3) => {
    // 新しくフォームのstateに登録したいデータ（stateは上書きしかできないので
    // 前のオブジェクトを展開して変更箇所だけ更新するための記述）
    const validIncludedAfter = {
      ...form,
      contents: {
        ...form.contents,
        postalCode: {
          value: value,
          value2: value2,
          isValid: true,
        },
        address1: {
          value: value3,
          isValid: true,
        },
      },
    };
    // 新しい値に更新
    setForm(validIncludedAfter);
  };

  const updateKanaValidly = (dataName, value) => {
    let _copy = JSON.parse(JSON.stringify(form)); // 複製
    _copy['contents'][dataName].value = value; // 複製を更新
    _copy['contents'][dataName].isValid = true; // 複製を更新
    _copy['contents'][dataName + 'Kana'].value = value; // 複製を更新
    _copy['contents'][dataName + 'Kana'].isValid = true; // 複製を更新
    setForm(_copy); // 戻す
  };

  const handleChange = (event, dataName, validationType, notRequired) => {
    // 入力内容
    let value = event.target.value;

    if (dataName === 'postalCode') {
      value = value.replaceAll('-', '');
    }

    if (dataName === 'phoneNum') {
      value = value.replaceAll('-', '');
      if (!isNaN(value))
        setPhoneNumInput(
          insertTwoHyphens(event, formatPhoneNum(event)[0], formatPhoneNum(event)[1], formatPhoneNum(event)[2])
        );
    }

    if (dataName === 'fNameKana' || dataName === 'lNameKana') {
      setKana[dataName](value);
    }

    if (dataName === 'address1') {
      setAddress1Input(value);
    }

    // バリデーション条件のチェック
    if (!validationExpressions[validationType]) {
      return;
    }
    // バリデーション
    if (validationExpressions[validationType].test(value)) {
      if (dataName === 'postalCode') {
        setPostalCodeInput(event.target.value);
        if (value.length === 7) {
          setAddress('住所を調べています');
          validatePostalCode(value, dataName);
        } else if (value.length < 7) {
          setAddress('郵便番号を入力してください');
          if (value.length === 0) {
            validError[dataName](false);
          } else {
            validError[dataName](true);
          }
          //送信ボタン無効化
          setForm({
            ...form,
            isValid: false,
            contents: {
              ...form.contents,
              [dataName]: {
                value: value,
                isValid: false,
              },
            },
          });
        }
      } else {
        if (!validationExpressions['hiragana']) {
          return;
        }
        if (validationExpressions['hiragana'].test(value) && (dataName === 'fName' || dataName === 'lName')) {
          updateKanaValidly(dataName, value);
          setKana[dataName + 'Kana'](value);
          validError[dataName + 'Kana'](false);
        } else {
          updateValidly(dataName, value);
          if (dataName !== 'address1' && dataName !== 'address2' && dataName !== 'address3') {
            validError[dataName](false);
          }
        }
      }
    } else {
      if (notRequired) {
        updateValidly(dataName, value);
        return;
      }
      // 無効なら該当箇所の判定を無効にして更新（同様に、展開して差分を上書き）
      setForm({
        ...form,
        isValid: false,
        contents: {
          ...form.contents,
          [dataName]: {
            value: value,
            isValid: false,
          },
        },
      });
      if (value.length === 0) {
        if (dataName !== 'address1') {
          validError[dataName](false);
        }
      } else {
        validError[dataName](true);
      }
    }
  };

  //コンポーネント外からPullDownを開く処理をするためのstateです
  const [isBuildingOpen, setIsBuildingOpen] = useState(false);
  const [isCompanyOpen, setIsCompanyOpen] = useState(false);

  const handleKeyUp = (event, dataName, nextData) => {
    //enterで次のフォームをfocusする
    if (form['contents'][dataName].isValid && event.keyCode === 13) {
      //建物名や会社名の時は例外処理で先にPullDownを開いてから時差でfocusさせる
      if (dataName === 'address1' && isBuildingOpen === false) {
        setIsBuildingOpen(true);
        setTimeout(() => {
          document.getElementById(nextData).focus();
        }, 0);
      } else if (dataName === 'address2' && isCompanyOpen === false) {
        setIsCompanyOpen(true);
        setTimeout(() => {
          document.getElementById(nextData).focus();
        }, 0);
      } else {
        document.getElementById(nextData).focus();
      }
    }
    //郵便番号と電話番号の整形
    if (dataName === 'postalCode') {
      setPostalCodeInput(insertHyphen(event, 3, 4));
    }
    if (dataName === 'phoneNum') {
      setPhoneNumInput(
        insertTwoHyphens(event, formatPhoneNum(event)[0], formatPhoneNum(event)[1], formatPhoneNum(event)[2])
      );
    }
  };

  const handleLinkClick = (event) => {
    event.preventDefault();
    // フォームが無効なら押せないはずだが念の為チェック
    if (!form.isValid) return;

    if (Object.keys(form.contents).length > 0) {
      setDelivery((current) => {
        const future = JSON.parse(JSON.stringify(current));
        future.selectedAddress = form.contents;
        future.addresses[delivery.edit] = form.contents;
        future.checked.fill(false);
        future.checked[delivery.edit] = true;
        return future;
      });
    }
    navigateToDelivery('/delivery');
  };

  //トップページへのリダイレクト
  const firstView = useRecoilValue(firstViewState);
  useEffect(() => {
    if (!firstView.visited) {
      navigateToDelivery('/');
    }
  }, []);

  return (
    <div>
      <InputAccountInfoCon className={classNames(css.wd100, css.input_info_con)}>
        <FormHeader title="配送先の編集" step="step2" />
        <form className={css.pd0_24}>
          <div className={css.flex}>
            <InputBox
              title="お名前"
              placeholder="山田"
              description="姓"
              padding={[0, 8, 0, 0]}
              content={form['contents']['lName']['value'] || null}
              isValid={form['contents']['lName'].isValid}
              requiredLabel={true}
              errorMessage={errorMessage.lNameValidMessage}
              isError={lNameValidError}
              onChange={(e) => handleChange(e, 'lName', 'string')}
              onKeyUp={(e) => handleKeyUp(e, 'lName', 'fName')}
              id="lName"
            />
            <InputBox
              placeholder="広美"
              description="名"
              padding={[0, 0, 0, 8]}
              content={form['contents']['fName']['value'] || null}
              isValid={form['contents']['fName'].isValid}
              errorMessage={errorMessage.fNameValidMessage}
              isError={fNameValidError}
              onChange={(e) => handleChange(e, 'fName', 'string')}
              onKeyUp={(e) => handleKeyUp(e, 'fName', 'lNameKana')}
              id="fName"
            />
          </div>
          <div className={css.flex}>
            <InputBox2
              className={css.fontJp}
              type="text"
              value={lNameKanaInput || form['contents']['lNameKana']['value'] || null}
              title="ふりがな"
              placeholder="やまだ"
              description="せい"
              padding={[12, 8, 0, 0]}
              requiredLabel={true}
              content={form['contents']['lNameKana']['value'] || null}
              onChange={(e) => handleChange(e, 'lNameKana', 'hiragana')}
              isValid={form['contents']['lNameKana'].isValid}
              isError={lNameKanaValidError}
              errorMessage={errorMessage.lNameKanaValidMessage}
              onKeyUp={(e) => handleKeyUp(e, 'lNameKana', 'fNameKana')}
              id="lNameKana"
            />
            <InputBox2
              type="text"
              value={fNameKanaInput || form['contents']['fNameKana']['value'] || null}
              placeholder="ひろみ"
              description="めい"
              padding={[12, 0, 0, 8]}
              content={form['contents']['fNameKana']['value'] || null}
              isValid={form['contents']['fNameKana'].isValid}
              isError={fNameKanaValidError}
              errorMessage={errorMessage.fNameKanaValidMessage}
              onChange={(e) => handleChange(e, 'fNameKana', 'hiragana')}
              onKeyUp={(e) => handleKeyUp(e, 'fNameKana', 'phoneNum')}
              id="fNameKana"
            />
          </div>
          <InputBox2
            type="tel"
            title="電話番号"
            value={phoneNumInput || form['contents']['phoneNum']['value']}
            placeholder="090-0000-0000"
            description="ハイフン入力不要"
            requiredLabel={true}
            content={form['contents']['phoneNum']['value'] || null}
            isValid={form['contents']['phoneNum'].isValid}
            isError={phoneNumValidError}
            errorMessage={errorMessage.phoneNumValidMessage}
            onChange={(e) => handleChange(e, 'phoneNum', 'phoneNum')}
            onKeyUp={(e) => handleKeyUp(e, 'phoneNum', 'postalCode')}
            id="phoneNum"
            onBlur={(e) => handleKeyUp(e, 'phoneNum')}
          />
          <InputBoxHalf2
            type="tel"
            title="郵便番号"
            value={postalCodeInput || form['contents']['postalCode']['value'] || null}
            placeholder="000-0000"
            description="ハイフン入力不要"
            requiredLabel={true}
            errorMessage={errorMessage.postNumValidMessage}
            isError={postalCodeValidError}
            content={form['contents']['postalCode']['value'] || null}
            isValid={form['contents']['postalCode'].isValid}
            onChange={(e) => handleChange(e, 'postalCode', 'postalCode')}
            onKeyUp={(e) => handleKeyUp(e, 'postalCode', 'address1')}
            id="postalCode"
            onBlur={(e) => handleKeyUp(e, 'postalCode')}
          />
          <div
            className={classNames(
              css.fs20,
              css.fw_b,
              css.mt12,
              css.ml24,
              css.mb12,
              css.displayFlex,
              address === '郵便番号が正しくありません' ? css.colorRed : ''
            )}
          >
            {address}
            <div className={address === '住所を調べています' ? css.loader : css.displayNone}>Loading...</div>
          </div>
          <InputBox2
            type="text"
            title="番地"
            value={address1Input || form['contents']['address1']['value'] || null}
            placeholder="1-2-3"
            requiredLabel={true}
            content={form['contents']['address1']['value'] || null}
            isValid={form['contents']['address1'].isValid}
            onChange={(e) => handleChange(e, 'address1', 'string')}
            onKeyUp={(e) => handleKeyUp(e, 'address1', 'building')}
            id="address1"
          />
          <PullDown
            className={css.mt12}
            title="建物名/部屋番号"
            id="building"
            isSelected={isBuildingOpen}
            setIsSelected={setIsBuildingOpen}
            content={
              <InputBox
                simplify={true}
                content={form['contents']['address2']['value'] || null}
                onChange={(e) => handleChange(e, 'address2', 'string', true)}
                placeholder="ティラミスハウス101"
                onKeyUp={(e) => handleKeyUp(e, 'address2', 'company')}
                id="building"
              />
            }
          />
          <PullDown
            className={css.mt12}
            title="会社名/部門"
            id="company"
            isSelected={isCompanyOpen}
            setIsSelected={setIsCompanyOpen}
            content={
              <InputBox
                simplify={true}
                content={form['contents']['address3']['value'] || null}
                onChange={(e) => handleChange(e, 'address3', 'string', true)}
                placeholder="株式会社○○"
                id="company"
              />
            }
          />
        </form>
        <AddressInfoForm>
          <BottomButtons>
            <BoxButton
              text="修正する"
              className={css.mt24}
              onClick={(e) => {
                handleLinkClick(e);
              }}
              disabled={!form.isValid}
            />
            <Link to="/delivery">
              <TextButton text="配送に戻る" />
            </Link>
          </BottomButtons>
        </AddressInfoForm>
      </InputAccountInfoCon>
    </div>
  );
}

export default AddressEdit;
