import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { termsAndPolicyState } from './atoms';
import classNames from 'classnames';
import css from '../index.module.scss';
import footerCss from './footer.module.scss';

export default function Footer() {
  const setTermsAndPolicy = useSetRecoilState(termsAndPolicyState);

  const updateTermsAndPolicy = (boolean) => {
    setTermsAndPolicy((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future['terms'] = boolean;
      return future;
    });
  };

  return (
    <div className={`${footerCss.footer}`} id="footer">
      <div>
        <div className={css.wid100}>
          <img src={`${process.env.PUBLIC_URL}/BENE REGALO-01.svg`} alt="" className={footerCss.footerLogo} />
        </div>
      </div>
      <div className={classNames(footerCss.footerFlex)}>
        <a
          href="https://eatpon.bene-regalo.com/"
          target="_blank"
          rel="noopener noreferrer"
          className={footerCss.footerIcon}
        >
          <img src={`${process.env.PUBLIC_URL}/eatpon.svg`} alt="" className={css.wid100} />
        </a>
        <a
          href="https://www.instagram.com/bene_regalo/"
          target="_blank"
          rel="noopener noreferrer"
          className={footerCss.footerIcon}
        >
          <img src={`${process.env.PUBLIC_URL}/instagram.svg`} alt="" className={css.wid100} />
        </a>
        <a
          href="https://twitter.com/bene_regalo"
          target="_blank"
          rel="noopener noreferrer"
          className={footerCss.footerIcon}
        >
          <img src={`${process.env.PUBLIC_URL}/twitter.svg`} alt="" className={css.wid100} />
        </a>
        <a href="https://lin.ee/43MZKyH" target="_blank" rel="noopener noreferrer" className={footerCss.footerIcon}>
          <img src={`${process.env.PUBLIC_URL}/line.svg`} alt="" className={css.wid100} />
        </a>
      </div>
      <div className={footerCss.footerText1}>
        <Link to={'/policy'}>
          <span
            onClick={() => {
              updateTermsAndPolicy(false);
            }}
          >
            プライバシーポリシー&nbsp;&nbsp;&nbsp;
          </span>
          <span
            onClick={() => {
              updateTermsAndPolicy(true);
            }}
          >
            利用規約
          </span>
        </Link>
      </div>
      <Link to={'/transaction-act'} className={footerCss.footerText1}>
        <div className={footerCss.footerText2}>特定商取引法に基づく表記</div>
      </Link>
      <div className={footerCss.footerText4}>Copyright ©Eight Good Co., Ltd.</div>
    </div>
  );
}
