import axios from 'axios';
const AWS_REFFERER_API_URL = 'https://znxizeug0l.execute-api.ap-northeast-1.amazonaws.com/measure_param';

const COOKIE_OPTION = { secure: true, 'max-age': 60 * 60 * 24 * 30 };
const RHETORIC = 'beneRef_';
const COOKIE_LIST = ['first_source', 'first_pattern', 'now_source', 'now_pattern', 'userId', 'first_time'];

export async function sendRefererData(page) {
  const cookies = getAllCookieData();
  let url = `${AWS_REFFERER_API_URL}?page=${page}`;
  for (let i = 0; i < COOKIE_LIST.length; i++) {
    // if(i==0) {
    //   url+=`${COOKIE_LIST[i]}=${cookies[COOKIE_LIST[i]]}`
    // } else {
    url += `&${COOKIE_LIST[i]}=${cookies[COOKIE_LIST[i]]}`;
    // }
  }
  await axios.get(url);
}

function getAllCookieData() {
  const cookies = {};
  for (let i = 0; i < COOKIE_LIST.length; i++) {
    cookies[COOKIE_LIST[i]] = getCookie(COOKIE_LIST[i]);
  }
  return cookies;
}

export function setReferrerData(url) {
  const cookies = getAllCookieData();

  if (analyzeURL(url)) {
    const source = url.split('source_')[1].split('-')[0];
    const pattern = url.split('pattern_')[1];

    if (cookies.userId) {
      setCookie('now_source', source, COOKIE_OPTION);
      setCookie('now_pattern', pattern, COOKIE_OPTION);
    } else {
      setCookie('first_source', source, COOKIE_OPTION);
      setCookie('now_source', source, COOKIE_OPTION);
      setCookie('first_pattern', pattern, COOKIE_OPTION);
      setCookie('now_pattern', pattern, COOKIE_OPTION);
      setCookie('userId', createId(), COOKIE_OPTION);
      setCookie('first_time', new Date().getTime(), COOKIE_OPTION);
    }
  } else {
    if (cookies.userId) {
      setCookie('now_source', 'none', COOKIE_OPTION);
      setCookie('now_pattern', 'none', COOKIE_OPTION);
    } else {
      setCookie('first_source', 'none', COOKIE_OPTION);
      setCookie('now_source', 'none', COOKIE_OPTION);
      setCookie('first_pattern', 'none', COOKIE_OPTION);
      setCookie('now_pattern', 'none', COOKIE_OPTION);
      setCookie('userId', createId(), COOKIE_OPTION);
      setCookie('first_time', new Date().getTime(), COOKIE_OPTION);
    }
  }

  function analyzeURL(url) {
    // http://localhost:3000/source_srca-pattern_lpb
    if (url.match('source') && url.match('pattern')) {
      // eslint-disable-next-line no-useless-escape
      const reg = new RegExp(/^http.*source_.*\-.*pattern_.*/, 'ig');
      const refererFlag = reg.test(url);
      if (refererFlag) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  function createId() {
    const S = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const N = 8;
    return Array.from(Array(N))
      .map(() => S[Math.floor(Math.random() * S.length)])
      .join('');
  }
}

export function setCookie(name, value, options = {}) {
  name = RHETORIC + name;

  options = {
    path: '/',
    ...options,
  };

  if (options.expires) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += '; ' + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

export function getCookie(name) {
  name = RHETORIC + name;
  const matches = document.cookie.match(
    // eslint-disable-next-line no-useless-escape
    new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`)
  );
  return matches ? decodeURIComponent(matches[1]) : '';
}
