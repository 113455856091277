import { StyledDiv, responsiveStyle } from '../../utils/styled';
import styled from 'styled-components';

export const Wrapper = styled(StyledDiv)`
  * {
    box-sizing: border-box;
    font-family: 'KiwiMaru', 'NotoSanJP';
  }

  margin: 0 auto;
  ${(props) => {
    return responsiveStyle({
      marginBottom: [64, 72, 80],
      width: props.inputFormWidth,
    });
  }}

  .addressTitle {
    ${responsiveStyle({
      height: [30, 34, 36],
      fontSize: [16, 18, 20],
      lineHeight: [30, 34, 36],
    })}
  }

  .addressList {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    background-color: #f3f4f5;
    border: 1px solid #161c1c;
    ${responsiveStyle({
      padding: [16, 18, 20],
      fontSize: [16, 18, 20],
      borderRadius: [4, 5, 6],
      marginBottom: [4, 5, 6],
    })}
    .rightWrap {
      position: relative;
      display: flex;
      flex-flow: column;
      justify-content: space-around;
      .editButton {
        top: 0;
        left: 0;
        color: #ff5656;
        position: absolute;
        ${responsiveStyle({
          fontSize: [16, 18, 20],
          width: [32, 36, 40],
          marginLeft: [-8, -9, -10],
        })}
      }
    }
  }

  .addAddressButtonWrap {
    cursor: pointer;
    display: flex;
    border: 1px solid #161c1c;
    justify-content: space-between;
    ${responsiveStyle({
      height: [48, 54, 60],
      borderRadius: [5, 5, 6],
      padding: [15, 17, 19],
      marginTop: [8, 10, 12],
      marginBottom: [16, 18, 20],
    })}
    .addAddressButton {
      ${responsiveStyle({
        fontSize: [16, 18, 20],
        lineHeight: [16, 18, 20],
      })}
    }
    img {
      rotate: 270deg;
      ${responsiveStyle({
        height: [16, 18, 20],
      })}
    }
  }

  .marginTop {
    ${responsiveStyle({
      marginTop: [16, 18, 20],
    })}
  }

  .marginBottom {
    ${responsiveStyle({
      marginBottom: [16, 18, 20],
    })}
  }

  .linkButton {
    ${responsiveStyle({
      marginTop: [24, 26, 28],
    })}
  }
`;
