import css from './index.module.scss';
import classNames from 'classnames';
import { ReadQR } from './pages/readQR';
import { useEffect, useState } from 'react';
import { Menu } from './pages/menu';
import { SignIn } from './pages/signIn';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';

export const KamaitachiManagement = () => {
  //ログイン用State
  const [isLogin, setIsLogin] = useState(false);
  const [page, setPage] = useState('ReadQR');
  const auth = getAuth();

  onAuthStateChanged(auth, (user) => {
    if (!user) {
      setIsLogin(false);
    }
  });

  const logOut = () => {
    signOut(auth);
    window.location.reload();
  };

  //ページのリロード時はsignOutする
  window.onbeforeunload = function () {
    signOut(auth);
  };
  useEffect(() => {
    signOut(auth);
  }, []);

  return (
    <div className={classNames(css.kamaitachiManagement)}>
      <Menu isLogin={isLogin} logOut={logOut} page={page} setPage={setPage} />
      {isLogin ? (
        <>
          {page === 'TOP' && <>TOP</>}
          {page === 'ReadQR' && <ReadQR />}
        </>
      ) : (
        <SignIn auth={auth} logOut={logOut} setIsLogin={setIsLogin} />
      )}
    </div>
  );
};
