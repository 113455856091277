import { atom, selector } from 'recoil';
import { itemList, sumUpCart } from './common';

export const userState = atom({
  key: 'userState',
  default: {
    user: {
      fName: null,
      lName: null,
      postalCode: null,
      address1: null,
      address2: null,
      address3: null,
      phoneNum: null,
    },
  },
});

export const paymentState = atom({
  key: 'paymentState',
  default: {},
});

export const deliveryState = atom({
  key: 'deliveryState',
  default: {
    dateTime: {
      fulldate: '指定なし',
      clockTime: '指定なし',
    },
    selectedAddress: {},
    checked: [true], //どの配送先が選ばれているか（ラジオボタン）
    addresses: [], //配送先の選択肢
    edit: null,
  },
});

export const cartState = atom({
  key: 'cartState',
  default: {
    show: null,
    items: itemList,
  },
});

export const totalPriceState = selector({
  key: 'totalPriceState',
  get: ({ get }) => {
    const cart = get(cartState);
    let shippingFee = 0;

    for (const key in cart.items) {
      if (cart['items'][key]['count'] > 0) {
        if (key === 'uid4') {
          shippingFee = 800;
        }
      }
    }
    for (const key in cart.items) {
      if (cart['items'][key]['count'] > 0) {
        if (
          key === 'uid1' ||
          key === 'uid2' ||
          key === 'uid8' ||
          key === 'uid11' ||
          key === 'uid12' ||
          key === 'uid18' ||
          key === 'uid25' ||
          key === 'uid31'
          // MEMO: 白黒復活のため残す
          // key === 'uid16' ||
          // key === 'uid17' ||
        ) {
          shippingFee = 1000;
        }
      }
    }

    // MEMO: 送料無料があと勝ち
    for (const key in cart.items) {
      if (cart['items'][key]['count'] > 0) {
        if (
          key === 'uid9' ||
          key === 'uid10' ||
          // key === 'uid13' ||
          // key === 'uid14' ||
          // key === 'uid15' ||
          key === 'uid23' ||
          key === 'uid22' ||
          key === 'uid21' ||
          key === 'uid20' ||
          key === 'uid19' ||
          key === 'uid26' ||
          key === 'uid27' ||
          key === 'uid28' ||
          key === 'uid29' ||
          key === 'uid30' ||
          key === 'uid32' ||
          key === 'uid33' ||
          key === 'uid34'
          // MEMO: 白黒復活のため残す
          // key === 'uid24'
        ) {
          shippingFee = 0;
        }
      }
    }
    return sumUpCart(cart) + shippingFee;
  },
});

export const totalCountState = selector({
  key: 'totalCountState',
  get: ({ get }) => {
    const cart = get(cartState);
    let count = 0;
    for (const key in cart.items) {
      count += cart['items'][key]['count'];
    }
    return count;
  },
});

export const firstViewState = atom({
  key: 'firstViewState',
  default: {
    visited: false,
  },
});

export const termsAndPolicyState = atom({
  key: 'termsAndPolicyState',
  default: {
    terms: true,
    backPage: '/',
  },
});

export const orderState = atom({
  key: 'orderState',
  default: {
    orderId: null,
  },
});

export const testPurchaseState = atom({
  key: 'testPurchaseState',
  default: {
    admin: false,
  },
});

export const bannerState = atom({
  key: 'bannerState',
  default: {
    shopClicked: false,
  },
});
