import { StyledDiv, responsiveStyle } from '../../utils/styled';
import styled from 'styled-components';

const multiplyList = (list, num) => {
  return [list[0] * num, list[1] * num, list[2] * num];
};

export const Wrapper = styled(StyledDiv)`
  * {
    box-sizing: border-box;
    font-family: 'KiwiMaru', 'NotoSanJP';
  }

  position: relative;
  margin: 0 auto;
  ${(props) => {
    return responsiveStyle({
      marginBottom: [64, 72, 80],
      width: props.inputFormWidth,
    });
  }}

  .deleteButton {
    color: #ffffff;
    background-color: #ff5656;
    position: absolute;
    right: 0;
    ${responsiveStyle({
      fontSize: [16, 18, 20],
    })}
    ${(props) => {
      return responsiveStyle({
        borderRadius: multiplyList(props.inputFormHeight, 0.125),
        marginTop: multiplyList(props.inputFormHeight, -1.4),
        Height: props.inputFormHeight,
        lineHeight: props.inputFormHeight,
        paddingLeft: multiplyList(props.inputFormHeight, 0.33),
        paddingRight: multiplyList(props.inputFormHeight, 0.33),
      });
    }}
  }

  .formFlex {
    display: flex;
    justify-content: space-between;
  }

  .marginTop {
    ${responsiveStyle({
      marginTop: [16, 18, 20],
    })}
  }

  .marginBottom {
    ${responsiveStyle({
      marginBottom: [16, 18, 20],
    })}
  }

  .fName {
    ${(props) => {
      return responsiveStyle({
        marginTop: multiplyList(props.inputFormHeight, 0.75),
      });
    }}
  }

  .checkBoxWrap {
    display: flex;
    align-items: flex-end;
    color: #6b757e;
    ${responsiveStyle({
      fontSize: [14, 15, 16],
      lineHeight: [24, 26, 28],
      marginBottom: [16, 18, 20],
    })}

    .checkBox {
      cursor: pointer;
      display: block;
      ${responsiveStyle({
        height: [24, 26, 28],
        marginRight: [12, 13, 14],
      })}
    }
  }

  .address {
    ${responsiveStyle({
      fontSize: [16, 18, 20],
      marginBottom: [8, 9, 10],
    })}
  }

  .description {
    cursor: pointer;
    ${responsiveStyle({
      fontSize: [16, 18, 20],
      marginBottom: [8, 9, 10],
    })}
    .img {
      ${responsiveStyle({
        height: [18, 20, 22],
        marginRight: [6, 7, 8],
        marginBottom: [-4, -4, -4],
      })}
      ${(props) => {
        return `
        &Building {
          rotate: ${props.showBuildingForm ? '180deg' : '0deg'};
        }
        &Corporation {
          rotate: ${props.showCorporationForm ? '180deg' : '0deg'};
        }
      `;
      }}
    }
  }

  .building {
    ${(props) => {
      return `
        display: ${props.showBuildingForm ? 'block' : 'none'};
      `;
    }}
  }

  .corporation {
    ${(props) => {
      return `
        display: ${props.showCorporationForm ? 'block' : 'none'};
      `;
    }}
  }

  .linkButton {
    ${responsiveStyle({
      marginTop: [24, 26, 28],
    })}
  }
`;
