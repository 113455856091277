import { useState, useEffect, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import classNames from 'classnames';

import css from './index.module.scss';

import { StyledDiv } from '../utils/styled';
import { validation } from '../utils/form';
import { importAll } from '../utils/image';

import { InputForm } from '../components/inputForm/inputForm.js';
import { LinkButton } from '../components/linkButton';
import { KamaitachiHeader, SideMenu } from '../component/index';

// MEMO: Recoil
import { useSetRecoilState } from 'recoil';
import { termsAndPolicyState } from '../../lp/atoms.js';

// MEMO: fireStore
import { contactCollectionCreate } from '../store/index.js';

export const KamaitachiContact = () => {
  const image = importAll(require.context('./image', false, /\.(webp|svg)$/));
  const inputFormWidth = [340, 512, 720];
  const inputFormHalfWidth = [160, 246, 350];
  const inputFormHeight = [32, 38, 44];
  const linkButtonHeight = [48, 52, 60];
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [activePullDown1, setActivePullDown1] = useState(false);
  const [activePullDown2, setActivePullDown2] = useState(false);
  const [activePullDown3, setActivePullDown3] = useState(false);
  const [activePullDown4, setActivePullDown4] = useState(false);
  const [activePullDown5, setActivePullDown5] = useState(false);
  const [activePullDown6, setActivePullDown6] = useState(false);
  const [activePullDown7, setActivePullDown7] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [customer, setCustomer] = useState({});

  const defaultFormObj = {
    familyName: 'not filled',
    lastName: 'not filled',
    familyNameKana: 'not filled',
    lastNameKana: 'not filled',
    mailAddress: 'not filled',
    inquiryBody: 'not filled',
  };

  const [form, setForm] = useState(defaultFormObj);

  const setTermsAndPolicy = useSetRecoilState(termsAndPolicyState);
  const setCustomerByProp = (key, value) => {
    setCustomer((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const setFormByProp = (key, value) => {
    setForm((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const onChangeInputForm = (event, prop, validationType) => {
    const value = event.target.value;
    setCustomerByProp(prop, value);
    if (!value) {
      setFormByProp(prop, 'not filled');
      setIsValid(false);
    } else if (validation[validationType].test(value)) {
      setFormByProp(prop, 'valid');
    } else {
      setFormByProp(prop, 'invalid');
      setIsValid(false);
    }
  };
  /**enter入力で次のフォームへ移動 */
  const onKeyUpInputForm = (event, currentProp, nextProp) => {
    if (form[currentProp] === 'valid' && event.keyCode === 13) {
      document.getElementById(nextProp).focus();
    }
  };
  /**ふりがなの自動入力 */
  const fillKanaAutomatically = (event, prop) => {
    const value = event.target.value;
    if (validation.hiragana.test(value)) {
      setCustomerByProp(prop + 'Kana', value);
      setFormByProp(prop + 'Kana', 'valid');
    }
  };
  // MEMO : inputのtypeをemailにするとspace入力時にonClickが発火しないため、onKeyUpで代替する
  const onKeyUpMailAddress = (event) => {
    const value = event.target.value;

    if (event.keyCode === 32) {
      setFormByProp('mailAddress', 'invalid');
      setIsValid(false);
    } else if (!value) {
      setFormByProp('mailAddress', 'not filled');
      setIsValid(false);
    } else if (validation.mail.test(value)) setFormByProp('mailAddress', 'valid');
  };

  useEffect(() => {
    if (isValid) return;
    if (
      Object.keys(form).every(function (key) {
        return form[key] === 'valid';
      })
    ) {
      setIsValid(true);
    }
  }, [form]);

  useEffect(() => {
    if (!isValid) setButtonIsLoading(false);
  }, [isValid]);

  useLayoutEffect(() => {
    setTermsAndPolicy((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future['backPage'] = '/kamaitachi-no-okite/contact';
      return future;
    });
  }, []);

  const updateTermsAndPolicy = (boolean) => {
    setTermsAndPolicy((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future['terms'] = boolean;
      return future;
    });
  };
  const sendContact = async () => {
    if (!customer) return;
    setButtonIsLoading(true);
    const isSending = await contactCollectionCreate(customer);
    if (isSending) {
      toast.success('送信しました');
      setCustomer({});
      setForm(defaultFormObj);
      setIsButtonActive(false);
    } else {
      toast.error('送信に失敗しました');
    }

    setButtonIsLoading(false);
  };

  return (
    <>
      <Toaster position="top-right" />
      <SideMenu>
        <li>
          <Link to={'/kamaitachi-no-okite/'}>
            <p>TOP</p>
          </Link>
        </li>
        <li>
          <Link to={'/policy'}>
            <p
              onClick={() => {
                updateTermsAndPolicy(true);
              }}
            >
              利用規約
            </p>
          </Link>
        </li>
        <li>
          <Link to={'/policy'}>
            <p
              onClick={() => {
                updateTermsAndPolicy(false);
              }}
            >
              プライバシーポリシー&nbsp;&nbsp;&nbsp;
            </p>
          </Link>
        </li>
        <li>
          <Link to={'/transaction-act'}>
            <p>特定商取引法に基づく表記</p>
          </Link>
        </li>
      </SideMenu>
      <div className={classNames(css.kamaitachiBodyBase)}>
        <KamaitachiHeader />
        <StyledDiv
          className={classNames(css.contactForm)}
          responsive={{
            width: inputFormWidth,
          }}
        >
          <h1 className={classNames(css.H1Title)}>よくある質問</h1>
          <div
            className={classNames(css.pullDown)}
            onClick={() => {
              setActivePullDown1(!activePullDown1);
            }}
          >
            <div>1.&nbsp;&nbsp;&nbsp;&nbsp;キャンセルしたいです。</div>
            <div>{activePullDown1 ? 'ー' : '＋'}</div>
          </div>
          <div
            style={{
              display: activePullDown1 ? 'block' : 'none',
            }}
            className={classNames(css.pullDownText)}
          >
            【掟感謝祭2024受取のご予約に関して】
            <br />
            2024年6月2日までは決済手数料7.2％分を差し引いた金額にて処理させていただきます。
            <br />
            上記以降は、返金致しかねますため、ご注意ください。
            <br />
            <br />
            【店頭受取、オンライン通販でのご予約に関して】
            <br />
            2024年6月30日までは決済手数料7.2％分を差し引いた金額にて処理させていただきます。
            <br />
            上記以降は、返金致しかねますため、ご注意ください。
            <br />
            <br />
            その他、ご不明な点は、下記お問い合わせフォームに購入の際に使用したメールアドレス、ご予約番号を提示の上ご連絡ください。
          </div>
          <div
            className={classNames(css.pullDown)}
            onClick={() => {
              setActivePullDown2(!activePullDown2);
            }}
          >
            <div>2.&nbsp;&nbsp;&nbsp;&nbsp;他の商品も追加したい。 </div>
            <div>{activePullDown2 ? 'ー' : '＋'}</div>
          </div>
          <div
            style={{
              display: activePullDown2 ? 'block' : 'none',
            }}
            className={classNames(css.pullDownText)}
          >
            【店頭の場合】
            <br />
            お電話、サイトからの追加予約が可能です。詳しくは下記リンクからご確認ください。
            <br />
            <br />
            ●秋川本店
            <br />
            <a href="https://bene-regalo.com/akigawa" target="blank">
              https://bene-regalo.com/akigawa
            </a>
            <br />
            <br />
            ●代々木店
            <br />
            <a href="https://bene-regalo.com/yoyogi" target="blank">
              https://bene-regalo.com/yoyogi
            </a>
            <br />
            <br />
            【オンライン通販の場合】
            <br />
            一部、追加で購入ができます。
            <br />
            その他、通常の購入サイト（
            <a href="https://bene-regalo.com/" target="blank">
              https://bene-regalo.com/
            </a>
            ）のみで購入できる商品を追加したい場合は、同一のメールアドレスを用いて、同一の配送先に注文していただければ送料などの返金が可能な場合がございます。
            <br />
            お手数ですが、ご連絡いただけますと幸いです。
          </div>
          <div
            className={classNames(css.pullDown)}
            onClick={() => {
              setActivePullDown3(!activePullDown3);
            }}
          >
            <div>3.&nbsp;&nbsp;&nbsp;&nbsp;掟感謝祭以外で購入することは出来ないのでしょうか。</div>
            <div>{activePullDown3 ? 'ー' : '＋'}</div>
          </div>
          <div
            style={{
              display: activePullDown3 ? 'block' : 'none',
            }}
            className={classNames(css.pullDownText)}
          >
            購入できるようになりました！！
            <br />
            店頭受取、オンライン通販ともに7月中旬以降での受取になりますが、6月9日12時から6月16日終日まで追加のご予約が可能となりました。是非、お早めにお申し込みください。
          </div>
          <div
            className={classNames(css.pullDown)}
            onClick={() => {
              setActivePullDown4(!activePullDown4);
            }}
          >
            <div>4.&nbsp;&nbsp;&nbsp;&nbsp;予約個数に上限はありますか。</div>
            <div>{activePullDown4 ? 'ー' : '＋'}</div>
          </div>
          <div
            style={{
              display: activePullDown4 ? 'block' : 'none',
            }}
            className={classNames(css.pullDownText)}
          >
            今のところ、一人あたりの予約個数上限は決めておりません。
            <br />
            スイーツという性質上もあり、たくさん買いすぎても食べきれない可能性がありますので、食べられる個数をご予約いただけますと幸いです。
          </div>
          <div
            className={classNames(css.pullDown)}
            onClick={() => {
              setActivePullDown5(!activePullDown5);
            }}
          >
            <div>5.&nbsp;&nbsp;&nbsp;&nbsp;賞味期限は当日中ですか？</div>
            <div>{activePullDown5 ? 'ー' : '＋'}</div>
          </div>
          <div
            style={{
              display: activePullDown5 ? 'block' : 'none',
            }}
            className={classNames(css.pullDownText)}
          >
            【掟感謝祭2024受取のご予約に関して】
            <br />
            ギリギリまで冷凍で保管しておく予定ですので、1-2時間以内に冷凍庫や冷蔵庫に入れることができれば、保管期間は伸びます。
            ただ、基本的にはなるべくお早めにお召し上がりいただけますよう、よろしくお願いいたします。
            <br />
            <br />
            【店頭受け取りに関して】 <br />
            冷蔵、冷凍どちらのお受け取りも指定出来ます。
            <br />
            特に指定がない場合は、冷蔵でお渡しいたします。
            <br />
            冷蔵の場合、当日中を目安になるべくお早めにお召し上がりください。
            <br />
            冷凍の場合、冷凍保管で約1ヶ月以内にお召し上がりください。 <br />
            <br />
            【オンライン配送に関して】
            <br />
            冷凍便での配送になります。冷凍保管で約1ヶ月以内にお召し上がりください。
          </div>
          <div
            className={classNames(css.pullDown)}
            onClick={() => {
              setActivePullDown6(!activePullDown6);
            }}
          >
            <div>6.&nbsp;&nbsp;&nbsp;&nbsp;量はどのぐらいですか？</div>
            <div>{activePullDown6 ? 'ー' : '＋'}</div>
          </div>
          <div
            style={{
              display: activePullDown6 ? 'block' : 'none',
            }}
            className={classNames(css.pullDownText)}
          >
            おいしさや見た目なども考慮して、選択できる範囲でなるべく小さいカップを選び、かつ、甘さを控えて食べやすくしたり、濃厚なクリームを少し軽い口溶けにできるよう工夫はしております。
            <br />
            重さとしては1個85g-110gほどとなっております。
          </div>
          <div
            className={classNames(css.pullDown)}
            onClick={() => {
              setActivePullDown7(!activePullDown7);
            }}
          >
            <div>7.&nbsp;&nbsp;&nbsp;&nbsp;コースターは選べますか？</div>
            <div>{activePullDown7 ? 'ー' : '＋'}</div>
          </div>
          <div
            style={{
              display: activePullDown7 ? 'block' : 'none',
            }}
            className={classNames(css.pullDownText)}
          >
            コースターは選択できません。
            <br />
            セットの内容は決まっておりますので、商品画像をよくみてご選択ください。
          </div>
          <h1 className={classNames(css.H1Title)}>お問い合わせ</h1>
          <div className={classNames(css.formFlex)}>
            <InputForm
              required={true}
              title="お名前"
              help="姓名合わせて全角16文字まで"
              width={inputFormHalfWidth}
              height={inputFormHeight}
              placeholder="定良"
              description="姓"
              id="familyName"
              value={customer.familyName || ''}
              onChange={(e) => {
                onChangeInputForm(e, 'familyName', 'string');
                fillKanaAutomatically(e, 'familyName');
              }}
              onKeyUp={(e) => {
                onKeyUpInputForm(e, 'familyName', 'lastName');
              }}
              check={form.familyName === 'valid'}
              error={form.familyName === 'invalid'}
              errorMessage="姓に無効な文字や空白を含んでいます"
            />
            <StyledDiv
              responsive={{
                marginTop: [inputFormHeight[0] * 0.75, inputFormHeight[1] * 0.75, inputFormHeight[2] * 0.75],
              }}
            >
              <InputForm
                width={inputFormHalfWidth}
                height={inputFormHeight}
                placeholder="美鈴"
                description="名"
                id="lastName"
                value={customer.lastName || ''}
                onChange={(e) => {
                  onChangeInputForm(e, 'lastName', 'string');
                }}
                onKeyUp={(e) => {
                  onKeyUpInputForm(e, 'lastName', 'familyNameKana');
                  fillKanaAutomatically(e, 'lastName');
                }}
                check={form.lastName === 'valid'}
                error={form.lastName === 'invalid'}
                errorMessage="名に無効な文字や空白を含んでいます"
              />
            </StyledDiv>
          </div>
          <div className={classNames(css.formFlex)}>
            <InputForm
              required={true}
              title="ふりがな"
              help="せいめい合わせて全角25文字まで"
              width={inputFormHalfWidth}
              height={inputFormHeight}
              placeholder="ていら"
              description="せい"
              id="familyNameKana"
              value={customer.familyNameKana || ''}
              onChange={(e) => {
                onChangeInputForm(e, 'familyNameKana', 'hiragana');
              }}
              onKeyUp={(e) => {
                onKeyUpInputForm(e, 'familyNameKana', 'lastNameKana');
              }}
              check={form.familyNameKana === 'valid'}
              error={form.familyNameKana === 'invalid'}
              errorMessage="ひらがなで入力してください"
            />
            <StyledDiv
              responsive={{
                marginTop: [inputFormHeight[0] * 0.75, inputFormHeight[1] * 0.75, inputFormHeight[2] * 0.75],
              }}
            >
              <InputForm
                width={inputFormHalfWidth}
                height={inputFormHeight}
                placeholder="みすず"
                description="めい"
                id="lastNameKana"
                value={customer.lastNameKana || ''}
                onChange={(e) => {
                  onChangeInputForm(e, 'lastNameKana', 'hiragana');
                }}
                onKeyUp={(e) => {
                  onKeyUpInputForm(e, 'lastNameKana', 'mailAddress');
                }}
                check={form.lastNameKana === 'valid'}
                error={form.lastNameKana === 'invalid'}
                errorMessage="ひらがなで入力してください"
              />
            </StyledDiv>
          </div>
          <InputForm
            required={true}
            title="メールアドレス"
            help="半角英数字のみ"
            width={inputFormWidth}
            height={inputFormHeight}
            placeholder="tiramisu@bene-regalo.com"
            id="mailAddress"
            type="email"
            value={customer.mailAddress || ''}
            onChange={(e) => {
              onChangeInputForm(e, 'mailAddress', 'mail');
            }}
            onKeyUp={(e) => {
              onKeyUpInputForm(e, 'mailAddress', 'phoneNum');
              onKeyUpMailAddress(e);
            }}
            check={form.mailAddress === 'valid'}
            error={form.mailAddress === 'invalid'}
            errorMessage="有効なメールアドレスを入力してください"
          />
          <div className={classNames(css.textAreaTitle)}>お問い合わせ内容</div>
          <textarea
            className={classNames(css.textArea)}
            value={customer.inquiryBody || ''}
            onChange={(e) => {
              onChangeInputForm(e, 'inquiryBody', 'textArea');
            }}
          />
          <div className={classNames(css.checkBoxWrap)}>
            <img
              src={image[`check_box_${isButtonActive ? 'active' : 'passive'}.svg`]}
              className={classNames(css.checkBox)}
              onClick={() => {
                setIsButtonActive(!isButtonActive);
              }}
            />
            <Link
              style={{
                color: '#3EB370',
                marginTop: '12px',
                textDecoration: 'underline',
              }}
              to={'/policy'}
              rel="noopener noreferrer"
            >
              <span
                onClick={() => {
                  updateTermsAndPolicy('terms', true);
                }}
              >
                利用規約
              </span>
            </Link>
            <span>・</span>
            <Link
              style={{
                color: '#3EB370',
                marginTop: '12px',
                textDecoration: 'underline',
              }}
              to={'/policy'}
              rel="noopener noreferrer"
            >
              <span
                onClick={() => {
                  updateTermsAndPolicy('terms', false);
                }}
              >
                プライバシーポリシー
              </span>
            </Link>
            に同意する
          </div>
          <LinkButton
            width={inputFormWidth}
            height={linkButtonHeight}
            text="送信する"
            color="black"
            active={isValid && isButtonActive}
            loading={buttonIsLoading}
            onClick={() => {
              sendContact();
            }}
          />
          <StyledDiv
            css={`
              margin-bottom: 6vw !important;
            `}
          />
        </StyledDiv>
      </div>
    </>
  );
};
