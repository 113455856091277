import { useState, forwardRef } from 'react';
import styled from 'styled-components';
import {
  MdArrowBackIosNew,
  MdKeyboardArrowDown,
  MdKeyboardArrowRight,
  MdOutlineKeyboardArrowUp,
  MdArrowForwardIos,
  MdKeyboardArrowUp,
} from 'react-icons/md';
import css from '../index.module.scss';
import classNames from 'classnames';
import { Complement } from './utils/Complement';

const BoxButtonCon = styled.div`
  height: 48px;
  border-radius: 4px;
  position: relative;
  > button {
    background-color: transparent;

    border: none;
    outline: none;
    padding: 0;
    appearance: none;
    text-align: center;
    color: #ffffff;
    font-family: 'KiwiMaru';

    font-size: 20px;
    line-height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
`;

export function BoxButton(props) {
  const disabledStyle = props.disabled ? css.bcC4C4C4 : css.bc161c1c;
  return (
    <BoxButtonCon className={classNames(props.className, disabledStyle)}>
      <button onClick={props.onClick} disabled={props.disabled} type="button">
        <div className={classNames(css.flexCenter)}>
          {props.text || '---'}
          <div className={props.isLoading ? css.loaderWhite : css.displayNone}>Loading...</div>
        </div>
      </button>
    </BoxButtonCon>
  );
}

const WhiteTextButtonCon = styled.div`
  text-align: center;
  padding: 12px;
  border: 1px solid #333;
  background-color: #ffffff;
  margin-top: 16px;
  margin-bottom: 32px;
`;

export function WhiteBoxButton(props) {
  return (
    <WhiteTextButtonCon onClick={props.onClick}>
      <TextButtonInner className={css.VertAlC}>{props.text || '---'}</TextButtonInner>
      <MdArrowForwardIos className={css.VertAlC} />
    </WhiteTextButtonCon>
  );
}

const MiniBoxButtonCon = styled.div`
  margin-top: 30px;
  width: 25.87vw;
  max-height: 40px;
  height: 10.67vw;
  background: #161c1c;
  border-radius: 4px;
  position: relative;
  border: 1px solid black;
  > button {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    appearance: none;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    font-family: 'KiwiMaru';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  :hover {
    background-color: white;
    button {
      color: black;
    }
  }
`;

const MiniBoxButtonConWhite = styled.div`
  margin-top: 30px;
  width: 25.87vw;
  max-height: 40px;
  height: 10.67vw;
  background: white;
  border-radius: 4px;
  position: relative;
  border: 1px solid black;
  > button {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    appearance: none;
    text-align: center;
    color: black;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    font-family: 'KiwiMaru';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  :hover {
    background-color: black;
    button {
      color: white;
    }
  }
`;

export function MiniBoxButton(props) {
  const disabledStyle = props.disabled ? css.disabledTrue : '';
  const buttonColorWhite = props.buttonColorWhite;

  if (buttonColorWhite) {
    return (
      <MiniBoxButtonConWhite className={classNames(disabledStyle)} onClick={props.onClick}>
        <button>{props.text || '---'}</button>
      </MiniBoxButtonConWhite>
    );
  } else {
    return (
      <MiniBoxButtonCon className={classNames(props.className, disabledStyle)} onClick={props.onClick}>
        <button>{props.text || '---'}</button>
      </MiniBoxButtonCon>
    );
  }
}

export const RequiredLabel = styled.span`
  align-items: center;
  text-align: center;
  color: #ffffff;
  background: #ff5656;
  border-radius: 4px;
  padding: 2px 4px 2px 4px;
  margin-left: 4px;
`;

const TextButtonCon = styled.div`
  text-align: center;
  padding: 12px;
`;

const TextButtonInner = styled.span``;

export function TextButton(props) {
  return (
    <TextButtonCon onClick={props.onClick}>
      <MdArrowBackIosNew className={css.mb2_5_m} />
      <TextButtonInner className={css.VertAlC}>{props.text || '---'}</TextButtonInner>
    </TextButtonCon>
  );
}

// InputBoxとInputBoxHalfのstyledが逆の命名になっている（いつかなおしたい）
// exportすると混乱しそうなのでその場合は要修正
const InputBoxCon = styled.div`
  padding: ${(props) => props.paddingTop || 12}px 0 0 0;
`;

export const InputBoxTitle = styled.div`
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  color: #161c1c;
`;

const InputBoxInp = styled.input`
  padding: 24px 0 24px 24px;
  width: calc(100% - 24px);
  border-radius: 4px;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #6b757e;
  height: 20px;
  background: #f3f4f5;
  :focus {
    border: solid 1px #161c1c;
  }
  display: block;
  border: none;
  color: #161c1c;
  outline: none;
`;

const InputBoxDesc = styled.div`
  margin-top: 8px;
  font-size: 16px;
  line-height: 16px;
  color: #6b757e;
`;

const InputBoxErrorDesc = styled.div`
  margin-top: 8px;
  font-size: 16px;
  line-height: 16px;
  color: red;
`;

export function InputBoxHalf(props) {
  const requiredLabel = props.requiredLabel ? <RequiredLabel>必須</RequiredLabel> : null;

  return (
    <InputBoxCon>
      <InputBoxTitle>
        {props.title || ''}
        {requiredLabel}
      </InputBoxTitle>
      <div className={classNames(css.input_box)}>
        <div className={classNames(css.half, props.isError ? css.border_red : css.none)}>
          <InputBoxInp
            type={props.type || 'text'}
            placeholder={props.placeholder || ''}
            onChange={props.onChange || null}
            defaultValue={props.content || null}
            id={props.id}
          />
          <img
            src={`${process.env.PUBLIC_URL}/tick_mark.svg`}
            alt=""
            className={props.isValid ? css.tick_half : css.displayNone}
          />
          <img
            src={`${process.env.PUBLIC_URL}/cross_mark.svg`}
            alt=""
            className={props.isError ? css.cross_half : css.displayNone}
          />
        </div>
      </div>
      <InputBoxDesc>{props.description}</InputBoxDesc>
      {props.isError && <InputBoxErrorDesc>{props.errorMessage}</InputBoxErrorDesc>}
    </InputBoxCon>
  );
}

export function InputBoxHalf2(props) {
  // title="郵便番号"
  const requiredLabel = props.requiredLabel ? (
    <>
      <RequiredLabel>必須</RequiredLabel> <Complement props={props}></Complement>
    </>
  ) : null;

  return (
    <InputBoxCon>
      <InputBoxTitle>
        {props.title || ''}
        {requiredLabel}
      </InputBoxTitle>
      <div className={classNames(css.input_box)}>
        <div className={classNames(css.half, props.isError ? css.border_red : css.none)}>
          <InputBoxInp
            type={props.type || 'tel'}
            value={props.value || ''}
            placeholder={props.placeholder || ''}
            onChange={props.onChange || null}
            onKeyUp={props.onKeyUp || null}
            onKeyDown={props.onKeyDown || null}
            onBlur={props.onBlur || null}
            defaultValue={props.content || null}
            id={props.id}
          />
          <img
            src={`${process.env.PUBLIC_URL}/tick_mark.svg`}
            alt=""
            className={props.isValid ? css.tick_half : css.displayNone}
          />
          <img
            src={`${process.env.PUBLIC_URL}/cross_mark.svg`}
            alt=""
            className={props.isError ? css.cross_half : css.displayNone}
          />
        </div>
      </div>
      <InputBoxDesc>{props.description}</InputBoxDesc>
      {props.isError && <InputBoxErrorDesc>{props.errorMessage}</InputBoxErrorDesc>}
    </InputBoxCon>
  );
}

export const InputBoxHalfCon = styled.div`
  width: 100%;
  padding: ${(props) => (props.padding ? props.padding[0] : 12)}px
    ${(props) => (props.padding ? props.padding[1] : 0)}px ${(props) => (props.padding ? props.padding[2] : 0)}px
    ${(props) => (props.padding ? props.padding[3] : 0)}px;
`;

export const InputBoxHalfTitle = styled.div`
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  height: 16px;
  color: #161c1c;
`;

export const InputBoxHalfInp = styled.input`
  padding: 24px 0 24px 24px;
  width: calc(100% - 24px);
  border-radius: 4px;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #6b757e;
  height: 20px;
  background: #f3f4f5;
  :focus {
    border: solid 1px #161c1c;
  }
  display: block;
  border: none;
  outline: none;
  color: #161c1c;
`;

export const InputBoxHalfDesc = styled.div`
  margin-top: 8px;
  font-size: 16px;
  line-height: 16px;
  color: #6b757e;
`;

export const InputBoxHalfErrorDesc = styled.div`
  margin-top: 8px;
  font-size: 16px;
  line-height: 16px;
  color: red;
`;

export const InputBox = forwardRef(function InputBoxFunction(props, ref) {
  // title="お名前, ふりがな, メールアドレス, 電話番号,番地"
  const requiredLabel = props.requiredLabel ? (
    <>
      <RequiredLabel>必須</RequiredLabel> <Complement props={props}></Complement>
    </>
  ) : null;
  if (props.simplify)
    return (
      <InputBoxHalfInp
        type={props.type || 'text'}
        placeholder={props.placeholder || ''}
        onChange={props.onChange || null}
        defaultValue={props.content || null}
        id={props.id}
        onKeyUp={props.onKeyUp || null}
      />
    );
  return (
    <InputBoxHalfCon padding={props.padding}>
      <InputBoxHalfTitle>
        {props.title || ''}
        {requiredLabel}
      </InputBoxHalfTitle>
      <div className={classNames(css.input_box)}>
        <div className={props.isError ? css.border_red : css.none}>
          <InputBoxHalfInp
            type={props.type || 'text'}
            placeholder={props.placeholder || ''}
            onChange={props.onChange || null}
            onKeyUp={props.onKeyUp || null}
            onBlur={props.onBlur}
            ref={ref}
            defaultValue={props.content || null}
            id={props.id}
          />
          <img
            src={`${process.env.PUBLIC_URL}/tick_mark.svg`}
            alt=""
            className={props.isValid ? css.tick : css.displayNone}
          />
          <img
            src={`${process.env.PUBLIC_URL}/cross_mark.svg`}
            alt=""
            className={props.isError ? css.cross : css.displayNone}
          />
        </div>
      </div>
      <InputBoxHalfDesc>{props.description}</InputBoxHalfDesc>
      {props.isError && <InputBoxHalfErrorDesc>{props.errorMessage}</InputBoxHalfErrorDesc>}
    </InputBoxHalfCon>
  );
});

//MEMO:InputBox2
//InputBoxとの違いは、input要素に入力された値とは別にvalueを引数(props)に持つことです。
//onKeyUp等の処理で入力された値を整形して、valueに再格納する目的で用います
export const InputBox2 = forwardRef(function InputBoxFunction2(props, ref) {
  const requiredLabel = props.requiredLabel ? (
    <>
      <RequiredLabel>必須</RequiredLabel> <Complement props={props}></Complement>
    </>
  ) : null;
  if (props.simplify)
    return (
      <InputBoxHalfInp
        placeholder={props.placeholder || ''}
        onChange={props.onChange || null}
        defaultValue={props.content || null}
        id={props.id}
      />
    );
  return (
    <InputBoxHalfCon padding={props.padding}>
      <InputBoxHalfTitle>
        {props.title || ''}
        {requiredLabel}
      </InputBoxHalfTitle>
      <div className={classNames(css.input_box)}>
        <div className={props.isError ? css.border_red : css.none}>
          <InputBoxHalfInp
            type={props.type || ''}
            value={props.value || ''}
            placeholder={props.placeholder || ''}
            onChange={props.onChange || null}
            onKeyUp={props.onKeyUp || null}
            onKeyDown={props.onKeyDown || null}
            onBlur={props.onBlur || null}
            ref={ref}
            defaultValue={props.content || null}
            id={props.id}
          />
          <img
            src={`${process.env.PUBLIC_URL}/tick_mark.svg`}
            alt=""
            className={props.isValid ? css.tick : css.displayNone}
          />
          <img
            src={`${process.env.PUBLIC_URL}/cross_mark.svg`}
            alt=""
            className={props.isError ? css.cross : css.displayNone}
          />
        </div>
      </div>
      <InputBoxHalfDesc>{props.description}</InputBoxHalfDesc>
      {props.isError && <InputBoxHalfErrorDesc>{props.errorMessage}</InputBoxHalfErrorDesc>}
    </InputBoxHalfCon>
  );
});

const FormHeaderCon = styled.div`
  min-height: 132px;
  padding: 24px 0;
  svg {
    width: 24px;
    height: 24px;
  }
`;

const FormHeaderInner = styled.div``;

const ProgressBarCon = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 8px;
  > img {
    width: calc(100% * 4 / 6);
    max-width: 560px;
  }
`;

const ProgressBarInner = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 100%;
  color: #c4c4c4;
  justify-content: space-between;
  margin: 0 auto 32px auto;
  width: calc(100% * 5 / 6);
  max-width: 700px;
  > div {
    flex-basis: 20%;
    text-align: center;
  }
  div:nth-child(${(props) => props.num}) {
    color: #161c1c;
  }
  div:nth-child(1) {
    white-space: nowrap;
  }
`;

export const FormHeader = (props) => {
  const progressBars = {
    step1: `${process.env.PUBLIC_URL}/progress_bar_1.webp`,
    step2: `${process.env.PUBLIC_URL}/progress_bar_2.webp`,
    step3: `${process.env.PUBLIC_URL}/progress_bar_3.webp`,
    step4: `${process.env.PUBLIC_URL}/progress_bar_4.webp`,
    step5: `${process.env.PUBLIC_URL}/progress_bar_5.webp`,
  };
  const highlightTexts = {
    step1: 1,
    step2: 2,
    step3: 3,
    step4: 4,
    step5: 5,
  };

  return (
    <FormHeaderCon className={css.wd100}>
      <ProgressBarCon>
        <img src={progressBars[props.step]} alt="" className={css.vertAlM} />
      </ProgressBarCon>
      <ProgressBarInner num={highlightTexts[props.step]}>
        <div>お客様情報</div>
        <div>配送</div>
        <div>決済</div>
        <div>確認</div>
        <div>完了</div>
      </ProgressBarInner>
      <FormHeaderInner className={classNames(css.formHeaderInner)}>
        <img src={`${process.env.PUBLIC_URL}/bene.svg`} alt="" className={classNames(css.mr12, css.vertAlM)} />
        <span className={css.vertAlM}>{props.title || ''}</span>
      </FormHeaderInner>
    </FormHeaderCon>
  );
};

const PullDownHidden = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
`;

const PullDownShown = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
`;

export const PullDown = (props) => {
  // 建物名/部屋名  会社名/部門
  const [opened, setOpened] = useState(props.isSelected || false);
  function handleClick() {
    setOpened(!opened);
    props.setIsSelected(false);
  }
  function focus() {
    // setTimeoutで遅延処理を行わないとfocus()があたらない、おそらくgetElementByIdの取得ができていない？
    setTimeout(() => {
      document.getElementById(props.id).focus();
    }, 0);
  }
  if (props.isSelected || opened) {
    return (
      <>
        <PullDownShown className={classNames(props.className, css.mb12)}>
          <div style={{ marginBottom: '8px' }}>
            <MdOutlineKeyboardArrowUp className={classNames(css.vertAlM, css.wh24)} />
            <span
              className={css.vertAlM}
              onClick={() => {
                handleClick();
              }}
            >
              {props.title || '--'}
            </span>
            <Complement props={props}></Complement>
          </div>

          {props.content || '--'}
        </PullDownShown>
      </>
    );
  }
  return (
    <PullDownHidden
      onClick={() => {
        handleClick();
        focus();
      }}
      className={props.className}
    >
      <MdKeyboardArrowDown className={classNames(css.vertAlM, css.wh24)} />
      <span className={css.vertAlM}>{props.title || '--'}</span>
    </PullDownHidden>
  );
};

const AddInputCon = styled.div`
  border: 1px solid #c4c4c4;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
`;
const AddInputLeft = styled.div`
  width: 80%;
`;

const AddInputRight = styled.div`
  width: 20%;
  text-align: right;
`;

export const AddInput = (props) => {
  return (
    <AddInputCon>
      <AddInputLeft>{props.text || '---'}</AddInputLeft>
      <AddInputRight>
        <MdKeyboardArrowRight className={css.VertAlC} />
      </AddInputRight>
    </AddInputCon>
  );
};

export const AddInputDown = (props) => {
  return (
    <AddInputCon>
      <AddInputLeft>{props.text || '---'}</AddInputLeft>
      <AddInputRight>
        <MdKeyboardArrowDown className={css.VertAlC} />
      </AddInputRight>
    </AddInputCon>
  );
};

export const AddInputUp = (props) => {
  return (
    <AddInputCon>
      <AddInputLeft>{props.text || '---'}</AddInputLeft>
      <AddInputRight>
        <MdKeyboardArrowUp className={css.VertAlC} />
      </AddInputRight>
    </AddInputCon>
  );
};
