import styled from 'styled-components';

const camelToKebab = (str) => {
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
};

export const responsiveStyle = (styles) => {
  return Object.entries(styles)
    .map(
      ([key, value]) => `
        ${camelToKebab(key)}: calc(${value[0]} / 375 * 100vw);
        @media (min-width: 375px){
          ${camelToKebab(key)}: calc(
            (${value[1]} - ${value[0]}) * 100vw / (834 - 375)
            + (${value[0]} * 834 - ${value[1]} * 375) * 1px / (834 - 375)
          );
        }
        @media (min-width: 834px) {
          ${camelToKebab(key)}: calc(
            (${value[2]} - ${value[1]}) * 100vw / (1440 - 834)
            + (${value[1]} * 1440 - ${value[2]} * 834)* 1px / (1440 - 834)
          );
        }
        @media (min-width: 1440px){
          ${camelToKebab(key)}: calc(${value[2]} / 1440 * 100vw);
        }
      `
    )
    .join('');
};

export const setVars = (variables) => {
  return Object.entries(variables)
    .map(
      ([key, value]) => `
        --${key}: ${value};
      `
    )
    .join('');
};

export const StyledDiv = styled.div`
  && {
    ${({ responsive }) => {
      return responsiveStyle(responsive || {});
    }}
  }
  ${({ css }) => {
    return css || '';
  }}
  ${({ variables }) => {
    return setVars(variables || {});
  }}
`;

export const StyledInput = styled.input`
  && {
    ${({ responsive }) => {
      return responsiveStyle(responsive || {});
    }}
  }
  ${({ css }) => {
    return css || '';
  }}
  ${({ variables }) => {
    return setVars(variables || {});
  }}
`;

export const StyledImg = styled.img`
  && {
    ${({ responsive }) => {
      return responsiveStyle(responsive || {});
    }}
  }
  ${({ css }) => {
    return css || '';
  }}
  ${({ variables }) => {
    return setVars(variables || {});
  }}
`;

export const StyledButton = styled.button`
  && {
    ${({ responsive }) => {
      return responsiveStyle(responsive || {});
    }}
  }
  ${({ css }) => {
    return css || '';
  }}
  ${({ variables }) => {
    return setVars(variables || {});
  }}
`;

export const useStyledDiv = (amount = 1) => {
  return Array(amount)
    .fill()
    .map(() => styled(StyledDiv)``);
};

export const useStyledImg = (amount = 1) => {
  return Array(amount)
    .fill()
    .map(() => styled(StyledImg)``);
};

export const useStyledButton = (amount = 1) => {
  return Array(amount)
    .fill()
    .map(() => styled(StyledButton)``);
};
