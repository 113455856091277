import { Children, useState } from 'react';
import { importAll } from '../../utils/image';
import { Wrapper } from './scss';

export const PullDown = ({ children, className, title, description, onClick = () => {}, setItem = () => {} }) => {
  const image = importAll(require.context('./image', false, /\.(webp|svg)$/));
  const width = [340, 512, 720];
  const height = [48, 54, 60];
  const [activePullDown, setActivePullDown] = useState(false);
  const [selected, setSelected] = useState('');
  const childLst = [];
  Children.forEach(children, (child, key) => {
    childLst.push(
      <div
        className="choice"
        key={key}
        onClick={() => {
          setSelected(key);
          setItem(children[key].props.children);
          setTimeout(() => {
            setActivePullDown(false);
          }, 1000 * 0.2);
        }}
      >
        {child}
        <div>{selected === key ? '◉' : '○'}</div>
      </div>
    );
  });
  return (
    <Wrapper
      className={className}
      variables={{ arrowVector: activePullDown ? '180deg' : '0deg' }}
      height={height}
      width={width}
    >
      <div className="title">{title}</div>
      <div className="pullDownWrap">
        <div
          onClick={() => {
            setActivePullDown(!activePullDown);
            onClick();
          }}
          className="pullDown"
        >
          {description || children?.[selected] || '-'}
          <img src={image['arrow_down.svg']} />
        </div>
        {activePullDown && childLst}
      </div>
    </Wrapper>
  );
};
