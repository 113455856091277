export const searchPostalCode = async (postCode) => {
  const url = 'https://postcode.teraren.com/postcodes/' + postCode + '.json';
  const response = await fetch(url)
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch(() => {
      return null;
    });
  return response;
};
