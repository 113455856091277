import css from '../index.module.scss';
import classNames from 'classnames';
import { db } from '../../zz_general/utils/configs/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useRef, useState, useEffect } from 'react';
import jsQR from 'jsqr';
import { importAll } from '../utils/image';

export const ReadQR = () => {
  const image = importAll(require.context('../img', false, /\.(webp|avif|svg)$/));
  const [scanResult, setScanResult] = useState('');
  const [isScanning, setIsScanning] = useState();
  const [productStatus, setProductStatus] = useState();
  const [itemList, setItemList] = useState();
  const [name, setName] = useState();
  const [kana, setKana] = useState();
  const videoRef = useRef(null);
  const canvasRef = useRef(document.createElement('canvas'));

  const startScan = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
      videoRef.current.srcObject = stream;
      videoRef.current.play();
      setIsScanning(true);
      scanQR();
    }
  };

  const scanQR = () => {
    if (videoRef.current.readyState === videoRef.current.HAVE_ENOUGH_DATA) {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      canvas.height = video.videoHeight;
      canvas.width = video.videoWidth;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const qrCode = jsQR(imageData.data, imageData.width, imageData.height);

      if (qrCode) {
        setScanResult(qrCode.data);
        stopScan();
        return;
      }
    }
    requestAnimationFrame(scanQR);
  };

  // const stopScan = () => {
  //   if (videoRef.current.srcObject) {
  //     videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
  //     videoRef.current.pause();
  //   }
  //   setIsScanning(false);
  // };

  const stopScan = () => {
    try {
      if (videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
        videoRef.current.srcObject = null;
        videoRef.current.style.display = 'none';
      }
      setIsScanning(false);
    } catch (error) {
      console.error('Failed to stop video stream', error);
    }
  };

  const updateDBStatus = async (docId, status) => {
    const docRef = doc(
      db,
      'kamaitachi',
      process.env.REACT_APP_IS_PROD === 'true' ? 'add-kamaitachi' : 'add-kamaitachi-test',
      'reservations',
      docId
    );
    await updateDoc(docRef, {
      deliveryStatus: status,
    });
  };

  const fetchDB = async (docId) => {
    const docRef = doc(
      db,
      'kamaitachi',
      process.env.REACT_APP_IS_PROD === 'true' ? 'add-kamaitachi' : 'add-kamaitachi-test',
      'reservations',
      docId
    );
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  };

  useEffect(() => {
    if (scanResult) {
      const promise = fetchDB(scanResult);
      promise
        .then((response) => {
          const statusList = { no: '未受け渡し', yes: '受け渡し済み', cancel: 'キャンセル' };
          setProductStatus(statusList[response.deliveryStatus]);
          const itemElemList = Object.entries(response.items).map(
            ([key, value]) =>
              value.count > 0 && (
                <div key={key} className={classNames(css.itemList)}>
                  <div className={classNames(css.itemName)}>{value.name}</div>
                  <div className={classNames(css.itemAmount)}>{value.count}</div>
                </div>
              )
          );
          setItemList(itemElemList);
          setName(response.fName + ' ' + response.lName);
          setKana(response.fNameKana + ' ' + response.lNameKana);
        })
        // eslint-disable-next-line no-console
        .catch((error) => console.log(error));
    }
  }, [scanResult]);

  return (
    <div className={classNames(css.readQR)}>
      {scanResult && (
        <div className={classNames(css.reserveInfoCon)}>
          <div className={classNames(css.reserveID)}>予約番号：{scanResult} </div>
          <div className={classNames(css.reserveID)}>
            お名前：{name}（{kana}）様
          </div>
          <div className={classNames(css.content)}>ご注文内容</div>
          {itemList}
          <div className={classNames(css.statusTitle)}>ステータス</div>
          <div className={classNames(css.status)}>{productStatus}</div>
          <button
            className={classNames(css.tradeDone)}
            onClick={() => {
              updateDBStatus(scanResult, 'yes');
              setProductStatus('受け渡し済み');
            }}
          >
            受け渡し済み
          </button>
          <button
            className={classNames(css.tradeCancel)}
            onClick={() => {
              updateDBStatus(scanResult, 'cancel');
              setProductStatus('キャンセル');
            }}
          >
            キャンセル
          </button>
          <button
            className={classNames(css.tradeCancel)}
            onClick={() => {
              updateDBStatus(scanResult, 'no');
              setProductStatus('未受け渡し');
            }}
          >
            未受け渡し
          </button>
        </div>
      )}

      <button
        className={classNames(css.scanQRButton)}
        onClick={() => {
          isScanning || startScan();
        }}
      >
        QRコード読み取り
      </button>

      {isScanning && <div className={classNames(css.qrScannerBackGround)} onClick={stopScan}></div>}
      <div className={classNames(css.qrScanner)}>
        <video
          ref={videoRef}
          style={isScanning ? { display: 'block' } : { display: 'none' }}
          className={classNames(css.video)}
        />
        {isScanning && <img src={image['cancel.webp']} onClick={stopScan} className={classNames(css.cancelButton)} />}
      </div>
    </div>
  );
};
