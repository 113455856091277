// MEMO: Firestore内の注文情報をヤマト発送伝票とLark通知用に加工する
export const purchasedItems = (orderItems) => {
  const comma = ',';
  const connection = 'x';
  let purchasedItemsResult = '';

  // MEMO: 製品数

  const products = {
    /** Primo Tiramisu in box */
    primoTiramisuInBox: {
      value: 'PTBo',
      count: 0,
    },
    /** Primo Tiramisu in bag */
    primoTiramisuInBag: {
      value: 'PTBa',
      count: 0,
    },
    /** Tiramisu Financier */
    tiramisuFinancier: {
      value: 'TF',
      count: 0,
    },
    /** Tiramisu Macaron */
    tiramisuMacaron: {
      value: 'TM4',
      count: 0,
    },
    /** Espresso Sauce */
    espressoSauce: {
      value: 'ES',
      count: 0,
    },
    /** Chocolat Feuillantine */
    chocolatFeuillantine: {
      value: 'CF',
      count: 0,
    },
    /** Tiramisu in un bicchiere */
    tiramisuInUnBicchiere: {
      value: 'TIB',
      count: 0,
    },
    /** Mascarpone Gelato */
    mascarponeGelato: {
      value: 'MG',
      count: 0,
    },
    /** Espresso Gelato */
    espressoGelato: {
      value: 'EG',
      count: 0,
    },
    /** Tiramisu Gelato 2×3 Set */
    tiramisuGelato2x3Set: {
      value: 'GS3',
      count: 0,
    },
    /** Tiramisu Gelato 2×6 Set */
    tiramisuGelato2x6Set: {
      value: 'GS6',
      count: 0,
    },
    /** 抹茶ティラミスプリン4個 */
    matchaPudding: {
      value: 'MTP4',
      count: 0,
    },
    /** Tiramisu Bianco */
    tiramisuBianco: {
      value: 'TB',
      count: 0,
    },
    /** Tiramisu Nero */
    tiramisuNero: {
      value: 'TN',
      count: 0,
    },
    /** ティラミスまん */
    tiramisuMan: {
      value: 'Tmn5',
      count: 0,
    },
    /** 生チョコティラミス16Box */
    rawChocolateTiramisu: {
      value: 'NchoT16',
      count: 0,
    },
    /** ねぎとろティラミス5 */
    negitoroTiramisu: {
      value: 'NGTR',
      count: 0,
    },
    /** coaster ×3 set */
    coaster: {
      value: 'CS',
      count: 0,
    },
  };

  if (0 < orderItems?.uid1) {
    // Primo Tiramisu in box
    products.primoTiramisuInBox.count = Number(orderItems.uid1);
  }
  if (0 < orderItems?.uid2) {
    // Primo Tiramisu in bag
    products.primoTiramisuInBag.count = Number(orderItems.uid2);
  }
  if (0 < orderItems?.uid3) {
    // Tiramisu Financier ×2
    products.tiramisuFinancier.count += Number(orderItems.uid3) * 2;
  }
  if (0 < orderItems?.uid4) {
    // Tiramisu Financier ×10
    products.tiramisuFinancier.count += Number(orderItems.uid4) * 10;
  }
  if (0 < orderItems?.uid5) {
    // Chocolat Feuillantine
    products.chocolatFeuillantine.count = Number(orderItems.uid5);
  }
  if (0 < orderItems?.uid6) {
    // Espresso Sauce
    products.espressoSauce.count = Number(orderItems.uid6);
  }
  // uid7は欠番
  if (0 < orderItems?.uid8) {
    // Tiramisu Macaron 4個Set
    products.tiramisuMacaron.count = Number(orderItems.uid8);
  }
  if (0 < orderItems?.uid9) {
    // Tiramisu Lovers Set
    products.primoTiramisuInBox.count = Number(orderItems.uid9);
    products.tiramisuMacaron.count = Number(orderItems.uid9);
    products.tiramisuFinancier.count += Number(orderItems.uid9) * 10;
  }
  if (0 < orderItems?.uid10) {
    // Tiramisu Super Lovers Set
    products.primoTiramisuInBox.count = Number(orderItems.uid10);
    products.tiramisuMacaron.count = Number(orderItems.uid10);
    products.tiramisuGelato2x3Set.count = Number(orderItems.uid10);
    products.tiramisuFinancier.count += Number(orderItems.uid10) * 10;
  }
  if (0 < orderItems?.uid11) {
    // Tiramisu Gelato 2×3 Set
    products.tiramisuGelato2x3Set.count = Number(orderItems.uid11);
  }
  if (0 < orderItems?.uid12) {
    // Tiramisu Gelato 2×6 Set
    products.tiramisuGelato2x6Set.count = Number(orderItems.uid12);
  }
  if (0 < orderItems?.uid13) {
    // Campaign Set 1
    products.primoTiramisuInBox.count = Number(orderItems.uid13);
    products.tiramisuGelato2x3Set.count = Number(orderItems.uid13);
  }
  if (0 < orderItems?.uid14) {
    // Campaign Set 2
    products.tiramisuGelato2x3Set.count = Number(orderItems.uid14);
    products.tiramisuFinancier.count += Number(orderItems.uid14) * 10;
  }
  if (0 < orderItems?.uid15) {
    // Campaign Set 3
    products.tiramisuGelato2x3Set.count = Number(orderItems.uid15);
    products.tiramisuMacaron.count = Number(orderItems.uid15);
  }
  if (0 < orderItems?.uid16) {
    // Tiramisu Bianco Set in Box
    products.tiramisuBianco.count = Number(orderItems.uid16);
  }
  if (0 < orderItems?.uid17) {
    // Tiramisu Nero Set in Box
    products.tiramisuNero.count = Number(orderItems.uid17);
  }
  if (0 < orderItems?.uid18) {
    // 抹茶てぃらみすぷりん4個セット
    products.matchaPudding.count = Number(orderItems.uid18);
  }
  if (0 < orderItems?.uid19) {
    // 抹茶てぃらぷりセット①
    products.matchaPudding.count = Number(orderItems.uid19);
    products.primoTiramisuInBox.count = Number(orderItems.uid19);
  }
  if (0 < orderItems?.uid20) {
    // 抹茶てぃらぷりセット②
    products.matchaPudding.count = Number(orderItems.uid20);
    products.tiramisuFinancier.count += Number(orderItems.uid20) * 10;
  }
  if (0 < orderItems?.uid21) {
    // 抹茶てぃらぷりセット③
    products.matchaPudding.count = Number(orderItems.uid21);
    products.tiramisuMacaron.count = Number(orderItems.uid21);
  }
  if (0 < orderItems?.uid22) {
    // ティラミス尽くしセット
    products.matchaPudding.count = Number(orderItems.uid22);
    products.primoTiramisuInBox.count = Number(orderItems.uid22);
    products.tiramisuMacaron.count = Number(orderItems.uid22);
    products.tiramisuFinancier.count += Number(orderItems.uid22) * 10;
  }
  if (0 < orderItems?.uid23) {
    // ティラミス尽くしフルセット
    products.matchaPudding.count = Number(orderItems.uid23);
    products.primoTiramisuInBox.count = Number(orderItems.uid23);
    products.tiramisuMacaron.count = Number(orderItems.uid23);
    products.tiramisuGelato2x3Set.count = Number(orderItems.uid23);
    products.tiramisuFinancier.count += Number(orderItems.uid23) * 10;
  }
  // MEMO: 白黒復活のため残す
  // if (0 < orderItems?.uid24) {
  //   // Bianco/Nero Tiramisu Set
  //   products.tiramisuBianco.count = Number(orderItems.uid24);
  //   products.tiramisuNero.count = Number(orderItems.uid24);
  // }
  if (0 < orderItems?.uid25) {
    // ティラミスまん5個 Bag入り
    products.tiramisuMan.count = Number(orderItems.uid25);
  }

  if (0 < orderItems?.uid26) {
    // ティラミス尽くしまんセット
    products.primoTiramisuInBox.count = Number(orderItems.uid26);
    products.tiramisuFinancier.count += Number(orderItems.uid26) * 10;
    products.tiramisuMacaron.count = Number(orderItems.uid26);
    products.tiramisuMan.count = Number(orderItems.uid26);
  }

  if (0 < orderItems?.uid27) {
    // ティラミス尽くしフルまんセット
    products.primoTiramisuInBox.count = Number(orderItems.uid27);
    products.tiramisuFinancier.count += Number(orderItems.uid27) * 10;
    products.tiramisuMacaron.count = Number(orderItems.uid27);
    products.Tman5tiramisuGelato2x3Set.count = Number(orderItems.uid27);
    products.tiramisuMan.count = Number(orderItems.uid27);
  }

  if (0 < orderItems?.uid28) {
    // ティラミスまんセット①
    products.tiramisuMan.count = Number(orderItems.uid28);
    products.primoTiramisuInBox.count = Number(orderItems.uid28);
  }

  if (0 < orderItems?.uid29) {
    // ティラミスまんセット②
    products.tiramisuMan.count = Number(orderItems.uid29);
    products.tiramisuFinancier.count += Number(orderItems.uid29) * 10;
  }

  if (0 < orderItems?.uid30) {
    // ティラミスまんセット③
    products.tiramisuMan.count = Number(orderItems.uid30);
    products.tiramisuMacaron.count = Number(orderItems.uid30);
  }

  if (0 < orderItems?.uid31) {
    //     name: '生チョコティラミス16個',
    products.rawChocolateTiramisu.count = Number(orderItems.uid31);
  }

  if (0 < orderItems?.uid32) {
    // セット商品(商品名はキャンペーンによって異なる)①
    products.rawChocolateTiramisu.count = Number(orderItems.uid32);
    products.primoTiramisuInBox.count = Number(orderItems.ui32);
  }

  if (0 < orderItems?.uid33) {
    // セット商品(商品名はキャンペーンによって異なる)②
    products.rawChocolateTiramisu.count = Number(orderItems.uid33);
    products.tiramisuFinancier.count += Number(orderItems.uid9) * 10;
  }

  if (0 < orderItems?.uid34) {
    // セット商品(商品名はキャンペーンによって異なる)③
    products.rawChocolateTiramisu.count = Number(orderItems.uid34);
    products.tiramisuMacaron.count = Number(orderItems.uid34);
  }

  if (0 < orderItems?.uid35) {
    // キャンペーンフルセット
    products.rawChocolateTiramisu.count = Number(orderItems.uid35);
    products.primoTiramisuInBox.count = Number(orderItems.ui35);
    products.tiramisuFinancier.count += Number(orderItems.uid35) * 10;
    products.tiramisuMacaron.count = Number(orderItems.uid35);
  }
  if (0 < orderItems?.uid36) {
    // Tiramisu Nero Set in Box
    products.negitoroTiramisu.count = Number(orderItems.uid36);
  }
  if (0 < orderItems?.uid37) {
    //     name: 'coaster ×3 set',
    products.coaster.count = Number(orderItems.uid37);
  }

  // MEMO: purchasedItemsResultへ合算する処理
  if (products.primoTiramisuInBox.count === 1) {
    purchasedItemsResult += `${comma}${products.primoTiramisuInBox.value}`;
  } else if (products.primoTiramisuInBox.count > 1) {
    purchasedItemsResult += `${comma}${products.primoTiramisuInBox.value}${connection}${products.primoTiramisuInBox.count}`;
  }
  if (products.primoTiramisuInBag.count === 1) {
    purchasedItemsResult += `${comma}${products.primoTiramisuInBag.value}`;
  } else if (products.primoTiramisuInBag.count > 1) {
    purchasedItemsResult += `${comma}${products.primoTiramisuInBag.value}${connection}${products.primoTiramisuInBag.count}`;
  }
  if (products.tiramisuMacaron.count === 1) {
    purchasedItemsResult += `${comma}${products.tiramisuMacaron.value}`;
  } else if (products.tiramisuMacaron.count > 1) {
    purchasedItemsResult += `${comma}${products.tiramisuMacaron.value}${connection}${products.tiramisuMacaron.count}`;
  }
  if (products.espressoSauce.count === 1) {
    purchasedItemsResult += `${comma}${products.espressoSauce.value}`;
  } else if (products.espressoSauce.count > 1) {
    purchasedItemsResult += `${comma}${products.espressoSauce.value}${connection}${products.espressoSauce.count}`;
  }
  if (products.chocolatFeuillantine.count === 1) {
    purchasedItemsResult += `${comma}${products.chocolatFeuillantine.value}`;
  } else if (products.chocolatFeuillantine.count > 1) {
    purchasedItemsResult += `${comma}${products.chocolatFeuillantine.value}${connection}${products.chocolatFeuillantine.count}`;
  }
  if (products.tiramisuInUnBicchiere.count === 1) {
    purchasedItemsResult += `${comma}${products.tiramisuInUnBicchiere.value}`;
  } else if (products.tiramisuInUnBicchiere.count > 1) {
    purchasedItemsResult += `${comma}${products.tiramisuInUnBicchiere.value}${connection}${products.tiramisuInUnBicchiere.count}`;
  }
  if (products.mascarponeGelato.count === 1) {
    purchasedItemsResult += `${comma}${products.mascarponeGelato.value}`;
  } else if (products.mascarponeGelato.count > 1) {
    purchasedItemsResult += `${comma}${products.mascarponeGelato.value}${connection}${products.mascarponeGelato.count}`;
  }
  if (products.espressoGelato.count === 1) {
    purchasedItemsResult += `${comma}${products.espressoGelato.value}`;
  } else if (products.espressoGelato.count > 1) {
    purchasedItemsResult += `${comma}${products.espressoGelato.value}${connection}${products.espressoGelato.count}`;
  }
  if (products.tiramisuGelato2x3Set.count === 1) {
    purchasedItemsResult += `${comma}${products.tiramisuGelato2x3Set.value}`;
  } else if (products.tiramisuGelato2x3Set.count > 1) {
    purchasedItemsResult += `${comma}${products.tiramisuGelato2x3Set.value}${connection}${products.tiramisuGelato2x3Set.count}`;
  }
  if (products.tiramisuGelato2x6Set.count === 1) {
    purchasedItemsResult += `${comma}${products.tiramisuGelato2x6Set.value}`;
  } else if (products.tiramisuGelato2x6Set.count > 1) {
    purchasedItemsResult += `${comma}${products.tiramisuGelato2x6Set.value}${connection}${products.tiramisuGelato2x6Set.count}`;
  }
  if (products.matchaPudding.count === 1) {
    purchasedItemsResult += `${comma}${products.matchaPudding.value}`;
  } else if (products.matchaPudding.count > 1) {
    purchasedItemsResult += `${comma}${products.matchaPudding.value}${connection}${products.matchaPudding.count}`;
  }
  if (products.tiramisuBianco.count === 1) {
    purchasedItemsResult += `${comma}${products.tiramisuBianco.value}`;
  } else if (products.tiramisuBianco.count > 1) {
    purchasedItemsResult += `${comma}${products.tiramisuBianco.value}${connection}${products.tiramisuBianco.count}`;
  }
  if (products.tiramisuNero.count === 1) {
    purchasedItemsResult += `${comma}${products.tiramisuNero.value}`;
  } else if (products.tiramisuNero.count > 1) {
    purchasedItemsResult += `${comma}${products.tiramisuNero.value}${connection}${products.tiramisuNero.count}`;
  }
  if (products.tiramisuMan.count === 1) {
    purchasedItemsResult += `${comma}${products.tiramisuMan.value}`;
  } else if (products.tiramisuMan.count > 1) {
    purchasedItemsResult += `${comma}${products.tiramisuMan.value}${connection}${products.tiramisuMan.count}`;
  }
  if (products.rawChocolateTiramisu.count === 1) {
    purchasedItemsResult += `${comma}${products.rawChocolateTiramisu.value}`;
  } else if (products.rawChocolateTiramisu.count > 1) {
    purchasedItemsResult += `${comma}${products.rawChocolateTiramisu.value}${connection}${products.rawChocolateTiramisu.count}`;
  }
  if (products.tiramisuFinancier.count > 1) {
    purchasedItemsResult += `${comma}${products.tiramisuFinancier.value}${connection}${products.tiramisuFinancier.count}`;
  }
  if (products.coaster.count > 1) {
    purchasedItemsResult += `${comma}${products.coaster.value}${connection}${products.coaster.count}`;
  }

  return purchasedItemsResult.trim();
};
