export function BiancoHowtoeatCard() {
  return (
    <div>
      <img
        src={`${process.env.PUBLIC_URL}/cardImage/TimraisuBianco-Howtoeat-card1024_1.webp`}
        alt=""
        style={{ width: '100vw', marginBottom: 0 }}
      />
      <img
        src={`${process.env.PUBLIC_URL}/cardImage/TimraisuBianco-Howtoeat-card1024_2.webp`}
        alt=""
        style={{ width: '100vw', marginTop: '-6px' }}
      />
    </div>
  );
}
