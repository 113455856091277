import css from '../index.module.scss';
import classNames from 'classnames';
import { importAll } from '../utils/image';
import { useEffect, useState } from 'react';

export const Menu = ({ isLogin, logOut, page, setPage }) => {
  const image = importAll(require.context('../img', false, /\.(webp|avif|svg)$/));
  const [isSlideMenu, setIsSlideMenu] = useState(false);

  const titleText = {
    TOP: '管理者TOP',
    ReadQR: '注文情報',
  };

  useEffect(() => {}, []);
  return (
    <div className={classNames(css.menu)}>
      <header className={classNames(css.header)}>
        {isLogin ? (
          <div
            className={classNames(css.tiramisuIcon)}
            onClick={() => {
              setIsSlideMenu(!isSlideMenu);
            }}
          >
            <div className={classNames(css.firstLine, isSlideMenu && css.firstLineActive)}></div>
            <div className={classNames(css.secondLine, isSlideMenu && css.secondLineActive)}></div>
            <div className={classNames(css.thirdLine, isSlideMenu && css.thirdLineActive)}></div>
            <div className={classNames(css.fourthLine, isSlideMenu && css.fourthLineActive)}></div>
            <div className={classNames(css.fifthLine, isSlideMenu && css.fifthLineActive)}></div>
          </div>
        ) : (
          <div className={classNames(css.flexAdjust)}></div>
        )}

        <div className={classNames(css.titleWrap)}>
          <img src={image['logo.webp']} className={classNames(css.logo)} />
          <h1 className={classNames(css.title)}>{isLogin ? titleText[page] : '管理者ページ'}</h1>
        </div>
        <div className={classNames(css.flexAdjust)}></div>
      </header>
      <div
        className={classNames(
          css.slideMenuBackGround,
          isSlideMenu ? css.slideMenuBackGroundActive : css.slideMenuBackGroundHidden
        )}
        onClick={() => {
          setIsSlideMenu(false);
        }}
      ></div>
      <div className={classNames(css.slideMenu, isSlideMenu ? css.slideMenuActive : css.slideMenuHidden)}>
        {/* MEMO: TOPページはひとまず非表示 */}
        {/* {page === 'TOP' || (
          <div
            onClick={() => {
              setPage('TOP');
              setIsSlideMenu(false);
            }}
          >
            TOP
          </div>
        )} */}
        {page === 'ReadQR' || (
          <div
            onClick={() => {
              setPage('ReadQR');
              setIsSlideMenu(false);
            }}
          >
            QR読み取りカメラ
          </div>
        )}
        <div
          onClick={() => {
            logOut();
            setIsSlideMenu(false);
          }}
        >
          ログアウト
        </div>
      </div>
    </div>
  );
};
