import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { RecoilRoot } from 'recoil';
import ScrollToTop from './lp/utils/ScrollToTop';

import { Confirm } from './lp/Confirm';
import { DeliveryInfo } from './lp/DeliveryInfo';
import { InputAccountInfo } from './lp/InputAccountInfo';
import Payment from './lp/Payment';
import Home from './lp/home/index';
import { AddressInfo } from './lp/AddressInfo';
import { AddressEdit } from './lp/AddressEdit';
import { Menu } from './lp/Menu';
import { Maintenance } from './lp/maintenance';
import { TransactionAct } from './lp/TransactionAct';
import { Policy } from './lp/Policy';
import Thanks from './lp/Thanks';
import { NeroHowtoeatCard } from './lp/NeroHowtoeatCard';
import { BiancoHowtoeatCard } from './lp/BiancoHowtoeatCard';

import { Management } from './management/Management';

import { Secret } from './secret/secret';

import { Akigawa } from './shop/akigawa';
import { Yoyogi } from './shop/yoyogi';

import KamaitachiTop from './add-kamaitachi-customer/top';
import { KamaitachiContact } from './add-kamaitachi-customer/contact/index';

import { KamaitachiCustomerInfoDelivery } from './add-kamaitachi-customer/delivery/customer-info/index';
import { KamaitachiPaymentDelivery } from './add-kamaitachi-customer/delivery/payment/index';
import { KamaitachiDeliveryInfo } from './add-kamaitachi-customer/delivery/delivery-info/index';
import { KamaitachiAddressInfo } from './add-kamaitachi-customer/delivery/address-info/index';
import { KamaitachiConfirmDelivery } from './add-kamaitachi-customer/delivery/confirm';
import { KamaitachiThanksDelivery } from './add-kamaitachi-customer/delivery/thanks/index';

import { KamaitachiCustomerInfoTakeout } from './add-kamaitachi-customer/takeout/customer-info/index';
import { KamaitachiPaymentTakeout } from './add-kamaitachi-customer/takeout/payment/index';
import { KamaitachiConfirmTakeout } from './add-kamaitachi-customer/takeout/confirm';
import { KamaitachiThanksTakeout } from './add-kamaitachi-customer/takeout/thanks/index';
import { KamaitachiManagement } from './kamaitachi-management/index';

import { KamaitachiThanks } from './add-kamaitachi-customer/thanks';

const stripePromise =
  process.env.REACT_APP_IS_PROD === 'true'
    ? loadStripe(process.env.REACT_APP_STRIPE_API_KEY.toString())
    : loadStripe(process.env.REACT_APP_STRIPE_TEST_API_KEY.toString());

function App() {
  return (
    <RecoilRoot>
      <Elements stripe={stripePromise}>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path="/customer" element={<InputAccountInfo />} />
            <Route path="/delivery" element={<DeliveryInfo />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/confirm" element={<Confirm />} />
            <Route path="/address" element={<AddressInfo />} />
            <Route path="/address-edit" element={<AddressEdit />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/maintenance" element={<Maintenance />} />
            <Route path="/management" element={<Management />} />
            <Route path="/transaction-act" element={<TransactionAct />} />
            <Route path="/policy" element={<Policy />} />
            <Route path="/thanks" element={<Thanks />} />
            <Route path="/nero-howtoeat-card" element={<NeroHowtoeatCard />} />
            <Route path="/bianco-howtoeat-card" element={<BiancoHowtoeatCard />} />
            <Route path="/akigawa" element={<Akigawa />} />
            <Route path="/yoyogi" element={<Yoyogi />} />
            <Route path="/secret" element={<Secret />} />

            <Route path="/kamaitachi/management" element={<KamaitachiManagement />} />
            <Route path="/kamaitachi-no-okite" element={<KamaitachiTop />} />
            <Route path="/kamaitachi-no-okite/contact" element={<KamaitachiContact />} />

            {/* MEMO : 追加予約 配送ユーザー用ページ */}
            <Route path="/kamaitachi-no-okite/delivery/customer-info" element={<KamaitachiCustomerInfoDelivery />} />
            <Route path="/kamaitachi-no-okite/delivery/delivery-info" element={<KamaitachiDeliveryInfo />} />
            <Route path="/kamaitachi-no-okite/delivery/address-info" element={<KamaitachiAddressInfo />} />
            <Route path="/kamaitachi-no-okite/delivery/payment" element={<KamaitachiPaymentDelivery />} />
            <Route path="/kamaitachi-no-okite/delivery/confirm" element={<KamaitachiConfirmDelivery />} />
            <Route path="/kamaitachi-no-okite/delivery/customer-page" element={<KamaitachiThanksDelivery />} />

            {/* MEMO : 追加予約 受取ユーザー用ページ */}
            <Route path="/kamaitachi-no-okite/takeout/customer-info" element={<KamaitachiCustomerInfoTakeout />} />
            <Route path="/kamaitachi-no-okite/takeout/payment" element={<KamaitachiPaymentTakeout />} />
            <Route path="/kamaitachi-no-okite/takeout/confirm" element={<KamaitachiConfirmTakeout />} />
            <Route path="/kamaitachi-no-okite/takeout/customer-page" element={<KamaitachiThanksTakeout />} />

            {/* MEMO : 事前予約ユーザー用ページ */}
            <Route path="/kamaitachi-no-okite/customer-page" element={<KamaitachiThanks />} />

            <Route path="/*" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </Elements>
    </RecoilRoot>
  );
}

export default App;
