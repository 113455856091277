import { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import classNames from 'classnames';
import css from '../index.module.scss';
import managementCss from './index.module.scss';
import { SelectTab } from './util/selectTab';
import { LoginComponent } from './util/loginComponent';
import { Order } from './order/index';
import { Analysis } from './analysis/index';
import { Application } from './application/index';
import { Customer } from './customer/index';
import { Item } from './item/index';
import { Shop } from './shop/index';
import { Marketing } from './marketing/index';

//main
export function Management() {
  //ログイン用State
  const [isLogin, setIsLogin] = useState(false);

  //管理画面左メニューtab
  const [tab, setTab] = useState('');

  //firebase用state
  const auth = getAuth();

  //ページのリロード時はsignOutする
  window.onbeforeunload = function () {
    signOut(auth);
  };
  useEffect(() => {
    signOut(auth);
  }, []);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      // ...
    } else {
      // User is signed out
      // ...
      setIsLogin(false);
    }
  });

  const logOut = () => {
    signOut(auth);
    window.location.reload();
  };

  return (
    <div className={managementCss.management}>
      <LoginComponent auth={auth} logOut={logOut} isLogin={isLogin} setIsLogin={setIsLogin} />
      <div className={classNames(managementCss.screen2, isLogin ? '' : css.displayNone)}>
        <div className={managementCss.header}>
          <img
            src={`${process.env.PUBLIC_URL}/top/BENEREGALO_logo1.webp`}
            alt=""
            className={classNames(managementCss.logoImage)}
          />
          <img
            src={`${process.env.PUBLIC_URL}/top/BENEREGALO_logo2.webp`}
            alt=""
            className={classNames(managementCss.logoText)}
          />
        </div>
        <div className={classNames(managementCss.tabs)}>
          <SelectTab setTab={setTab} name={'order'} />
          <SelectTab setTab={setTab} name={'customer'} />
          <SelectTab setTab={setTab} name={'marketing'} />
          <SelectTab setTab={setTab} name={'item'} />
          <SelectTab setTab={setTab} name={'analysis'} />
          <SelectTab setTab={setTab} name={'shop'} />
          <SelectTab setTab={setTab} name={'application'} />
          <div
            className={classNames(managementCss.logout_button)}
            onClick={() => {
              logOut(auth);
            }}
          >
            ログアウト
          </div>
        </div>
        <div className={managementCss.contents}>
          <Order tab={tab} />
          <Customer tab={tab} />
          <Marketing tab={tab} />
          <Item tab={tab} />
          <Analysis tab={tab} />
          <Shop tab={tab} />
          <Application tab={tab} />
        </div>
      </div>
    </div>
  );
}
