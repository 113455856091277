import css from '../index.module.scss';

export function Menu() {
  const encodeUrl =
    'https%3A%2F%2Ffirebasestorage.googleapis.com%2Fv0%2Fb%2Fbene-reglo-lp.appspot.com%2Fo%2FLP%252Fmenu%252Fbene_regalo_menu.pdf%3Falt%3Dmedia%26token%3D7f3e2875-3352-4db6-b3cc-5b42b1e16b40';
  const url = `https://docs.google.com/viewer?url=${encodeUrl}&embedded=true`;

  return (
    <div style={{ height: '100vh' }}>
      {/* <iframe width="100%" height="1300px" src={url} className={css.menu}></iframe> */}
      <iframe src={url} className={css.menu}></iframe>

      {/* MEMO: 過去の遺物 */}
      {/* <iframe
        style={{ width: '100%', height: '100%' }}
        src={`${process.env.PUBLIC_URL}/2023_04_11_menu.pdf`}
        type="application/pdf"
      /> */}
      {/* <a href={`${process.env.PUBLIC_URL}/menu.pdf`}>メニュー表リンク</a> */}
      {/* <img src={`${process.env.PUBLIC_URL}/menu.pdf`} /> */}
      {/* <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" /> */}
    </div>
  );
}
