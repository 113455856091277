import { getDocs, collection } from 'firebase/firestore';
import { doc, setDoc } from 'firebase/firestore';

import { db } from '../../zz_general/utils/configs/firebase';

export const getAddKamaitachiProduct = async () => {
  return await getDocs(collection(db, 'kamaitachi', 'add-kamaitachi', 'items'));
};
export const getLpProduct = async () => {
  return await getDocs(collection(db, 'items'));
};

export const contactCollectionCreate = async (data) => {
  const dbContactCollection = collection(db, 'kamaitachi', 'add-kamaitachi', 'contact');

  try {
    await setDoc(doc(dbContactCollection), { data });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return false;
  }
  return true;
};
