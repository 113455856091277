import css from '../index.module.scss';
import classNames from 'classnames';
import { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';

export const SignIn = ({ auth, logOut, setIsLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');

  const inputEmail = (event) => {
    setEmail(event.target.value);
  };
  const inputPassword = (event) => {
    setPassword(event.target.value);
  };

  const logIn = (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        if (user.uid !== 'mZMeEszgPwQmrcF2N5u0XMxM8IO2') {
          logOut();
        }
        setIsLogin(true);
        // eslint-disable-next-line no-console
        console.log('Loginが成功しました');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error.code);
        // eslint-disable-next-line no-console
        console.log(error.message);
        setLoginError('ログインに失敗しました');
        setTimeout(() => {
          setLoginError('');
        }, 3000);
      });
  };
  return (
    <div className={classNames(css.signIn)}>
      <h1 className={classNames(css.title)}>ログイン</h1>
      <div className={classNames(css.inputLabel)}>
        メールアドレス
        <span className={classNames(css.required)}>必須</span>
      </div>
      <input className={classNames(css.inputBox)} type="text" onChange={(e) => inputEmail(e)} />
      <div className={classNames(css.inputLabel)}>
        パスワード
        <span className={classNames(css.required)}>必須</span>
      </div>
      <input className={classNames(css.inputBox)} type="password" onChange={(e) => inputPassword(e)} />
      {loginError}
      <button
        onClick={() => {
          email && password && logIn(email, password);
        }}
        className={classNames(css.loginButton, email && password ? css.loginButtonActive : css.loginButtonDisabled)}
      >
        ログイン
      </button>
    </div>
  );
};
