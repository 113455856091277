import { atom } from 'recoil';

export const addKamaitachiReservationIdState = atom({
  key: 'addKamaitachiReservationIdState',
  default: '',
});

export const addKamaitachiIsDelivery = atom({
  key: 'addKamaitachiIsDelivery',
  default: false,
});

export const addKamaitachiPaymentState = atom({
  key: 'addKamaitachiPaymentState',
  default: {
    mode: 'stripe',
    stripe: {
      paymentIntent: {
        clientSecret: 'test_key',
      },
      paymentMethod: {
        id: 'test_id',
        card: {
          brand: 'test',
          last4: 'test',
        },
      },
    },
    payPay: {
      paymentLink: 'test_url',
      sentData: false,
    },
  },
});

export const addKamaitachiCustomerState = atom({
  key: 'addKamaitachiCustomerState',
  default: {
    receiveDM: true,
  },
});

export const addKamaitachiFormState = atom({
  key: 'addKamaitachiFormState',
  default: {
    deliveryFormIsValid: false,
    takeoutFormIsValid: false,
    content: {
      lastName: 'not filled',
      firstName: 'not filled',
      lastNameKana: 'not filled',
      firstNameKana: 'not filled',
      mailAddress: 'not filled',
      phoneNum: 'not filled',
      takeoutPlace: 'not filled',
      takeoutDate: 'not filled',
      takeoutTime: 'not filled',
      zipCode: 'not filled',
      street: 'not filled',
      building: 'valid',
      corporation: 'valid',
    },
  },
});

export const addKamaitachiDeliveryFormState = atom({
  key: 'addKamaitachiDeliveryFormState',
  default: {
    isValid: false,
    content: {
      deliveryDate: 'not filled',
      deliveryTime: 'not filled',
    },
  },
});

export const addKamaitachiDeliveryState = atom({
  key: 'addKamaitachiDeliveryState',
  default: {},
});

export const addKamaitachiAddressState = atom({
  key: 'addKamaitachiAddressState',
  default: {
    selected: 0,
    list: [],
  },
});

export const addKamaitachiTakeoutState = atom({
  key: 'addKamaitachiTakeoutState',
  default: {},
});

export const addKamaitachiProductState = atom({
  key: 'addKamaitachiProductState',
  default: {},
});
export const lpProductState = atom({
  key: 'lpProductState',
  default: {},
});

export const addKamaitachiTotalState = atom({
  key: 'addKamaitachiTotalState',
  default: {
    totalCount: 0,
    totalPrice: 0,
    includeDeliveryFee: false,
  },
});
