import { StyledDiv, responsiveStyle } from '../../utils/styled';
import styled from 'styled-components';

const multiplyList = (list, num) => {
  return [list[0] * num, list[1] * num, list[2] * num];
};

export const Wrapper = styled(StyledDiv)`
  * {
    box-sizing: border-box;
    font-family: 'KiwiMaru', 'NotoSanJP';
  }

  margin: 0 auto;
  ${(props) => {
    return responsiveStyle({
      marginBottom: [64, 72, 80],
      width: props.inputFormWidth,
    });
  }}

  .formFlex {
    display: flex;
    justify-content: space-between;
  }

  .marginTop {
    ${responsiveStyle({
      marginTop: [16, 18, 20],
    })}
  }

  .marginBottom {
    ${responsiveStyle({
      marginBottom: [16, 18, 20],
    })}
  }

  .fName {
    ${(props) => {
      return responsiveStyle({
        marginTop: multiplyList(props.inputFormHeight, 0.75),
      });
    }}
  }

  .checkBoxWrap {
    display: flex;
    align-items: flex-end;
    color: #6b757e;
    ${responsiveStyle({
      fontSize: [14, 15, 16],
      lineHeight: [24, 26, 28],
      marginBottom: [16, 18, 20],
    })}

    .checkBox {
      cursor: pointer;
      display: block;
      ${responsiveStyle({
        height: [24, 26, 28],
        marginRight: [12, 13, 14],
      })}
    }
  }

  .building {
    display: var(--showBuildingForm);
  }

  .corporation {
    display: var(--showCorporationForm);
  }

  .linkButton {
    ${responsiveStyle({
      marginTop: [24, 26, 28],
    })}
  }
`;
