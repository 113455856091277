/**
 * ローカルパスでフォルダを指定して、画像を一括インポートします。
 *
 * You can batch import images by specifying a folder with a local path.
 *
 * 使用方法 how to use ）
 *
 *  const image = importAll(require.context('[image folder path/画像フォルダへのパス]', false, /\.([image folder extension/拡張子])$/));
 *
 *  return (
 *    <img src={image["[image file name/画像ファイル名]"]}/>
 *  )
 *
 * 例 example )
 *
 *  const image = importAll(require.context('./image', false, /\.(webp|avif|png)$/));
 *
 *  return (
 *    <img src={image["xxx.webp"]}/>
 *    <img src={image["yyy.avif"]}/>
 *    <img src={image["zzz.png"]}/>
 *  )
 */
export const importAll = (r) => {
  let image = {};
  r.keys().forEach((item) => {
    image[item.replace('./', '')] = r(item);
  });
  return image;
};
