import { useState, useEffect } from 'react';
import css from '../index.module.scss';
import { useNavigate } from 'react-router-dom';
import { MdOutlineKeyboardArrowUp } from 'react-icons/md';
import classNames from 'classnames';

export function TransactionAct() {
  const navigate = useNavigate();
  const [showPageTopButton, setShowPageTopButton] = useState(false);

  const returnTop = (how) => {
    window.scroll({
      top: 0,
      behavior: how,
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollWindow);
    return () => {
      window.removeEventListener('scroll', scrollWindow);
    };
  }, []);

  const scrollWindow = () => {
    const top = 100; //ボタンを表示させたい位置
    let scroll = 0;
    scroll = window.scrollY;
    if (top <= scroll) {
      setShowPageTopButton(true);
    } else {
      setShowPageTopButton(false);
    }
  };
  return (
    <div>
      <div className={`${css.transactionAct}`}>
        <div className={`${css.transactionAct_title}`}>特定商取引法に基づく表示</div>
        <div className={`${css.transactionAct_element}`}>●商品代金</div>
        <div className={`${css.transactionAct_text}`}>各商品ページに記載している価格とします。</div>
        <div className={`${css.transactionAct_element}`}>●商品代金以外に必要な料金</div>
        <div className={`${css.transactionAct_text}`}>
          ・消費税（内税）
          <br />
          ・送料（全国一律1,000円）
        </div>
        <div className={`${css.transactionAct_element}`}>●お支払い方法</div>
        <div className={`${css.transactionAct_text}`}>
          ・クレジットカード/デビットカード
          <br />
          ・PayPay
        </div>
        <div className={`${css.transactionAct_element}`}>●お支払い時期</div>
        <div className={`${css.transactionAct_text}`}>
          ・クレジットカード/デビットカード
          <br />
          ・PayPay
          <br />
          →ご利用の会社に直接お問合せください。
        </div>
        <div className={`${css.transactionAct_element}`}>●商品のお引渡時期</div>
        <div className={`${css.transactionAct_text}`}>
          通常、ご注文の完了から5営業日（土日・祝日除く）以内に発送いたします。
          予約販売の場合、お届け希望日の2日前に発送いたします。
        </div>
        <div className={`${css.transactionAct_element}`}>●キャンセル・返品・交換</div>
        <div className={`${css.transactionAct_text}`}>
          ご注文のキャンセルは注文日から24時間以内までにご連絡いただけますとお受けできます。
          <br />
          商品に欠陥がある場合を除き、基本的には返品のご対応はできかねます。
          <br />
          <br />
          お客様のご状況によっては、発送前にキャンセルをお受けすることはございます。
          <br />
          ご返金の際は、決済手数料7.2％分を差し引いた金額にて処理させていただきます。
          <br />
          <br />
          お届けした商品が不良品であった場合、異なる商品が届いた場合、至急交換対応をさせていただきます。尚、交換可能な在庫がない場合には、ご返金にてご対応させていただきます。
          <br />
          注文内容や商品状態等により注文の取消し・返品をお受けできない場合がございます。
          <br />
          <br />
          当社が指定する方法以外の返品はお受け付けできません。
          <br />
          <br />
          ○返品期限
          <br />
          商品お届けから3日以内
          <br />
          ○返品送料
          <br />
          商品に欠陥がある場合は、弊社で負担いたします。
          <br />
          それ以外は、お客さまのご負担になります。
          <br />
        </div>
        <div className={`${css.transactionAct_element}`}>●販売事業者</div>
        <div className={`${css.transactionAct_text}`}>
          株式会社Eight Good
          <br />
          販売責任者 井下田 淳
        </div>
        <div className={`${css.transactionAct_element}`}>●販売事業者の住所</div>
        <div className={`${css.transactionAct_text}`}>
          〒197-0827
          <br />
          東京都あきる野市油平49-1 <br />
          岡部ビル1階南
        </div>
        <div className={`${css.transactionAct_element}`}>●販売事業者の連絡先</div>
        <div className={`${css.transactionAct_text}`}>
          ○電話番号
          <br />
          042-808-7933
          <br />
          受付時間：平日11:00〜17:00
          <br />
          <br />
          ○メールアドレス
          <br />
          support@bene-regalo.com
        </div>
        {/* <div className={`${css.rectangle}`}>a</div> */}
      </div>
      <div className={css.Policy_and_Transaction_footer}>
        <div
          onClick={() => {
            returnTop('smooth');
          }}
          className={showPageTopButton ? css.pageTop_Button : css.displayNone}
        >
          <p>
            <MdOutlineKeyboardArrowUp className={classNames(css.vertAlM, css.wh24)} />
            <div>Page Top</div>
          </p>
        </div>
        <div>
          <div
            className={css.return_button}
            onClick={() => {
              navigate(-1);
            }}
          >
            ← 戻る
          </div>
        </div>
      </div>
    </div>
  );
}
