export const errorMessage = {
  lNameValidMessage: '姓に無効な文字や空白を含んでいます',
  fNameValidMessage: '名に無効な文字や空白を含んでいます',
  lNameKanaValidMessage: 'ひらがなで入力してください',
  fNameKanaValidMessage: 'ひらがなで入力してください',
  mailValidMessage: '有効なメールアドレスを入力してください',
  phoneNumValidMessage: '10~11桁の有効な電話番号を入力してください',
  postNumValidMessage: '7桁の有効な郵便番号を入力してください',
  cardNumValidMessage: '有効なクレジットカード番号を入力して下さい',
  effectiveDateValidMessage: '有効な有効期限を入力してください',
  securityCodeValidMessage: '有効なセキュリティーコードを入力してください',
  accountNameValidMessage: '有効なカード名義を入力してください',
};

export const sumUpCart = (cart) => {
  let totalPrice = 0;
  for (let key in cart.items) {
    totalPrice += cart.items[key].price * cart.items[key].count;
  }
  return totalPrice;
};

export const itemList = {
  uid1: {
    name: 'Primo Tiramisu in box',
    nameInConfirm: 'Tiramisu in box',
    price: 5000,
    count: 0,
    fileName: 'BENE_REGALO_Tiramisu_in_box.webp',
    fileName2: 'BENE_REGALO_Tiramisu_in_box.avif',
    disabled: false,
    limit: 2,
  },
  uid2: {
    name: 'Primo Tiramisu in bag',
    nameInConfirm: 'Tiramisu in Bag',
    price: 4000,
    count: 0,
    fileName: 'BENE_REGALO_Tiramisu_in_bag.webp',
    fileName2: 'BENE_REGALO_Tiramisu_in_bag.avif',
    disabled: false,
    limit: 2,
  },
  uid3: {
    name: 'Tiramisu Financier ×2',
    nameInConfirm: 'Financier ×2',
    price: 500,
    count: 0,
    fileName: 'BENE_REGALO_Tiramisu_Financier_2.webp',
    fileName2: 'BENE_REGALO_Tiramisu_Financier_2.avif',
    disabled: false,
    limit: null, //無制限
  },
  uid4: {
    name: 'Tiramisu Financier ×10',
    nameInConfirm: 'Financier ×10',
    price: 2500,
    count: 0,
    fileName: 'BENE_REGALO_Tiramisu_Financier_10.webp',
    fileName2: 'BENE_REGALO_Tiramisu_Financier_10.avif',
    disabled: false,
    limit: null,
  },
  uid5: {
    name: 'Chocolat Feuillantine',
    nameInConfirm: 'Chocolat Feuillantine',
    price: 500,
    count: 0,
    fileName: 'BENE_REGALO_Chocolat_Feuillantine.webp',
    fileName2: 'BENE_REGALO_Chocolat_Feuillantine.avif',
    disabled: false,
    limit: null,
  },
  uid6: {
    name: 'Espresso Sauce',
    nameInConfirm: 'Espresso Sauce',
    price: 500,
    count: 0,
    fileName: 'BENE_REGALO_Espresso_Sauce.webp',
    fileName2: 'BENE_REGALO_Espresso_Sauce.avif',
    disabled: false,
    limit: null,
  },
  uid8: {
    name: 'Tiramisu Macaron 4個Set',
    nameInConfirm: 'Tiramisu Macaron',
    price: 2000,
    count: 0,
    fileName: 'macaronInBox.webp',
    fileName1: 'macaronInBox.webp',
    fileName2: 'macaronInBox.webp',
    fileName3: 'macaron1.webp',
    fileName4: 'macaron1.webp',
    fileName5: 'macaron2.webp',
    fileName6: 'macaron2.webp',
    disabled: false,
    limit: null,
    slide: 2,
  },
  uid9: {
    name: 'Tiramisu Lovers Set',
    nameInConfirm: 'Lovers Set',
    price: 9000,
    count: 0,
    fileName: 'loversSet.webp',
    fileName2: 'loversSet.webp',
    disabled: false,
    limit: null,
    slide: 1,
  },
  uid10: {
    name: 'Tiramisu Super Lovers Set',
    nameInConfirm: 'Super Lovers Set',
    price: 12000,
    count: 0,
    fileName: 'tiramisu_super_lovers_set.webp',
    fileName2: 'tiramisu_super_lovers_set.webp',
    disabled: false,
    limit: null,
    slide: 1,
  },
  uid11: {
    name: 'Tiramisu Gelato 2×3 Set',
    nameInConfirm: 'Tiramisu Gelato ×6',
    price: 3000,
    count: 0,
    fileName: 'tiramisu_gelato_6.webp',
    fileName2: 'tiramisu_gelato_6.webp',
    disabled: false,
    limit: null,
    slide: 1,
  },
  uid12: {
    name: 'Tiramisu Gelato 2×6 Set',
    nameInConfirm: 'Tiramisu Gelato ×12',
    price: 5500,
    count: 0,
    fileName: 'tiramisu_gelato_12.webp',
    fileName2: 'tiramisu_gelato_12.webp',
    disabled: false,
    limit: null,
    slide: 1,
  },
  // uid13: {
  //   name: 'Campaign Set 1',
  //   nameInConfirm: 'Campaign Set 1',
  //   price: 8000,
  //   count: 0,
  //   fileName: 'tiramisu_gelato_set1.webp',
  //   fileName2: 'tiramisu_gelato_set1.webp',
  //   disabled: false,
  //   limit: null,
  //   slide: 1,
  // },
  // uid14: {
  //   name: 'Campaign Set 2',
  //   nameInConfirm: 'Campaign Set 2',
  //   price: 5500,
  //   count: 0,
  //   fileName: 'tiramisu_gelato_set2.webp',
  //   fileName2: 'tiramisu_gelato_set2.webp',
  //   disabled: false,
  //   limit: null,
  //   slide: 1,
  // },
  // uid15: {
  //   name: 'Campaign Set 3',
  //   nameInConfirm: 'Campaign Set 3',
  //   price: 5000,
  //   count: 0,
  //   fileName: 'tiramisu_gelato_set3.webp',
  //   fileName2: 'tiramisu_gelato_set3.webp',
  //   disabled: false,
  //   limit: null,
  //   slide: 1,
  // },
  // MEMO: 白黒復活のため残す
  // uid16: {
  //   name: 'Tiramisu Bianco Set in Box',
  //   nameInConfirm: 'Tiramisu Bianco Set',
  //   price: 6500,
  //   count: 0,
  //   fileName: 'tiramisu_bianco_set_in_box.webp',
  //   fileName2: 'tiramisu_bianco_set_in_box.avif',
  //   disabled: false,
  //   limit: null,
  //   slide: 1,
  // },
  // uid17: {
  //   name: 'Tiramisu Nero Set in Box',
  //   nameInConfirm: 'Tiramisu Nero Set',
  //   price: 6500,
  //   count: 0,
  //   fileName: 'tiramisu_nero_set_in_box.webp',
  //   fileName2: 'tiramisu_nero_set_in_box.avif',
  //   disabled: false,
  //   limit: null,
  //   slide: 1,
  //   theme: 'Nero',
  // },
  // uid18: {
  //   name: '抹茶てぃらみすぷりん4個セット',
  //   nameInConfirm: 'Matcha Tiramisu ×4',
  //   price: 3000,
  //   count: 0,
  //   fileName: 'matcha_tiramisu_pudding_4.webp',
  //   fileName2: 'matcha_tiramisu_pudding_4.avif',
  //   disabled: false,
  //   limit: null,
  //   slide: 1,
  //   theme: 'Matcha',
  // },
  // uid19: {
  //   name: '抹茶てぃらぷりセット①',
  //   nameInConfirm: 'Matcha Set 1',
  //   price: 8200,
  //   count: 0,
  //   fileName: 'matcha_tiramisu_set1.webp',
  //   fileName2: 'matcha_tiramisu_set1.avif',
  //   disabled: false,
  //   limit: null,
  //   slide: 1,
  //   theme: 'Matcha',
  // },
  // uid20: {
  //   name: '抹茶てぃらぷりセット②',
  //   nameInConfirm: 'Matcha Set 2',
  //   price: 5700,
  //   count: 0,
  //   fileName: 'matcha_tiramisu_set2.webp',
  //   fileName2: 'matcha_tiramisu_set2.avif',
  //   disabled: false,
  //   limit: null,
  //   slide: 1,
  //   theme: 'Matcha',
  // },
  // uid21: {
  //   name: '抹茶てぃらぷりセット③',
  //   nameInConfirm: 'Matcha Set 3',
  //   price: 5200,
  //   count: 0,
  //   fileName: 'matcha_tiramisu_set3.webp',
  //   fileName2: 'matcha_tiramisu_set3.avif',
  //   disabled: false,
  //   limit: null,
  //   slide: 1,
  //   theme: 'Matcha',
  // },
  // uid22: {
  //   name: 'ティラミス尽くしセット',
  //   nameInConfirm: 'All Tiramisu Set',
  //   price: 12200,
  //   count: 0,
  //   fileName: 'all_tiramisu_set.webp',
  //   fileName2: 'all_tiramisu_set.avif',
  //   disabled: false,
  //   limit: null,
  //   slide: 1,
  //   theme: 'Matcha',
  // },
  // uid23: {
  //   name: 'ティラミス尽くしフルセット',
  //   nameInConfirm: 'Full Tiramisu Set',
  //   price: 15000,
  //   count: 0,
  //   fileName: 'all_tiramisu_full_set.webp',
  //   fileName2: 'all_tiramisu_full_set.avif',
  //   disabled: false,
  //   limit: null,
  //   slide: 1,
  //   theme: 'Matcha',
  // },
  // MEMO: 白黒復活のため残す
  // uid24: {
  //   name: 'Bianco/Nero Tiramisu Set',
  //   nameInConfirm: 'Bianco/Nero Set',
  //   price: 12000,
  //   count: 0,
  //   fileName: 'tiramisu_bianco_nero_set.webp',
  //   fileName2: 'tiramisu_bianco_nero_set.avif',
  //   disabled: false,
  //   limit: null,
  //   slide: 1,
  //   theme: 'Split',
  // },
  // uid25: {
  //   name: 'ティラミスまん5個 Bag入り',
  //   nameInConfirm: 'Tiramisu Bun x5',
  //   price: 2500,
  //   count: 0,
  //   fileName: 'bun_002.webp',
  //   fileName2: 'bun_002.avif',
  //   disabled: false,
  //   limit: null,
  //   slide: 1,
  // },
  // uid26: {
  //   name: 'ティラミス尽くしまんセット',
  //   nameInConfirm: 'All Tiramisu Bun Set',
  //   price: 12000,
  //   count: 0,
  //   fileName: 'all_tiramisu_bun_set.webp',
  //   fileName2: 'all_tiramisu_bun_set.avif',
  //   disabled: false,
  //   limit: null,
  //   slide: 1,
  // },
  // uid27: {
  //   name: 'ティラミス尽くしフルまんセット',
  //   nameInConfirm: 'Full Tiramisu Bun Set',
  //   price: 15000,
  //   count: 0,
  //   fileName: 'all_tiramisu_bun_full_set.webp',
  //   fileName2: 'all_tiramisu_bun_full_set.avif',
  //   disabled: false,
  //   limit: null,
  //   slide: 1,
  // },
  // uid28: {
  //   name: 'ティラミスまんセット①',
  //   nameInConfirm: 'Tiramisu Bun Set 1',
  //   price: 8000,
  //   count: 0,
  //   fileName: 'tiramisu_bun_set1.webp',
  //   fileName2: 'tiramisu_bun_set1.avif',
  //   disabled: false,
  //   limit: null,
  //   slide: 1,
  // },
  // uid29: {
  //   name: 'ティラミスまんセット②',
  //   nameInConfirm: 'Tiramisu Bun Set 2',
  //   price: 5000,
  //   count: 0,
  //   fileName: 'tiramisu_bun_set2.webp',
  //   fileName2: 'tiramisu_bun_set2.avif',
  //   disabled: false,
  //   limit: null,
  //   slide: 1,
  // },
  // uid30: {
  //   name: 'ティラミスまんセット③',
  //   nameInConfirm: 'Tiramisu Bun Set 3',
  //   price: 4500,
  //   count: 0,
  //   fileName: 'tiramisu_bun_set3.webp',
  //   fileName2: 'tiramisu_bun_set3.avif',
  //   disabled: false,
  //   limit: null,
  //   slide: 1,
  // },
  uid31: {
    name: '生チョコティラミス16個',
    nameInConfirm: 'Namachoco Tiramisu x16',
    price: 2000,
    count: 0,
    fileName: 'namachoco_002.webp',
    fileName2: 'namachoco_002.avif',
    disabled: false,
    limit: null,
    slide: 1,
  },
  uid32: {
    name: 'キャンペーンセット①',
    nameInConfirm: 'Campaign Set 1',
    price: 7000,
    count: 0,
    fileName: 'white_day_set1.webp',
    fileName2: 'white_day_set1.avif',
    disabled: false,
    limit: null,
    slide: 1,
  },
  uid33: {
    name: 'キャンペーンセット②',
    nameInConfirm: 'Campaign Set 2',
    price: 4500,
    count: 0,
    fileName: 'white_day_set2.webp',
    fileName2: 'white_day_set2.avif',
    disabled: false,
    limit: null,
    slide: 1,
  },
  uid34: {
    name: 'キャンペーンセット③',
    nameInConfirm: 'Campaign Set 3',
    price: 4000,
    count: 0,
    fileName: 'white_day_set3.webp',
    fileName2: 'white_day_set3.avif',
    disabled: false,
    limit: null,
    slide: 1,
  },
  uid35: {
    name: 'キャンペーンフルセット',
    nameInConfirm: 'Campaign Full Set',
    price: 11500,
    count: 0,
    fileName: 'white_day_full_set.webp',
    fileName2: 'white_day_full_set.avif',
    disabled: false,
    limit: null,
    slide: 1,
  },
  uid36: {
    name: 'ネギトロティラミス5貫',
    nameInConfirm: 'Negitoro Tiramisu ×5',
    price: 2000,
    count: 0,
    fileName: 'negitoro.webp',
    fileName2: 'negitoro.avif',
    disabled: false,
    limit: null,
    slide: 1,
  },
  uid37: {
    name: '【追加のみ】限定コースター3種',
    nameInConfirm: 'coaster ×3 set',
    price: 600,
    count: 0,
    fileName: 'coaster.webp',
    fileName2: 'coaster.avif',
    disabled: false,
    limit: null,
    slide: 1,
  },
};
