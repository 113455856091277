import { useCallback, createRef, useState, useLayoutEffect, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { KamaitachiHeader } from '../component/index';
import { ItemPanel, SideMenu, Cart, ErrModal } from '../component/index';

import styled from 'styled-components';
import css from './index.module.scss';
import toast, { Toaster } from 'react-hot-toast';

// MEMO: Recoil
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  addKamaitachiTotalState,
  addKamaitachiProductState,
  addKamaitachiIsDelivery,
  lpProductState,
} from '../store/atoms';

import { termsAndPolicyState } from '../../lp/atoms';
import { getAddKamaitachiProduct, getLpProduct } from '../store';

// MEMO: image
import topImageSp1 from './img/topImageSp1.webp';
import topImageSp2 from './img/topImageSp2.webp';
import topImageSp3 from './img/topImageSp3.webp';
import topImagePc1 from './img/topImagePc1.webp';
import topImagePc2 from './img/topImagePc2.webp';
import topImagePc3 from './img/topImagePc3.webp';

import logo from './img/logo.webp';
import okiteLogo from './img/okiteLogo.webp';
import xLogo from './img/xLogo.webp';

import storeMallDirectoryIcon from './img/store-icon.svg';
import trackIcon from './img/track-Icon.svg';

// MEMO: 商品画像
import KMITCCS from './img/KMITCCS.webp';
import KMITCCS_cart from './img/KMITCCS_cart.webp';
import KMITC from './img/KMITC.webp';
import KMITC_cart from './img/KMITC_cart.webp';
import YMUC from './img/YMUC.webp';
import YMUCCS from './img/YMUCCS.webp';
import HMIE from './img/HMIE.webp';
import HMIECS from './img/HMIECS.webp';
import KMITCWC6S from './img/KMITCWC6S.webp';
import KMITCWC6S_cart from './img/KMITCWC6S_cart.webp';
import KMITCW6S from './img/KMITCW6S.webp';
import KMITCW6S_cart from './img/KMITCW6S_cart.webp';

const cartProductImages = {
  KMITCCS: KMITCCS_cart,
  KMITC: KMITC_cart,
  YMUC: YMUC,
  YMUCCS: YMUCCS,
  HMIE: HMIE,
  HMIECS: HMIECS,
  KMITCWC6S: KMITCWC6S_cart,
  KMITCW6S: KMITCW6S_cart,
};

// MEMO: swiper
import { Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import CountdownTimer from '../snippets/countDownTimer';
import { importAll } from '../utils/image';

const TotalPrice = styled.div`
  padding: 12px;
  display: flex;
  height: clamp(0px, calc(17.06vw-24px), 76px);
  line-height: 100%;
  letter-spacing: 0;
  background-color: #161c1c;
  font-family: 'KiwiMaru';
  position: fixed;
  bottom: clamp(64px, 17.06vw, 80px);
  right: 0px;
  z-index: 9;
  border-radius: 4px;
`;

const CartIconCon = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;
  margin-right: 16px;
  margin-left: 4px;
`;

const CountIcon = styled.div`
  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  padding-left: 4px;
  margin-bottom: -20px;
  font-size: 24px;
  @media (max-width: 900px) {
    font-size: 18px;
    padding-left: 3px;
    margin-bottom: -12px;
  }
`;

const PriceCon = styled.div`
  display: flex;
  background-color: white;
  border-radius: 4px;
  color: black;
`;

const PriceFonts = styled.div`
  margin-top: auto;
  margin-bottom: 4px;
  padding-right: 8px;
  padding-left: 8px;
`;

export default function KamaitachiTop() {
  const image = importAll(require.context('./img', false, /\.(webp|avif|svg)$/));
  const setTermsAndPolicy = useSetRecoilState(termsAndPolicyState);

  const updateTermsAndPolicy = (boolean) => {
    setTermsAndPolicy((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future['terms'] = boolean;
      return future;
    });
  };

  // MEMO: kamaitachi/kamaitachi/itemsの型定義
  //  {
  //   count: number, // 購入数
  //   description: string, // フルネーム(一部の商品で表示名が短縮されるため)
  //   imageUrl: string, // 画像名(拡張子付き)
  //   name: string, // 表示名
  //   price: number, // 価格
  //   productCode: string, // 商品コード
  //   reserveTotal: number, // カート情報では不使用 商品ごとの予約数（製造目標）
  //   stockTotal: number, // カート情報では不使用 製造数
  // };

  /**
   * MEMO: itemsを下記形式で取り出すように整形済みのデータ product["productCode"]
   * @type {Object.<string | number, Object>}
   */
  const [product, setProduct] = useRecoilState(addKamaitachiProductState);
  const [_lpProductList, setLpProductState] = useRecoilState(lpProductState);

  const [isDelivery, setIsDelivery] = useRecoilState(addKamaitachiIsDelivery);
  const [kamaitachiTotal, setKamaitachiTotal] = useRecoilState(addKamaitachiTotalState);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isActualPerformance, _setIsActualPerformance] = useState(false);
  const [isShopModalOpen, setIsShopModalOpen] = useState(false);
  const [total, setTotal] = useRecoilState(addKamaitachiTotalState);
  const setTotalByProp = (key, value) => {
    setTotal((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future[key] = value;
      return future;
    });
  };

  useEffect(() => {
    // MEMO: Objだと代入できなかったので一度Jsonに変換する
    const copyProductJson = Object.assign({}, JSON.parse(JSON.stringify(product)));
    // Object.keys(copyProductJson).forEach((e) => {
    //   if (Object.keys(lpProductList).includes(e)) {
    //     if (copyProductJson[e]['count'] > 0) {
    //       copyProductJson[e]['count'] = 0;
    //     }
    //   }
    // });
    // MEMO : カートの内容をすべてリセット
    Object.keys(product).forEach((e) => {
      if (copyProductJson[e]['count'] > 0) {
        copyProductJson[e]['count'] = 0;
      }
    });
    setProduct(copyProductJson);
  }, [isDelivery]);

  useEffect(() => {
    let totalCount = 0;
    let totalPrice = 0;
    Object.keys(product).forEach((e) => {
      totalCount += product[e]['count'];
      totalPrice += product[e]['count'] * product[e]['price'];
    });

    setKamaitachiTotal((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future['totalCount'] = totalCount;
      future['totalPrice'] = totalPrice;
      return future;
    });
  }, [product]);

  useLayoutEffect(() => {
    if (Object.keys(product).length === 0) {
      getAddKamaitachiProduct()
        .then((res) => {
          const items = [];
          res.forEach((doc) => {
            items.push(doc.data());
          });
          let resultItems = {};
          if (items) {
            items.map((data) => {
              resultItems[data.productCode] = data;
            });
          }
          setProduct(resultItems);
        })
        // eslint-disable-next-line no-console
        .catch((error) => console.log(error));

      getLpProduct().then((res) => {
        const items = [];
        res.forEach((doc) => {
          items.push(doc.data());
        });
        let resultItems = {};

        if (items) {
          items.map((data) => {
            setProduct((resultItems) => ({ ...resultItems, [data.productCode]: data }));
            resultItems[data.productCode] = data;
          });
          setLpProductState(resultItems);
        }
      });
    }

    // 送料をリセット
    if (total.totalPrice < 6000 && total.includeDeliveryFee === true) {
      setTotalByProp('totalPrice', total.totalPrice - 1000);
      setTotalByProp('includeDeliveryFee', false);
    }

    setTermsAndPolicy((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future['backPage'] = '/kamaitachi-no-okite';
      return future;
    });
  }, []);

  const onClickItemAddCount = (productCode, count) => {
    setProduct((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future[productCode]['count'] = count;
      return future;
    });
  };

  const column = {
    singleColumn: 'single',
    doubleColumn: 'double',
  };

  const ref = createRef();
  const scrollToBottomOfList = useCallback(() => {
    const bannerElement = document.getElementById('topItems');
    bannerElement.scrollIntoView([true | { behavior: 'smooth' }]);
  }, [ref]);

  const moveWindow = () => {
    scrollToBottomOfList();
    setIsCartOpen(false);
  };

  const [isErrModal, setIsErrModal] = useState(false);

  const updateSetIsErrModal = (boolean) => {
    setIsErrModal(boolean);
  };

  const notify = () => toast.error('かまいたち商品を必ず含めてください');
  if (Object.keys(product).length === 0) {
    return <></>;
  }

  return (
    <>
      {
        // MEMO: 予約開始まで非表示
        isActualPerformance && (
          <div className={css.homeCartSection} id="textTop">
            <img
              src={`${process.env.PUBLIC_URL}/CartLogo.svg`}
              alt=""
              className={kamaitachiTotal.totalCount > 0 ? css.displayNone : css.cartImg}
              onClick={() => {
                setIsCartOpen(true);
              }}
            />
            <div
              onClick={() => {
                setIsCartOpen(true);
              }}
              className={kamaitachiTotal.totalCount > 0 ? '' : css.displayNone}
            >
              <TotalPrice>
                <CartIconCon>
                  <img
                    className={classNames(css.vertAlM, css.cart_icon)}
                    src={`${process.env.PUBLIC_URL}/shopping_cart.svg`}
                    alt=""
                  />
                  <CountIcon>{kamaitachiTotal.totalCount}</CountIcon>
                </CartIconCon>
                <PriceCon>
                  <PriceFonts>
                    <span className={`${css.priceFont}`}>合計</span>
                    <span className={`${css.priceFont2}`}>¥</span>
                    <span className={`${css.priceFont3} ${css.fw_b}`}>
                      {isDelivery
                        ? (kamaitachiTotal.totalPrice + (kamaitachiTotal.totalPrice < 6000 ? 1000 : 0)).toLocaleString()
                        : kamaitachiTotal.totalPrice.toLocaleString()}
                    </span>
                    <span className={`${css.priceFont}`}>(税込)</span>
                  </PriceFonts>
                </PriceCon>
              </TotalPrice>
            </div>
          </div>
        )
      }
      {isCartOpen ? (
        <Cart
          close={() => setIsCartOpen(false)}
          cartProductImages={cartProductImages}
          moveWindow={() => moveWindow()}
          isErrModal={isErrModal}
          updateSetIsErrModal={updateSetIsErrModal}
          notify={notify}
        />
      ) : null}
      {isErrModal ? <ErrModal updateSetIsErrModal={updateSetIsErrModal} close={() => setIsCartOpen(false)} /> : null}
      <Toaster position="top-right" reverseOrder={false} />
      <SideMenu>
        <li>
          <Link to={'/policy'}>
            <p
              onClick={() => {
                updateTermsAndPolicy(true);
              }}
            >
              利用規約
            </p>
          </Link>
        </li>
        <li>
          <Link to={'/policy'}>
            <p
              onClick={() => {
                updateTermsAndPolicy(false);
              }}
            >
              プライバシーポリシー&nbsp;&nbsp;&nbsp;
            </p>
          </Link>
        </li>
        <li>
          <Link to={'/transaction-act'}>
            <p>特定商取引法に基づく表記</p>
          </Link>
        </li>
        <li>
          <Link to={'/kamaitachi-no-okite/contact'}>
            <p>よくある質問／お問い合わせ</p>
          </Link>
        </li>
      </SideMenu>
      <div className={css.kamaitachiBodyBase}>
        <KamaitachiHeader />
        <section className={css.topSection}>
          <div className={css.imageBox}>
            <Swiper
              loop={true}
              autoplay={{ delay: 5000, disableOnInteraction: false }}
              speed={3000}
              slidesPerView={1}
              centeredSlides={true}
              className={css.mediaSlide}
              modules={[Pagination, Autoplay]}
              spaceBetween={24}
            >
              <SwiperSlide>
                <img
                  src={image['add_top_image_sp1.webp']}
                  className={classNames(css.topImage, css.pcInvisible)}
                  alt="topImage"
                />
                <img
                  src={image['add_top_image_pc1.webp']}
                  className={classNames(css.topImage, css.spInvisible)}
                  alt="topImage"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img src={topImageSp2} className={classNames(css.topImage, css.pcInvisible)} alt="topImage" />
                <img src={topImagePc2} className={classNames(css.topImage, css.spInvisible)} alt="topImage" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={topImageSp3} className={classNames(css.topImage, css.pcInvisible)} alt="topImage" />
                <img src={topImagePc3} className={classNames(css.topImage, css.spInvisible)} alt="topImage" />
              </SwiperSlide>
            </Swiper>
          </div>
          {isActualPerformance && (
            // MEMO: 予約開始まで非表示
            <div className={css.textBox}>
              <p className={css.title}>事前予約受付日時</p>
              <p className={css.description}>2024年6月9日〜2024年6月16日</p>
              <p className={css.attention}>※7月以降順次商品受取予定</p>
            </div>
          )}
        </section>

        {isActualPerformance && (
          // MEMO: 予約開始まで非表示
          <>
            <section className={css.isDeliverySection}>
              <button
                className={classNames(!isDelivery ? css.adaptation : css.maladaptive)}
                onClick={() => setIsDelivery(false)}
              >
                <img src={storeMallDirectoryIcon} className={css.icon} alt="logo" />
                店頭受取
              </button>
              <button
                className={classNames(isDelivery ? css.adaptation : css.maladaptive)}
                onClick={() => setIsDelivery(true)}
              >
                <img src={trackIcon} className={css.icon} alt="logo" />
                配送
              </button>
            </section>

            {isDelivery ? (
              // MEMO: 配送の場合
              <section className={css.itemSection} id="topItems">
                <div className={css.title}>
                  <span className={css.marker}>￥6,000円以上で送料無料</span>
                </div>
                <p className={css.title}>商品一覧</p>
                <div className={css.itemBoxSingle}>
                  <ItemPanel
                    imageData={KMITCWC6S}
                    name={product['KMITCWC6S']['name']}
                    price={product['KMITCWC6S']['price']}
                    productCode={product['KMITCWC6S']['productCode']}
                    count={product['KMITCWC6S']['count']}
                    onClickItemAddCount={(productCode, count) => {
                      onClickItemAddCount(productCode, count);
                      setIsCartOpen(true);
                    }}
                    column={column.singleColumn}
                  />
                </div>
                <div className={css.itemBoxSingle}>
                  <ItemPanel
                    imageData={KMITCW6S}
                    name={product['KMITCW6S']['name']}
                    price={product['KMITCW6S']['price']}
                    productCode={product['KMITCW6S']['productCode']}
                    count={product['KMITCW6S']['count']}
                    onClickItemAddCount={(productCode, count) => {
                      onClickItemAddCount(productCode, count);
                      setIsCartOpen(true);
                    }}
                    column={column.singleColumn}
                  />
                </div>
              </section>
            ) : (
              // MEMO: 店頭受取の場合
              <section className={css.itemSection} id="topItems">
                <p className={css.shopInfo} onClick={() => setIsShopModalOpen(true)}>
                  <img src={image['shopIcon.svg']} />
                  <div>店舗情報</div>
                </p>
                {isShopModalOpen && (
                  <div className={css.shopInfoModal}>
                    <div className={css.shopTitleFlex}>
                      <div className={css.shopTitle} onClick={() => setIsShopModalOpen(false)}>
                        ×
                      </div>
                      <div className={css.shopTitle}>店舗情報</div>
                      <div></div>
                    </div>
                    <div className={css.shop}>
                      <div className={css.shopTitle}>秋川本店</div>
                      <br />
                      〒197-0804
                      <br />
                      東京都あきる野市秋川1-8 あきる野ルピア1階
                      <br />
                      TEL: 0428-08-7933
                      <br />
                      <br />
                      営業日：水曜定休日
                      <br />
                      営業時間：11:00〜17:00{' '}
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3238.8474988251223!2d139.28206448273548!3d35.72996890184922!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601921c7df270435%3A0xbd02a2f7b8587fcd!2z44OG44Kj44Op44Of44K55bCC6ZaA5bqXIEJFTkUgUkVHQUxPIOeni-W3neacrOW6lw!5e0!3m2!1sja!2sjp!4v1683359823050!5m2!1sja!2sjp"
                        allowfullscreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        className={css.shopMap}
                      ></iframe>
                    </div>
                    <div className={css.shop}>
                      <div className={css.shopTitle}>代々木店</div>
                      <br />
                      〒151-0053
                      <br />
                      東京都渋谷区代々木1-16-1
                      <br />
                      TEL: 03-6300-9465
                      <br />
                      <br />
                      営業日：定休日なし
                      <br />
                      営業時間：11:00〜19:00
                      <br />
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.834521216736!2d139.6977165755477!3d35.6810763725873!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d67eda10887%3A0x38f4b875f089a412!2z44OG44Kj44Op44Of44K55bCC6ZaA5bqXIEJFTkUgUkVHQUxPIOS7o-OAheacqOW6lw!5e0!3m2!1sja!2sjp!4v1683357367280!5m2!1sja!2sjp"
                        allowfullscreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        className={css.shopMap}
                      ></iframe>
                    </div>
                  </div>
                )}

                <p className={css.title}>商品一覧</p>
                <div className={css.itemBoxSingle}>
                  <ItemPanel
                    imageData={KMITCCS}
                    name={product['KMITCCS']['name']}
                    price={product['KMITCCS']['price']}
                    productCode={product['KMITCCS']['productCode']}
                    count={product['KMITCCS']['count']}
                    onClickItemAddCount={(productCode, count) => {
                      onClickItemAddCount(productCode, count);
                      setIsCartOpen(true);
                    }}
                    column={column.singleColumn}
                  />
                </div>

                <div className={css.itemBoxDouble}>
                  <ItemPanel
                    imageData={YMUCCS}
                    name={product['YMUCCS']['name']}
                    price={product['YMUCCS']['price']}
                    productCode={product['YMUCCS']['productCode']}
                    count={product['YMUCCS']['count']}
                    onClickItemAddCount={(productCode, count) => {
                      onClickItemAddCount(productCode, count);
                      setIsCartOpen(true);
                    }}
                    column={column.doubleColumn}
                  />
                  <ItemPanel
                    imageData={HMIECS}
                    name={product['HMIECS']['name']}
                    price={product['HMIECS']['price']}
                    productCode={product['HMIECS']['productCode']}
                    count={product['HMIECS']['count']}
                    onClickItemAddCount={(productCode, count) => {
                      onClickItemAddCount(productCode, count);
                      setIsCartOpen(true);
                    }}
                    column={column.doubleColumn}
                  />
                </div>
                <div className={css.itemBoxSingle}>
                  <ItemPanel
                    imageData={KMITC}
                    name={product['KMITC']['name']}
                    price={product['KMITC']['price']}
                    productCode={product['KMITC']['productCode']}
                    count={product['KMITC']['count']}
                    onClickItemAddCount={(productCode, count) => {
                      onClickItemAddCount(productCode, count);
                      setIsCartOpen(true);
                    }}
                    column={column.singleColumn}
                  />
                </div>
                <div className={css.itemBoxDouble}>
                  <ItemPanel
                    imageData={YMUC}
                    name={product['YMUC']['name']}
                    price={product['YMUC']['price']}
                    productCode={product['YMUC']['productCode']}
                    count={product['YMUC']['count']}
                    onClickItemAddCount={(productCode, count) => {
                      onClickItemAddCount(productCode, count);
                      setIsCartOpen(true);
                    }}
                    column={column.doubleColumn}
                  />
                  <ItemPanel
                    imageData={HMIE}
                    name={product['HMIE']['name']}
                    price={product['HMIE']['price']}
                    productCode={product['HMIE']['productCode']}
                    count={product['HMIE']['count']}
                    onClickItemAddCount={(productCode, count) => {
                      onClickItemAddCount(productCode, count);
                      setIsCartOpen(true);
                    }}
                    column={column.doubleColumn}
                  />
                </div>
              </section>
            )}

            <section className={css.copyRightSection}>
              <div className={css.textBox}>
                <p>
                  <span className={css.eng}>©TSK／</span>かまいたちの掟
                </p>
                <p className={css.eng}>©Eight Good／BENE REGALO</p>
              </div>
            </section>
            <section className={css.logoSection}>
              <a href="https://bene-regalo.com" target="blank">
                <img src={logo} className={css.logo} alt="logo" />
              </a>
              <a href="https://tsk-tv.com/sp/okite/" target="blank">
                <img src={okiteLogo} className={css.okiteLogo} alt="okiteLogo" />
              </a>
              <a href="https://twitter.com/bene_regalo" target="blank">
                <img src={xLogo} className={css.logo} alt="xLogo" />
              </a>
            </section>
            <footer className={css.footer}>
              <div className={css.textBox}>
                <Link to={'/policy'}>
                  <p
                    onClick={() => {
                      updateTermsAndPolicy(true);
                    }}
                  >
                    利用規約
                  </p>
                </Link>
                <Link to={'/kamaitachi-no-okite/contact'}>
                  {' '}
                  <p>よくある質問／お問い合わせ</p>
                </Link>
                <Link to={'/policy'}>
                  <p
                    onClick={() => {
                      updateTermsAndPolicy(false);
                    }}
                  >
                    プライバシーポリシー&nbsp;&nbsp;&nbsp;
                  </p>
                </Link>
                <Link to={'/transaction-act'}>
                  <p>特定商取引法に基づく表記</p>
                </Link>
              </div>

              <div className={css.copyright}>
                <p className={css.eng}>Copyright ©Eight Good Co., Ltd. All Rights Reserved</p>
              </div>
            </footer>
          </>
        )}
      </div>
      {!isActualPerformance && (
        <div className={classNames(css.countDownWrap)}>
          <div className={classNames(css.countDownText)}>予約期間は終了しました。</div>
          {/* <CountdownTimer targetDate="2024-06-09T12:00:00+09:00" /> */}
        </div>
      )}
    </>
  );
}
