import { useState } from 'react';
import css from '../../index.module.scss';
import classNames from 'classnames';

export function Complement(props) {
  // title="お名前, ふりがな, メールアドレス, 電話番号, 郵便番号, 番地, 建物名/部屋番号, 会社名/部門"

  // TODO: 助長なコードがあるので暇な時にリファクタする
  const [isText, setIsText] = useState({
    name: false,
    hurigana: false,
    mail: false,
    tell: false,
    postalCode: false,
    address1: false,
    address2: false,
    address3: false,
  });

  const title = props?.props?.title;
  const text =
    props?.props?.title === 'お名前'
      ? '姓名あわせて全角16文字まで'
      : props?.props?.title === 'ふりがな'
      ? 'せいめいあわせて全角25文字まで'
      : props?.props?.title === 'メールアドレス'
      ? '半角英数字のみ'
      : props?.props?.title === '電話番号'
      ? '半角数字のみ11桁まで'
      : props?.props?.title === '郵便番号'
      ? '半角数字のみ7桁'
      : props?.props?.title === '番地'
      ? '全角16文字（半角32文字）まで'
      : props?.props?.title === '建物名/部屋番号'
      ? '全角16文字まで'
      : props?.props?.title === '会社名/部門'
      ? '全角25文字まで'
      : 'a';

  function imgClick(title) {
    if (title === 'お名前') {
      setIsText({ ...isText, name: true });
    } else if (title === 'ふりがな') {
      setIsText({ ...isText, hurigana: true });
    } else if (title === 'メールアドレス') {
      setIsText({ ...isText, mail: true });
    } else if (title === '電話番号') {
      setIsText({ ...isText, tell: true });
    } else if (title === '郵便番号') {
      setIsText({ ...isText, postalCode: true });
    } else if (title === '番地') {
      setIsText({ ...isText, address1: true });
    } else if (title === '建物名/部屋番号') {
      setIsText({ ...isText, address2: true });
    } else if (title === '会社名/部門') {
      setIsText({ ...isText, address3: true });
    }
  }

  function textClick(title) {
    if (title === 'お名前') {
      setIsText({ ...isText, name: false });
    } else if (title === 'ふりがな') {
      setIsText({ ...isText, hurigana: false });
    } else if (title === 'メールアドレス') {
      setIsText({ ...isText, mail: false });
    } else if (title === '電話番号') {
      setIsText({ ...isText, tell: false });
    } else if (title === '郵便番号') {
      setIsText({ ...isText, postalCode: false });
    } else if (title === '番地') {
      setIsText({ ...isText, address1: false });
    } else if (title === '建物名/部屋番号') {
      setIsText({ ...isText, address2: false });
    } else if (title === '会社名/部門') {
      setIsText({ ...isText, address3: false });
    }
  }

  return (
    <div className={classNames(css.helpMark)}>
      {(() => {
        if (title === 'お名前') {
          if (isText.name) {
            return (
              <div className={css.textBox1}>
                <p onClick={() => textClick(title)}>{text}</p>
              </div>
            );
          } else if (!isText.name) {
            return (
              <img
                onClick={() => imgClick(title)}
                src={`${process.env.PUBLIC_URL}/InputAccountInfo/helpMark.png`}
                alt=""
                className={css.textBox1Img}
              />
            );
          }
        }
        if (title === 'ふりがな') {
          if (isText.hurigana) {
            return (
              <div className={css.textBox2}>
                <p onClick={() => textClick(title)}>{text}</p>
              </div>
            );
          } else if (!isText.hurigana) {
            return (
              <img
                onClick={() => imgClick(title)}
                src={`${process.env.PUBLIC_URL}/InputAccountInfo/helpMark.png`}
                alt=""
                className={css.textBox1Img}
              />
            );
          }
        }

        if (title === 'メールアドレス') {
          if (isText.mail) {
            return (
              <div className={css.textBox2}>
                <p onClick={() => textClick(title)}>{text}</p>
              </div>
            );
          } else if (!isText.mail) {
            return (
              <img
                onClick={() => imgClick(title)}
                src={`${process.env.PUBLIC_URL}/InputAccountInfo/helpMark.png`}
                alt=""
                className={css.textBox1Img}
              />
            );
          }
        }

        if (title === '電話番号') {
          if (isText.tell) {
            return (
              <div className={css.textBox2}>
                <p onClick={() => textClick(title)}>{text}</p>
              </div>
            );
          } else if (!isText.tell) {
            return (
              <img
                onClick={() => imgClick(title)}
                src={`${process.env.PUBLIC_URL}/InputAccountInfo/helpMark.png`}
                alt=""
                className={css.textBox1Img}
              />
            );
          }
        }

        if (title === '郵便番号') {
          if (isText.postalCode) {
            return (
              <div className={css.textBox2}>
                <p onClick={() => textClick(title)}>{text}</p>
              </div>
            );
          } else if (!isText.postalCode) {
            return (
              <img
                onClick={() => imgClick(title)}
                src={`${process.env.PUBLIC_URL}/InputAccountInfo/helpMark.png`}
                alt=""
                className={css.textBox1Img}
              />
            );
          }
        }

        if (title === '番地') {
          if (isText.address1) {
            return (
              <div className={css.textBox2}>
                <p onClick={() => textClick(title)}>{text}</p>
              </div>
            );
          } else if (!isText.address1) {
            return (
              <img
                onClick={() => imgClick(title)}
                src={`${process.env.PUBLIC_URL}/InputAccountInfo/helpMark.png`}
                alt=""
                className={css.textBox1Img}
              />
            );
          }
        }
        if (title === '建物名/部屋番号') {
          if (isText.address2) {
            return (
              <div className={css.textBox3}>
                <p onClick={() => textClick(title)}>{text}</p>
              </div>
            );
          } else if (!isText.address2) {
            return (
              <img
                onClick={() => imgClick(title)}
                src={`${process.env.PUBLIC_URL}/InputAccountInfo/helpMark.png`}
                alt=""
                className={css.textBox2Img}
              />
            );
          }
        }
        if (title === '会社名/部門') {
          if (isText.address3) {
            return (
              <div className={css.textBox3}>
                <p onClick={() => textClick(title)}>{text}</p>
              </div>
            );
          } else if (!isText.address3) {
            return (
              <img
                onClick={() => imgClick(title)}
                src={`${process.env.PUBLIC_URL}/InputAccountInfo/helpMark.png`}
                alt=""
                className={css.textBox2Img}
              />
            );
          }
        }
      })()}
    </div>
  );
}
