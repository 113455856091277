import { Wrapper } from './scss';

/**
 * ローディングアニメーション
 */
export const Loader = ({ color, size }) => {
  return (
    <Wrapper variable={{ color: color }} size={size}>
      <div className="loader"></div>
    </Wrapper>
  );
};
