import { useEffect, useState } from 'react';
import css from '../index.module.scss';
import classNames from 'classnames';
import { Responsive } from './common';

export const ImageSection = (props) => {
  const [boxText, setBoxText] = useState();

  useEffect(() => {
    setBoxText(
      [...props.section.boxText].map((text, key) => {
        return <p key={key}>{text}</p>;
      })
    );
  }, [props.theme]);

  return (
    <div className={css.ImageSection}>
      <img
        src={`${process.env.PUBLIC_URL}/top/${props.section.img}.webp`}
        alt=""
        className={classNames(css.centerImg, css['centerImg' + props.orientation])}
      />
      <div className={classNames(css.description, css.fontRome, css['description' + props.orientation])}>
        {props.section.description}
      </div>
      <div
        className={classNames(
          css.englishText,
          css['englishText' + props.orientation],
          css['englishText' + props.theme.name]
        )}
      >
        <div>{props.section.englishText.line1}</div>
        <Responsive styleProps={{ 'margin-top': props.section.englishText.lineMargin }}>
          {props.section.englishText.line2}
        </Responsive>
      </div>
      <div className={classNames(css.boxText, css['boxText' + props.orientation], css['boxText' + props.theme.name])}>
        {boxText}
      </div>
    </div>
  );
};
