import css from '../index.module.scss';

export function Maintenance() {
  return (
    <div className={css.Maintenance}>
      現在メンテナンス中につき、サイトがご利用いただけません。
      <br />
      ご不便をおかけして誠に申し訳ございません。
      <br />
      {/* メンテナンスの修了時刻は、本日23:00頃を予定しております。<br/> */}
      <p>BENE REGALO</p>
      ●販売事業者の連絡先
      <br />
      ○電話番号
      <br />
      042-808-7933
      <br />
      受付時間：平日10:00〜17:00
      <br />
      <br />
      ○メールアドレス
      <br />
      support@bene-regalo.com
    </div>
  );
}
