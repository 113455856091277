import { useStyledDiv, StyledDiv, useStyledImg } from '../../utils/styled';
import { importAll } from '../../utils/image';

const [Wrapper, StepFlex, TitleWrap, Title] = useStyledDiv(4);
const [ProgressBar, Logo] = useStyledImg(2);

export const FormHeader = ({ step, title }) => {
  const image = importAll(require.context('./image', false, /\.(webp|svg)$/));

  return (
    <Wrapper
      css={`
        margin: 0 auto !important;
      `}
      responsive={{
        width: [340, 512, 720],
      }}
    >
      <ProgressBar
        src={image[`progress${step}.svg`]}
        css={`
          display: block;
        `}
        responsive={{
          width: [300, 480, 512],
          marginTop: [24, 28, 32],
          marginLeft: [20, 16, 102],
          marginRight: [20, 16, 102],
        }}
      />
      <StepFlex
        css={`
          position: relative;
          justify-content: space-between;
        `}
        responsive={{
          width: [300, 480, 512],
          fontSize: [12, 14, 16],
          lineHeight: [24, 28, 32],
          Height: [24, 28, 32],
          marginLeft: [20, 16, 102],
          marginRight: [20, 16, 102],
        }}
      >
        <StyledDiv
          css={`
            position: absolute;
            color: ${step === 1 ? '#161C1C' : '#c4c4c4'};
            transform: translateX(-50%);
          `}
        >
          お客様情報
        </StyledDiv>
        <StyledDiv
          css={`
            position: absolute;
            color: ${step === 2 ? '#161C1C' : '#c4c4c4'};
            left: 33.3%;
            transform: translateX(-50%);
          `}
        >
          決済
        </StyledDiv>
        <StyledDiv
          css={`
            position: absolute;
            color: ${step === 3 ? '#161C1C' : '#c4c4c4'};
            left: 66.6%;
            transform: translateX(-50%);
          `}
        >
          確認
        </StyledDiv>
        <StyledDiv
          css={`
            position: absolute;
            color: ${step === 4 ? '#161C1C' : '#c4c4c4'};
            right: 0;
            transform: translateX(50%);
          `}
        >
          完了
        </StyledDiv>
      </StepFlex>
      <TitleWrap
        css={`
          display: flex;
        `}
        responsive={{
          marginTop: [16, 18, 20],
          marginBottom: [16, 18, 20],
        }}
      >
        <Logo
          src={image[`bene_regalo_logo.webp`]}
          css={`
            display: block;
          `}
          responsive={{
            width: [32, 36, 40],
          }}
        />
        <Title
          responsive={{
            marginLeft: [16, 20, 24],
            fontSize: [24, 28, 32],
            lineHeight: [32, 36, 40],
          }}
        >
          {title}
        </Title>
      </TitleWrap>
    </Wrapper>
  );
};
