import classNames from 'classnames';
import css from '../../index.module.scss';
import { useSetRecoilState } from 'recoil';
import { testPurchaseState } from '../../lp/atoms';
import { useNavigate } from 'react-router-dom';

export const Application = (props) => {
  const navigate = useNavigate();

  const setTestPurchase = useSetRecoilState(testPurchaseState);
  const TestPurchase = () => {
    setTestPurchase((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future['admin'] = true;
      return future;
    });
    navigate('/');
  };

  return (
    <div className={classNames(props.tab === 'application' ? '' : css.displayNone)}>
      アプリ管理
      <br />
      <div
        onClick={() => {
          TestPurchase();
        }}
      >
        テスト購入を行う
      </div>
    </div>
  );
};
