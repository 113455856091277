// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBIcp0Ggkkiycsetzheo63jhgzllGMRvjQ',
  authDomain: 'bene-reglo-lp.firebaseapp.com',
  projectId: 'bene-reglo-lp',
  storageBucket: 'bene-reglo-lp.appspot.com',
  messagingSenderId: '500090694268',
  appId: '1:500090694268:web:1d4cbbb4a16e1a4cd377f9',
  measurementId: 'G-S5J5HM4S8Y',
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);

export const db = getFirestore();
export const storage = getStorage();
export const auth = getAuth();
export const functions = getFunctions(firebase, 'asia-northeast1');

export default firebase;
