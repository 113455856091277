import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { useStripe } from '@stripe/react-stripe-js';

import { collection, doc, setDoc, updateDoc } from 'firebase/firestore';
import { db, functions } from '../../../zz_general/utils/configs/firebase';

import { importAll } from '../../utils/image';
import css from './index.module.scss';

import { CustomerInfo_takeout, OrderDetails } from '../../component';
import { LinkButton } from '../../components/linkButton';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
  addKamaitachiProductState,
  addKamaitachiTotalState,
  addKamaitachiPaymentState,
  addKamaitachiCustomerState,
  addKamaitachiReservationIdState,
  addKamaitachiTakeoutState,
} from '../../store/atoms';
import { termsAndPolicyState } from '../../../lp/atoms';
import { httpsCallable } from 'firebase/functions';
import { FormHeader } from '../../components/fourStepBar';

export const KamaitachiConfirmTakeout = () => {
  const navigate = useNavigate();
  const image = importAll(require.context('./image', false, /\.(webp|svg)$/));
  const inputFormWidth = [340, 512, 720];
  const linkButtonHeight = [48, 52, 60];
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);

  const stripe = useStripe();

  const product = useRecoilValue(addKamaitachiProductState);
  const total = useRecoilValue(addKamaitachiTotalState);
  const [payment, setPayment] = useRecoilState(addKamaitachiPaymentState);
  const customer = useRecoilValue(addKamaitachiCustomerState);
  const takeout = useRecoilValue(addKamaitachiTakeoutState);
  const setTermsAndPolicy = useSetRecoilState(termsAndPolicyState);
  const reservationId = useRecoilValue(addKamaitachiReservationIdState);

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'Asia/Tokyo',
  };

  // MEMO: Firebaseに送信するデータ
  const reservationData = {
    reservationsId: reservationId,
    // MEMO: 受け渡しステータス（no:未受け渡し、yes:受け渡し済み、cancel:キャンセル）
    deliveryStatus: 'no',
    // 顧客情報
    fName: customer.firstName,
    lName: customer.lastName,
    fNameKana: customer.firstNameKana,
    lNameKana: customer.lastNameKana,
    emailAddress: customer.mailAddress,
    phoneNumber: customer.phoneNum,
    // 商品情報
    items: product,
    total: total,
    // 受取情報
    howToGet: {
      flag: 'takeout',
      receiveMethod: {
        takeout: {
          place: takeout.place,
          date: takeout.date,
          time: takeout.time,
        },
      },
    },
    // メール送信を行ったか
    mailStatus: '',
    // 決済情報
    paymentStatus: 'pending',
    paymentMethod: payment.mode,
    creditBrand: payment?.stripe?.paymentMethod?.card?.brand || '',
    creditNumber: payment?.stripe?.paymentMethod?.card?.last4 || '',
    // 予約確認URL
    url: `https://bene-regalo.com/kamaitachi-no-okite/takeout/customer-page?reservationsId=${reservationId}`,
    // 予約日時
    reserveDate: new Date().toLocaleString('ja-JP', options),
  };

  // MEMO: Firebaseに送信するデータ
  const customerData = {
    // 顧客情報
    fName: customer.firstName,
    lName: customer.lastName,
    fNameKana: customer.firstNameKana,
    lNameKana: customer.lastNameKana,
    emailAddress: customer.mailAddress,
    phoneNumber: customer.phoneNum,
    // 案内メールを受け取るか
    receiveDM: customer.receiveDM,
  };

  // MEMO: Firebaseに予約情報を送信
  const sendReservationDataToFireStore = async () => {
    const collectionRef = collection(
      db,
      'kamaitachi',
      process.env.REACT_APP_IS_PROD === 'true' ? 'add-kamaitachi' : 'add-kamaitachi-test',
      'reservations'
    );
    const docRef = doc(collectionRef, reservationId);
    await setDoc(docRef, reservationData);
    // MEMO : 送信済みフラグを立てる
    setPayment((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future['payPay']['sentData'] = true;
      return future;
    });
  };

  // MEMO: Firebaseに顧客情報を送信
  const sendCustomerDataToFireStore = async () => {
    const collectionRef = collection(
      db,
      'kamaitachi',
      process.env.REACT_APP_IS_PROD === 'true' ? 'add-kamaitachi' : 'add-kamaitachi-test',
      'customers'
    );
    const docRef = doc(collectionRef, reservationId);
    await setDoc(docRef, customerData);
  };

  /**端末がdeepLinkに対応している場合deepLink、そうでない場合はwebURLに遷移する */
  const openLink = (deepLink, webURL) => {
    // MEMO : deepLinkを試す
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = deepLink;
    document.body.appendChild(iframe);
    //MEMO : deepLinkに対応していない場合、webURLに遷移
    setTimeout(() => {
      document.body.removeChild(iframe);
      window.open(webURL, '_blank');
    }, 2000);
  };

  // MEMO : 5分後に決済ステータスを確認する関数をセットする
  const setScheduledFunction = async () => {
    const scheduledFunction = httpsCallable(
      functions,
      process.env.REACT_APP_IS_PROD === 'true' ? 'payPay-scheduledFunction' : 'payPayTest-scheduledFunction'
    );
    try {
      const res = await scheduledFunction({ reservationId: reservationId });
      // eslint-disable-next-line no-console
      console.log(res.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error setting scheduled function:', error);
    }
  };

  // MEMO: PayPayの支払いリンクへ遷移
  const confirmPayPay = async () => {
    setButtonIsLoading(true);
    if (!payment?.payPay?.sentData) {
      await sendCustomerDataToFireStore();
      setScheduledFunction();
      await sendReservationDataToFireStore().then(() => {
        // MEMO : ポップアップブロック時に挙動がおかしくなるので一旦使用しない
        // openLink(payment?.payPay?.deepLink, payment?.payPay?.paymentLink);
        window.open(payment?.payPay?.paymentLink, '_blank');
      });
    } else {
      // openLink(payment?.payPay?.deepLink, payment?.payPay?.paymentLink);
      window.open(payment?.payPay?.paymentLink, '_blank');
    }
    setTimeout(() => {
      setButtonIsLoading(false);
    }, 2000);
  };

  // MEMO: Stripeの支払いを確定する
  const confirmStripe = async () => {
    setButtonIsLoading(true);
    if (!payment?.payPay?.sentData) {
      sendReservationDataToFireStore();
      sendCustomerDataToFireStore();
    } else {
      const collectionRef = collection(
        db,
        'kamaitachi',
        process.env.REACT_APP_IS_PROD === 'true' ? 'add-kamaitachi' : 'add-kamaitachi-test',
        'reservations'
      );
      const docRef = doc(collectionRef, reservationId);
      updateDoc(docRef, {
        paymentStatus: 'done',
        isMailSent: true,
      });
    }
    await stripe
      .confirmCardPayment(payment.stripe.paymentIntent.client_secret, {
        payment_method: payment.stripe.paymentMethod.id,
      })
      .then((res) => {
        if (res.paymentIntent && res.paymentIntent.status === 'succeeded') {
          // MEMO: 成功時の処理
          // eslint-disable-next-line no-console
          console.log('stripe: success');
          navigate(`/kamaitachi-no-okite/takeout/customer-page?reservationsId=${reservationId}`);
        } else if (res.error && res.error.message) {
          // MEMO: エラー時の処理
          // eslint-disable-next-line no-console
          console.log(res.error.message);
        } else {
          window.scrollTo(0, 0);
          // MEMO: 予期しないエラー
          // eslint-disable-next-line no-console
          console.log('stripe: unexpected error');
        }
      })
      .catch((_err) => {});
  };

  const updateTermsAndPolicy = (key, boolean) => {
    setTermsAndPolicy((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future[key] = boolean;
      return future;
    });
  };

  useEffect(() => {
    console.log(customer);
    console.log(takeout);
    // MEMO : 直接アクセスされた場合のリダイレクト
    if (total.totalCount === 0) {
      navigate('/kamaitachi-no-okite');
    }
    setTermsAndPolicy((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future['backPage'] = '/kamaitachi-no-okite/takeout/confirm';
      return future;
    });
  }, []);
  return (
    <div>
      <FormHeader title="確認" step={3} />
      <div className={css.reservationsContents}>
        <OrderDetails product={product} total={total} />
        <hr className={classNames(css.lineM)} />
        <CustomerInfo_takeout customer={customer} takeout={takeout} />
        <hr className={classNames(css.lineM)} />
        <div className={css.title}>決済</div>
        {payment?.mode === 'stripe' && (
          <div className={classNames(css.cardNum)}>
            <img src={image['credit_card.svg']} className={classNames(css.cardIcon)} />
            <span className={css.text}>
              クレジットカード
              <br />
              <span className={classNames(css.cardFlex)}>
                <img
                  src={image[`${payment?.stripe?.paymentMethod?.card?.brand}.webp`]}
                  className={classNames(css.cardBrand)}
                />
                <span className={css.cardText}>****-****-****-{payment?.stripe?.paymentMethod?.card?.last4}</span>
              </span>
            </span>
          </div>
        )}
        {payment?.mode === 'payPay' && <img src={image['pay_pay.webp']} className={classNames(css.payPay)} />}
        <div className={classNames(css.checkBoxWrap)}>
          <img
            src={image[`check_box_${isButtonActive ? 'active' : 'passive'}.svg`]}
            className={classNames(css.checkBox)}
            onClick={() => {
              setIsButtonActive(!isButtonActive);
            }}
          />
          <Link
            style={{
              color: '#3EB370',
              marginTop: '12px',
              textDecoration: 'underline',
            }}
            to={'/policy'}
            rel="noopener noreferrer"
          >
            <span
              onClick={() => {
                updateTermsAndPolicy('terms', true);
              }}
            >
              利用規約
            </span>
          </Link>
          <span>・</span>
          <Link
            style={{
              color: '#3EB370',
              marginTop: '12px',
              textDecoration: 'underline',
            }}
            to={'/policy'}
            rel="noopener noreferrer"
          >
            <span
              onClick={() => {
                updateTermsAndPolicy('terms', false);
              }}
            >
              プライバシーポリシー
            </span>
          </Link>
          に同意する
        </div>
        <LinkButton
          className={classNames(css.linkButton)}
          width={inputFormWidth}
          height={linkButtonHeight}
          text={payment?.payPay?.sentData && payment.mode === 'payPay' ? 'PayPayへ遷移する' : '注文を確定する'}
          color="black"
          active={isButtonActive}
          loading={buttonIsLoading}
          onClick={() => {
            if (payment?.mode === 'stripe') {
              confirmStripe();
            }
            if (payment?.mode === 'payPay') {
              confirmPayPay();
            }
          }}
        />
        <LinkButton
          width={inputFormWidth}
          height={linkButtonHeight}
          text="決済に戻る"
          color="white"
          arrow="left"
          active={true}
          onClick={() => {
            navigate('/kamaitachi-no-okite/takeout/payment');
          }}
        />
      </div>
    </div>
  );
};
