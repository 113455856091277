import { useEffect, useState } from 'react';
import { MdClear, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import classNames from 'classnames';
import styled from 'styled-components';
import css from './index.module.scss';
import { BoxButton, TextButton } from '../../lp/components';
// MEMO: Recoil
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  addKamaitachiProductState,
  addKamaitachiTotalState,
  lpProductState,
  addKamaitachiIsDelivery,
} from '../store/atoms';
// MEMO: images
import { importAll } from '../utils/image';
import logo from './img/logo.webp';
import logoText from './img/logoText.webp';
import deleteIcon from './img/deleteIcon.png';

import minusButton from './img/minusButton.webp';
import plusButton from './img/plusButton.webp';
import sideMenuIconChecked from './img/sideMenuIconChecked.webp';
import sideMenuIcon from './img/sideMenuIcon.webp';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const ItemCon = styled.div`
  width: 60%;
  position: relative;
  @media (min-width: 900px) {
    width: 50%;
  }
`;

const ItemCountCon = styled.div`
  @media (min-width: 900px) {
    width: 50%;
  }
`;

const TotalPrice = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 42px;
  margin-top: 12px;
  margin-right: 22px;
`;

const CartIconCon = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;
`;

const CountIcon = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 4px;
  margin-bottom: -0.7em;
  font-size: 24px;
  @media (max-width: 900px) {
    font-size: 18px;
    padding-left: 3px;
    margin-bottom: -0.8em;
  }
`;
export function KamaitachiHeader() {
  const navigate = useNavigate();
  return (
    <header className={css.header}>
      <div className={css.imageBox}>
        <img
          src={logo}
          className={css.logo}
          alt="logo"
          onClick={() => {
            navigate('/kamaitachi-no-okite');
          }}
        />
        <img
          src={logoText}
          className={css.logoText}
          alt="logo"
          onClick={() => {
            navigate('/kamaitachi-no-okite');
          }}
        />
      </div>
    </header>
  );
}

export function SideMenu(props) {
  const [isChecked, setIsChecked] = useState(false);

  const handleIsChecked = () => {
    if (isChecked) {
      setIsChecked(false);
    } else {
      setIsChecked(true);
    }
  };

  return (
    <div className={css.sideMenu}>
      <input type="checkbox" id="openSidebarMenu" onChange={() => handleIsChecked()} />
      <label htmlFor="openSidebarMenu" className={css.sidebarIconToggle}>
        <img
          src={sideMenuIconChecked}
          className={classNames(!isChecked && css.display_false)}
          alt="sideMenuIconChecked"
        />
        <img src={sideMenuIcon} className={classNames(isChecked && css.display_false)} alt="sideMenuIcon" />
      </label>

      <div className={css.sidebarMenu}>
        <ul className={css.sidebarMenuInner}>{props.children}</ul>
      </div>
    </div>
  );
}

export function ErrModal(props) {
  return (
    <div className={css.errModal}>
      <div
        // onClick={props.updateSetIsErrModal(false)}
        onClick={props.close()}
        className={css.ModalBack}
      >
        <div className={css.Modal}>
          <div className={css.iconBox}>
            <img src={deleteIcon} className={css.deleteIcon} alt="deleteIcon" />
          </div>
          <div className={css.textBox}>
            <div className={css.title}>
              <p>本当に削除しますか？</p>
            </div>

            <div className={css.description}>
              <p>
                削除すると元に戻せなくなります。 <br />
                同じ商品をご購入の際は、選びおなしてください。
                <br />
                本当に削除してよろしいですか？
              </p>
            </div>
          </div>

          <div className={css.buttonBox}>
            <button className={css.deleteButton} onClick={() => {}}>
              削除する
            </button>

            <button className={css.cancelButton} onClick={() => {}}>
              {'< やめる'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export function ItemPanel(props) {
  const imageData = props.imageData;
  const name = props.name;
  const price = props.price !== 0 ? props.price.toLocaleString() : 0;
  const column = props.column;
  const item = props.column;
  const productCode = props.productCode;
  const count = props.count;

  const [displayCount, setDisplayCount] = useState(1);
  if (displayCount < 1) {
    setDisplayCount(1);
  }

  return (
    <div className={classNames(css.itemPanel, column === 'single' ? css.singleItemPanel : css.doubleItemPanel)}>
      <div className={classNames(css.imageBox, item === 'double' ? css.singleItemImage : css.doubleItemImage)}>
        <img src={imageData} alt="logo" />
      </div>
      <div className={css.textBox}>
        <p className={css.name}>{name}</p>
        <p className={css.price}>¥{price}(税込)</p>
      </div>
      <div className={css.actionBox}>
        <div className={css.countBox}>
          <img
            src={minusButton}
            className={css.minusButton}
            alt="minusButton"
            onClick={() => {
              setDisplayCount(displayCount - 1);
            }}
          />
          <p className={css.itemCount}>{displayCount}</p>
          <img
            src={plusButton}
            className={css.plusButton}
            alt="plusButton"
            onClick={() => {
              setDisplayCount(displayCount + 1);
            }}
          />
        </div>
        <button
          className={css.button}
          onClick={() => {
            props.onClickItemAddCount(productCode, count + displayCount);
            setDisplayCount(1);
          }}
        >
          カートへ
        </button>
      </div>
    </div>
  );
}

export function CustomerInfo(data) {
  const image = importAll(require.context('../delivery/confirm/image', false, /\.(webp|svg)$/));
  const customer = data.customer;
  return (
    <div className={css.customerInfoSection}>
      <div className={css.title}>お客様情報</div>
      <div className={classNames(css.customerInfo)}>
        <img src={image['customer.svg']} />
        <span className={css.text}>
          {customer.familyName} {customer.lastName}（{customer.familyNameKana} {customer.lastNameKana}）
        </span>
      </div>
      <div className={classNames(css.customerInfo)}>
        <img src={image['mail.svg']} />
        <span className={css.text}>{customer.mailAddress}</span>
      </div>
      <div className={classNames(css.customerInfo)}>
        <img src={image['phone.svg']} />
        <span className={css.text}>{customer.phoneNum}</span>
      </div>
    </div>
  );
}

export function CustomerInfo_takeout(data) {
  const image = importAll(require.context('../delivery/confirm/image', false, /\.(webp|svg)$/));
  const customer = data.customer;
  const takeout = data.takeout;
  return (
    <div className={css.customerInfoSection}>
      <div className={css.title}>お客様情報</div>
      <div className={classNames(css.customerInfo)}>
        <img src={image['customer.svg']} />
        <span className={css.text}>
          {customer.lastName} {customer.firstName}（{customer.lastNameKana} {customer.firstNameKana}）
        </span>
      </div>
      <div className={classNames(css.customerInfo)}>
        <img src={image['place.svg']} />
        <div className={classNames(css.text)}>お受け取り店舗：{takeout.place}</div>
      </div>
      <div className={classNames(css.customerInfo)}>
        <div className={classNames(css.customerInfo)}>
          <img src={image['calender.svg']} />
          <div className={classNames(css.text)}>
            期日：{takeout?.date && dayjs(takeout.date).format('YYYY年MM月DD日')}
            {customer.takeoutTime}
          </div>
        </div>
      </div>
      <div className={classNames(css.customerInfo)}>
        <img src={image['mail.svg']} />
        <span className={css.text}>{customer.mailAddress}</span>
      </div>
      <div className={classNames(css.customerInfo)}>
        <img src={image['phone.svg']} />
        <span className={css.text}>{customer.phoneNum}</span>
      </div>
    </div>
  );
}

export function CustomerInfo_delivery(data) {
  const image = importAll(require.context('../delivery/confirm/image', false, /\.(webp|svg)$/));
  const customer = data.customer;
  const address = data.address.list[0];
  if (!address) return null;
  return (
    <div className={css.customerInfoSection}>
      <div className={css.title}>お客様情報</div>
      <div className={classNames(css.customerInfo)}>
        <img src={image['customer.svg']} />
        <span className={css.text}>
          {customer.lastName} {customer.firstName}（{customer.lastNameKana} {customer.firstNameKana}）
        </span>
      </div>
      <div className={classNames(css.text)}>
        <div className={classNames(css.customerInfo)}>
          <img src={image['place.svg']} />
          <div className={classNames(css.text)}>〒{address.zipCode}</div>
        </div>
        <div className={classNames(css.address)}>
          {address.prefecture}
          {address.city}
          {address.suburb}
          {address.street}
          <br />
          {address.building}
        </div>
      </div>
      <div className={classNames(css.customerInfo)}>
        <img src={image['mail.svg']} />
        <span className={css.text}>{customer.mailAddress}</span>
      </div>
      <div className={classNames(css.customerInfo)}>
        <img src={image['phone.svg']} />
        <span className={css.text}>{customer.phoneNum}</span>
      </div>
    </div>
  );
}

export function DeliveryInfo_delivery(data) {
  const image = importAll(require.context('../delivery/confirm/image', false, /\.(webp|svg)$/));
  const address = data.address.list[data.address.selected];
  const delivery = data.delivery;
  if (!address) return null;
  return (
    <div className={css.customerInfoSection}>
      <div className={css.title}>配送先情報</div>
      <div className={classNames(css.customerInfo)}>
        <img src={image['customer.svg']} />
        <span className={css.text}>
          {address.lastName} {address.firstName}（{address.lastNameKana} {address.firstNameKana}）
        </span>
      </div>
      <div className={classNames(css.text)}>
        <div className={classNames(css.customerInfo)}>
          <img src={image['shipping.svg']} />
          <div className={classNames(css.text)}>〒{address.zipCode}</div>
        </div>
        <div className={classNames(css.address)}>
          {address.prefecture}
          {address.city}
          {address.suburb}
          {address.street}
          <br />
          {address.building}
        </div>
      </div>
      <div className={classNames(css.customerInfo)}>
        <img src={image['phone.svg']} />
        <span className={css.text}>{address.phoneNum}</span>
      </div>
      <div className={classNames(css.text)}>
        <div className={classNames(css.customerInfo)}>
          <img src={image['calender.svg']} />
          <div className={classNames(css.text)}>{delivery.date && dayjs(delivery.date).format('YYYY年MM月DD日')}</div>
        </div>
        <div className={classNames(css.address)}>{delivery.time}</div>
      </div>
    </div>
  );
}

export function OrderDetails(data) {
  const [productList, setProductList] = useState([]);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const product = data.product;
  const total = data.total;
  useEffect(() => {
    const productList = [];
    let totalItemPrice = 0;

    if (Object.keys(product).length !== 0) {
      Object.keys(product).map((data, key) => {
        const item = product[data];
        if (item.count > 0) {
          totalItemPrice += item.count * item.price;
          productList.push(
            <div key={key} className={classNames(css.productList)}>
              <div className={classNames(css.productName, css.text)}>{item.name}</div>
              <div className={classNames(css.text, css.price)}>
                ¥{item.price.toLocaleString()} × {item.count} = ¥{(item.price * item.count).toLocaleString()}
              </div>
            </div>
          );
        }
      });
    }
    setDeliveryFee(total.totalPrice - totalItemPrice);
    setProductList(productList);
  }, []);

  return (
    <div className={css.orderDetailsSection}>
      <span className={css.title}>ご注文内容</span>
      {productList}
      <hr className={classNames(css.lineS)} />
      {deliveryFee > 0 && (
        <div className={classNames(css.productList)}>
          <div className={classNames(css.text)}>
            <span>配送料</span>
          </div>
          <div className={classNames(css.text, css.price)}>¥{Math.floor(deliveryFee).toLocaleString()}</div>
        </div>
      )}
      <div className={classNames(css.productList)}>
        <div className={classNames(css.text)}>
          <span>消費税（内税）</span>
        </div>
        <div className={classNames(css.text, css.price)}>
          ¥{Math.floor(total.totalPrice - total.totalPrice / 1.08).toLocaleString()}
        </div>
      </div>
      <hr className={classNames(css.lineS)} />

      <div className={classNames(css.productList)}>
        <span className={css.text}>合計</span>
        <span className={classNames(css.text, css.price)}>¥{total.totalPrice.toLocaleString()}</span>
      </div>
    </div>
  );
}

// MEMO既存商品画像
import CF from './img/LpProduct/BENE_REGALO_Chocolat_Feuillantine.webp';
import ES from './img/LpProduct/BENE_REGALO_Espresso_Sause.webp';
import GS3 from './img/LpProduct/tiramisu_gelato_6.webp';
import GS6 from './img/LpProduct/tiramisu_gelato_12.webp';
import MTP4PTBo from './img/LpProduct/white_day_set3.webp';
import MTP4PTBoTF10TM4TG6 from './img/LpProduct/white_day_full_set.webp';
import NchoT16 from './img/LpProduct/namachoco_002.webp';
import PTBa from './img/LpProduct/BENE_REGALO_Tiramisu_in_bag.webp';
import PTBo from './img/LpProduct/BENE_REGALO_Tiramisu_in_box.webp';
import PTBoTF10TM4 from './img/LpProduct/loversSet.webp';
import PTBoTF10TM4TG6 from './img/LpProduct/tiramisu_super_lovers_set.webp';
import TFx10 from './img/LpProduct/BENE_REGALO_Tiramisu_Financier_10.webp';
import TFx2 from './img/LpProduct/BENE_REGALO_Tiramisu_Financier_2.webp';
import TG6PTBo from './img/LpProduct/white_day_set1.webp';
import TG6TF10 from './img/LpProduct/white_day_set2.webp';
import TM4 from './img/LpProduct/macaronInBox.webp';
import NGTR from './img/LpProduct/negitoro.webp';
import CS from './img/LpProduct/coaster.webp';

const cartLpProductImages = {
  CS: CS,
  NGTR: NGTR,
  CF: CF,
  ES: ES,
  GS3: GS3,
  GS6: GS6,
  MTP4PTBo: MTP4PTBo,
  MTP4PTBoTF10TM4TG6: MTP4PTBoTF10TM4TG6,
  NchoT16: NchoT16,
  PTBa: PTBa,
  PTBo: PTBo,
  PTBoTF10TM4: PTBoTF10TM4,
  PTBoTF10TM4TG6: PTBoTF10TM4TG6,
  TFx10: TFx10,
  TFx2: TFx2,
  TG6PTBo: TG6PTBo,
  TG6TF10: TG6TF10,
  TM4: TM4,
};

// TODO: Cart部分切り分けたい
export function Cart(props) {
  const [product, setProduct] = useRecoilState(addKamaitachiProductState);
  const [kamaitachiTotal, setKamaitachiTotal] = useRecoilState(addKamaitachiTotalState);

  const isDelivery = useRecoilValue(addKamaitachiIsDelivery);

  useEffect(() => {
    let totalCount = 0;
    let totalPrice = 0;

    Object.keys(product).forEach((e) => {
      totalCount += product[e]['count'];
      totalPrice += product[e]['count'] * product[e]['price'];
    });

    setKamaitachiTotal((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future['totalCount'] = totalCount;
      future['totalPrice'] = totalPrice;
      return future;
    });
  }, [product]);

  const navigate = useNavigate();
  const setShow = (event) => {
    event.preventDefault();
    props.close();
    // TODO: 後続画面に遷移する処理を入れる
    isDelivery
      ? navigate('/kamaitachi-no-okite/delivery/customer-info')
      : navigate('/kamaitachi-no-okite/takeout/customer-info');
  };

  const onClickItemAddCount = (productCode, count) => {
    setProduct((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future[productCode]['count'] = count;
      return future;
    });
  };

  const items = [];
  // TODO: LP画像を追加する
  const cartProductImages = props.cartProductImages;

  if (Object.keys(product).length !== 0) {
    Object.keys(product).map((data, key) => {
      const item = product[data];
      if (item['count'] > 0) {
        items.push(
          <CartItem
            imageData={
              cartProductImages[item['productCode']]
                ? cartProductImages[item['productCode']]
                : cartLpProductImages[item['productCode']]
            }
            key={key}
            name={item['name']}
            price={item['price']}
            productCode={item['productCode']}
            count={item['count']}
            onClickItemAddCount={(productCode, count) => {
              onClickItemAddCount(productCode, count);
            }}
            isErrModal={props.isErrModal}
            updateSetIsErrModal={props.updateSetIsErrModal}
            close={props.close}
            notify={props.notify}
          />
        );
      }
    });
  }

  function CartItem(props) {
    const product = useRecoilValue(addKamaitachiProductState);

    return (
      <div className={css.CartItem}>
        <ItemCon>
          <div className={`${css.item_font1} `}>{props.name}</div>
          <div className={`${css.item_font2} `}>￥{props.price?.toLocaleString()}</div>
        </ItemCon>

        <ItemCountCon>
          <div className={css.item_img_con1}>
            <img src={props.imageData} alt="" className={css.item_img} />
          </div>
          <div className={`${css.buttons}  `}>
            <div className={css.item_img_con2}>
              <img src={props.imageData} alt="" className={css.item_img} />
            </div>

            <div className={css.buttonsContent}>
              <div
                onClick={() => {
                  // MEMO: かまいたち商品0こかどうか判別する
                  const kamaitachiPickItems = {};
                  let count = 0;
                  const kamaitachiProductProductCodeList = [
                    'HMIE',
                    'HMIECS',
                    'KMITC',
                    'KMITCCS',
                    'YMUC',
                    'YMUCCS',
                    'KMITCW6S',
                    'KMITCWC6S',
                  ];
                  const lpProductProductCodeList = Object.keys(lpProductList);

                  Object.keys(product).forEach((e) => {
                    if (kamaitachiProductProductCodeList.includes(e)) {
                      kamaitachiPickItems[product[e]['productCode']] = product[e];
                    }
                  });

                  Object.keys(kamaitachiPickItems).forEach((e) => {
                    count += kamaitachiPickItems[e]['count'];
                  });

                  let kamaitachiProductFlg;
                  lpProductProductCodeList.find((element) => {
                    if (element === props.productCode) {
                      props.onClickItemAddCount(props.productCode, product[props.productCode]['count'] - 1);
                      return;
                    } else {
                      kamaitachiProductProductCodeList.find((element) => {
                        if (element === props.productCode) {
                          kamaitachiProductFlg = true;
                        }
                      });
                      if (count < 2 && kamaitachiProductFlg) {
                        // MEMO: state更新の関係上2だが、かまいたち商品を0にしようとしたら踏む
                        // MEMO: エラーモーダル出そうとしたので作り込めなかったので後でやる
                        // props.updateSetIsErrModal(true);
                        // props.close;
                        return props.notify();
                      }
                    }
                    props.onClickItemAddCount(props.productCode, product[props.productCode]['count'] - 1);
                  });
                }}
                className={css.buttonsBox}
              >
                <img src={`${process.env.PUBLIC_URL}/removebutton.svg`} alt="" className={`${css.wid100}`} />
              </div>
              <span>{props.count}</span>

              <div className={css.buttonsBox}>
                <img
                  onClick={() => {
                    props.onClickItemAddCount(props.productCode, props.count + 1);
                  }}
                  src={`${process.env.PUBLIC_URL}/addbutton.svg`}
                  alt=""
                  className={classNames(props.disabled ? css.disable : '')}
                />
              </div>
            </div>
          </div>
        </ItemCountCon>
      </div>
    );
  }

  const cartContent =
    kamaitachiTotal.totalCount > 0 ? (
      items
    ) : (
      <div className={css.pd24_0}>
        <img className={classNames(css.vertAlM, css.mr12)} src={`${process.env.PUBLIC_URL}/15.svg`} alt="" />
        <span>カートは空っぽです</span>
      </div>
    );

  const [isOpen, setIsOpen] = useState(false);
  const lpProductList = useRecoilValue(lpProductState);
  const deliveryFee = kamaitachiTotal.totalPrice < 6000 ? 1000 : 0;

  const bottomContent =
    kamaitachiTotal.totalCount > 0 ? (
      <div className={css.bottomContent}>
        <div className={css.fee}>
          {isDelivery && (
            <div className={css.postage}>
              <span className={css.span1}>見込み送料 </span>
              <span className={css.span2}>¥</span>
              <span className={css.span3}>{deliveryFee.toLocaleString()}</span>
              <span className={css.span4}>(税込)</span>
            </div>
          )}

          <TotalPrice>
            <CartIconCon>
              <img className={classNames(css.vertAlM, css.cart_icon)} src={`${process.env.PUBLIC_URL}/15.svg`} alt="" />
              <CountIcon>{kamaitachiTotal.totalCount}</CountIcon>
            </CartIconCon>
            <span className={`${css.priceFont}`}>合計</span>
            <span className={`${css.priceFont2}`}>¥</span>
            <span className={`${css.priceFont3} ${css.fw_b}`}>
              {isDelivery
                ? (kamaitachiTotal.totalPrice + deliveryFee).toLocaleString()
                : kamaitachiTotal.totalPrice.toLocaleString()}
            </span>
            <span className={`${css.priceFont}`}>(税込)</span>
          </TotalPrice>
        </div>

        {(() => {
          if (!isDelivery) return;
          // MEMO: LP商品でcount:0のものがあれば表示する
          let flg = false;
          Object.keys(product).forEach((e) => {
            if (Object.keys(lpProductList).includes(e)) {
              if (product[e]['count'] === 0) {
                flg = true;
                return;
              }
            }
          });

          if (flg) {
            return (
              <>
                <div className={css.addTextBox}>
                  <p>セット商品はいかがですか？</p>
                  <p>送料そのままでご追加できます。</p>
                </div>
                <div className={classNames(css.upSellBox, isOpen ? css.open : css.close)}>
                  <div className={classNames(css.upCellItems, isOpen ? css.open : css.close)}>
                    <UpCellItemsWrapper />
                  </div>
                </div>
                <div
                  className={css.isSetShow}
                  onClick={() => {
                    isOpen ? setIsOpen(false) : setIsOpen(true);
                  }}
                >
                  {(() => {
                    if (isOpen) {
                      return (
                        <>
                          <MdKeyboardArrowUp className={`${css.wh12}`} />
                          <p>セット商品を閉じる</p>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <MdKeyboardArrowDown className={`${css.wh12}`} /> <p>&nbsp;セット商品をもっと見る</p>
                        </>
                      );
                    }
                  })()}
                </div>
              </>
            );
          }
        })()}

        <div onClick={setShow} className={css.purchase}>
          <BoxButton text="購入手続きへ進む" />
        </div>
        <div className={css.continue}>
          <TextButton onClick={props.close} text="買い物を続ける" />
        </div>
      </div>
    ) : (
      <div className={css.choose}>
        <BoxButton onClick={props.moveWindow} text="ティラミスを選ぶ" className={classNames(css.mt32, css.mb24)} />
      </div>
    );

  return (
    <div className={css.CartModal}>
      <div onClick={props.close} className={css.ModalBack}></div>
      <div className={css.Modal}>
        <div className={css.topContent}>
          <button onClick={props.close}>
            <MdClear className={`${css.wh24}`} />
          </button>
          <img src={`${process.env.PUBLIC_URL}/bene.svg`} alt="" className={classNames(css.cartLogo)} />
          <span className={`${css.vertAlM} ${css.hei20}`}>カート</span>
        </div>
        <div className={classNames(css.cartContent, css.items)}>{cartContent}</div>
        {bottomContent}
      </div>
    </div>
  );
}
function UpCellItems(props) {
  return (
    <div
      className={css.upCellItem}
      onClick={() => {
        props.onClickItemAddCount(props.productCode, props.count + 1);
      }}
    >
      <div className={css.textBox}>
        <div className={`${css.name} `}>
          {(() => {
            if (props.uid === 'uid5' || props.uid === 'uid6') {
              return '【追加】';
            }
          })()}
          {props.name}
        </div>
        <div className={`${css.price} `}>￥{props.price.toLocaleString()}</div>
      </div>
      <div className={css.box}>
        <div className={css.imgBox}>
          <img src={cartLpProductImages[props.productCode]} alt="" className={css.img} />
        </div>
        <div
          onClick={() => {
            props.onClickItemAddCount(props.productCode, props.count + 1);
          }}
          className={css.addTextBox}
        >
          <p className={css.text}>{'追加する'}</p>
        </div>
      </div>
    </div>
  );
}
export function UpCellItemsWrapper() {
  const [product, setProduct] = useRecoilState(addKamaitachiProductState);
  const lpProductList = useRecoilValue(lpProductState);
  const [upCellItems, setUpCellItems] = useState(product);

  const onClickItemAddCount = (productCode, count) => {
    setProduct((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future[productCode]['count'] = count;
      return future;
    });
  };

  // MEMO: productからLp商品のみを抜き出す。
  const pickItems = {};
  Object.keys(product).forEach((e) => {
    if (Object.keys(lpProductList).includes(e)) {
      pickItems[product[e]['productCode']] = product[e];
    }
  });
  useEffect(() => {
    // MEMO: 、countが0のみのものをUpCellItemsに入れる
    const resultItems = {};
    Object.keys(pickItems).forEach((e) => {
      if (pickItems[e]['count'] === 0) {
        resultItems[pickItems[e]['productCode']] = pickItems[e];
      }
    });
    setUpCellItems(resultItems);
  }, [product]);
  let result = [];
  Object.keys(upCellItems).forEach((e) => {
    result.push(
      <UpCellItems
        key={upCellItems[e]['name']}
        name={upCellItems[e]['name']}
        price={upCellItems[e]['price']}
        count={upCellItems[e]['count']}
        productCode={upCellItems[e]['productCode']}
        onClickItemAddCount={(productCode, count) => {
          onClickItemAddCount(productCode, count);
        }}
      />
    );
    // }
  });
  // }
  return <>{result}</>;
}
