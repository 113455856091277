import { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { InputBox } from '../../lp/components';
import classNames from 'classnames';
import css from '../../index.module.scss';
import managementCss from './../index.module.scss';

export const LoginComponent = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const inputEmail = (event) => {
    setEmail(event.target.value);
  };

  const inputPassword = (event) => {
    setPassword(event.target.value);
  };

  const logIn = (email, password) => {
    signInWithEmailAndPassword(props.auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        if (user.uid !== 'UTNuxmIpGaZBAA1qiObXuCDt15V2') {
          props.logOut();
        }
        props.setIsLogin(true);
        // eslint-disable-next-line no-console
        console.log('Loginが成功しました');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error.code);
        // eslint-disable-next-line no-console
        console.log(error.message);
      });
  };
  return (
    <div className={classNames(managementCss.screen1, props.isLogin ? css.displayNone : '')}>
      <h1 className={classNames(managementCss.title)}>管理画面 ログイン</h1>
      <InputBox
        title="メールアドレス"
        placeholder="address@domain.com"
        padding={[0, 0, 0, 8]}
        content={email || null}
        onChange={(e) => inputEmail(e)}
      />
      <InputBox
        title="パスワード"
        type="password"
        placeholder="****"
        padding={[0, 0, 0, 8]}
        content={password || null}
        onChange={(e) => inputPassword(e)}
      />
      <div
        onClick={() => {
          logIn(email, password);
        }}
        className={classNames(managementCss.login_button)}
      >
        ログイン
      </div>
    </div>
  );
};
