import { FormHeader, WhiteBoxButton } from './components';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import classNames from 'classnames';
import css from '../index.module.scss';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { paymentState, orderState, userState, deliveryState, firstViewState, cartState } from './atoms';
import { itemList, sumUpCart } from './common';
import * as Util from './util';

const Thanks = () => {
  const payment = useRecoilValue(paymentState);
  const order = useRecoilValue(orderState);
  const user = useRecoilValue(userState);
  const delivery = useRecoilValue(deliveryState);
  const setCart = useSetRecoilState(cartState);

  const state = order.orderId;
  const callBackUrl = `${
    process.env.REACT_APP_IS_PROD === 'true'
      ? 'https://bene-regalo.com/'
      : 'https://bene-reglo-lp--preview-azgxuhem.web.app/'
  }`;
  const url = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1657393544&redirect_uri=${callBackUrl}&state=${state}&bot_prompt=aggressive&scope=profile%20openid%20email&`;

  const cart = { items: {} };
  if (payment.paymentIntent?.metadata) {
    Object.keys(payment.paymentIntent.metadata).forEach((key) => {
      if (Object.keys(itemList).includes(key) && payment.paymentIntent.metadata[key] !== '0') {
        cart.items[key] = {
          ...itemList[key],
          count: payment.paymentIntent.metadata[key],
        };
      }
    });
  }

  const card = payment.paymentMethod ? payment.paymentMethod.card : {};

  useEffect(() => {
    Util.sendRefererData('Thanks');
  }, []);

  const firstView = useRecoilValue(firstViewState);
  const navigate = useNavigate();
  useEffect(() => {
    if (!firstView.visited) {
      navigate('/');
    }
  }, []);

  const handleLinkClick = () => {
    setCart((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future['items'][`uid1`]['count'] = 0;
      future['items'][`uid2`]['count'] = 0;
      future['items'][`uid3`]['count'] = 0;
      future['items'][`uid4`]['count'] = 0;
      future['items'][`uid5`]['count'] = 0;
      future['items'][`uid6`]['count'] = 0;
      future['items'][`uid8`]['count'] = 0;
      future['items'][`uid9`]['count'] = 0;
      future['items'][`uid10`]['count'] = 0;
      future['items'][`uid11`]['count'] = 0;
      future['items'][`uid12`]['count'] = 0;
      // future['items'][`uid13`]['count'] = 0;
      // future['items'][`uid14`]['count'] = 0;
      // future['items'][`uid15`]['count'] = 0;
      // future['items'][`uid16`]['count'] = 0;
      // future['items'][`uid17`]['count'] = 0;
      // future['items'][`uid18`]['count'] = 0;
      // future['items'][`uid19`]['count'] = 0;
      // future['items'][`uid20`]['count'] = 0;
      // future['items'][`uid21`]['count'] = 0;
      // future['items'][`uid22`]['count'] = 0;
      // future['items'][`uid23`]['count'] = 0;
      // future['items'][`uid24`]['count'] = 0;
      future['items'][`uid31`]['count'] = 0;
      future['items'][`uid32`]['count'] = 0;
      future['items'][`uid33`]['count'] = 0;
      future['items'][`uid34`]['count'] = 0;
      future['items'][`uid35`]['count'] = 0;
      return future;
    });
    navigate('/');
  };

  return (
    <div className={classNames(css.Confirm, css.input_info_con)}>
      <FormHeader title="Thank you so much." step="step5" />
      <div className={css.t}>
        <div className={classNames(css.pd0_24, css.fs24, css.mt12, css.mb12, css.ma, css.text)}>
          BENE REGALOをご注文いただき、誠にありがとうございます。
          ご注文が確認でき次第、注文完了メールをお送りさせていただきます。
          <div className={css.line_login_text}>
            限定のティラミスなどの最新情報をいち早くお届け！
            <br />
            便利な会員機能はこちらから
          </div>
          <a href={url} alt="" className={css.line_login_button}>
            <div className={css.button_mask}></div>
            <img src={`${process.env.PUBLIC_URL}/line_login.svg`} alt="" />
          </a>
        </div>
        <img
          width="90%"
          height="60%"
          src={`${process.env.PUBLIC_URL}/1122_BeneRegalo_48448 1.png`}
          alt=""
          className={`${classNames(css.pd0_24, css.Img7, css.ma)}`}
        />
        {/* <span className={css.t1}>注文番号</span>
                <div className={classNames(css.flex, css.wid100, css.t2, css.mt12, css.mb20)}>
                    <div>
                        order No. ?
                    </div>
                </div> */}
        <div className={classNames(css.pd0_24, css.t)}>
          <span className={css.t1}>購入明細</span>
          <div className={classNames(css.flex, css.wid100, css.t2, css.mt12, css.mb6)}></div>
          {Object.keys(cart.items).map(
            (key, i) =>
              cart.items[key].count !== 0 && (
                <div key={i} className={classNames(css.flex, css.wid100, css.t2, css.mt12)}>
                  <div>{cart.items[key].name}</div>
                  <div>
                    ￥{cart.items[key].price.toLocaleString()} x {cart.items[key].count} ￥
                    {Math.floor(cart.items[key].price * cart.items[key].count).toLocaleString()}
                  </div>
                </div>
              )
          )}
          <div className={classNames(css.flex, css.wid100, css.t2, css.mb20)} />
          <div className={classNames(css.flex, css.wid100, css.t2, css.mb20)}>
            <div>配送料</div>
            <div>
              ￥
              {payment?.paymentIntent?.amount
                ? (payment.paymentIntent.amount - sumUpCart(cart)).toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,')
                : 'テスト円'}
            </div>
          </div>
          {/* <div className={classNames(css.flex, css.wid100, css.t2, css.mb12)}>
                        <div>
                            消費税（内税）
                        </div>
                        <div >
                            ￥{Math.floor(Object.keys(cart.items).reduce((acc, item) => (cart.items[acc]["price"] * cart.items[acc]["count"]) + (cart.items[item]["price"] * cart.items[item]["count"])) * 0.08)}
                        </div>
                    </div> */}
          <hr className={classNames(css.mb12, css.lineS)} />
          <div className={css.flex}>
            <span className={css.t1}>合計</span>
            <span className={css.t1}>
              ￥
              {payment?.paymentIntent?.amount
                ? payment.paymentIntent.amount.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,')
                : 'テスト円'}
            </span>
          </div>
        </div>
        <hr className={classNames(css.mb24, css.lineM, css.mt24, css.shippingPadding)} />

        <div className={css.shippingPadding}>
          <div className={css.flex}>
            <p className={css.t1}>お客様情報</p>
          </div>
          <div className={css.flex_start}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M18.1 18.6H18.6V18.1V17C18.6 16.7295 18.4457 16.5182 18.3307 16.3909C18.2013 16.2475 18.0306 16.1118 17.8421 15.986C17.4624 15.7325 16.9426 15.4745 16.3437 15.2435C15.1456 14.7814 13.5591 14.4 12 14.4C10.4409 14.4 8.8544 14.7814 7.65632 15.2435C7.05741 15.4745 6.53759 15.7325 6.15785 15.986C5.96945 16.1118 5.79872 16.2475 5.66925 16.3909C5.55427 16.5182 5.4 16.7295 5.4 17V18.1V18.6H5.9H18.1ZM14.6 8C14.6 6.56386 13.4361 5.4 12 5.4C10.5639 5.4 9.4 6.56386 9.4 8C9.4 9.43614 10.5639 10.6 12 10.6C13.4361 10.6 14.6 9.43614 14.6 8ZM8.5 8C8.5 6.06614 10.0661 4.5 12 4.5C13.9339 4.5 15.5 6.06614 15.5 8C15.5 9.93386 13.9339 11.5 12 11.5C10.0661 11.5 8.5 9.93386 8.5 8ZM4.5 17C4.5 16.5186 4.73716 16.06 5.21364 15.6202C5.69352 15.1773 6.38208 14.7882 7.18469 14.4666C8.79071 13.8233 10.7275 13.5 12 13.5C13.2725 13.5 15.2093 13.8233 16.8153 14.4666C17.6179 14.7882 18.3065 15.1773 18.7864 15.6202C19.2628 16.06 19.5 16.5186 19.5 17V19.5H4.5V17Z"
                fill="#161C1C"
                stroke="black"
              />
            </svg>
            <span className={css.ml24}>
              {user?.user?.lName?.value || null}
              {user?.user?.fName?.value || null}
            </span>
            <br />
            <span className={css.ml48}>
              {user?.user?.lNameKana?.value || null}
              {user?.user?.fNameKana?.value || null}
            </span>
          </div>
          <div className={classNames(css.flex_start, css.mt12)}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2ZM7 9C7 6.24 9.24 4 12 4C14.76 4 17 6.24 17 9C17 11.88 14.12 16.19 12 18.88C9.92 16.21 7 11.85 7 9Z"
                fill="#161C1C"
              />
              <path
                d="M12 11.5C13.3807 11.5 14.5 10.3807 14.5 9C14.5 7.61929 13.3807 6.5 12 6.5C10.6193 6.5 9.5 7.61929 9.5 9C9.5 10.3807 10.6193 11.5 12 11.5Z"
                fill="#161C1C"
              />
            </svg>
            <span className={css.ml24}>
              〒{user?.user?.postalCode?.value.slice(0, 3) + '-' + user?.user?.postalCode?.value.slice(3, 7)}
            </span>
            <br />
            <div className={classNames(css.ml48, css.overflowWrap)}>
              <span>
                {user?.user?.postalCode?.value2 || null}
                {user?.user?.address1?.value || null}
                {user?.user?.address2?.value || null}
              </span>
              <br />
              <span>{user?.user?.address3?.value || null}</span>
            </div>
          </div>
          <div className={classNames(css.flex_start, css.mt12)}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.54 5C6.6 5.89 6.75 6.76 6.99 7.59L5.79 8.79C5.38 7.59 5.12 6.32 5.03 5H6.54ZM16.4 17.02C17.25 17.26 18.12 17.41 19 17.47V18.96C17.68 18.87 16.41 18.61 15.2 18.21L16.4 17.02ZM7.5 3H4C3.45 3 3 3.45 3 4C3 13.39 10.61 21 20 21C20.55 21 21 20.55 21 20V16.51C21 15.96 20.55 15.51 20 15.51C18.76 15.51 17.55 15.31 16.43 14.94C16.33 14.9 16.22 14.89 16.12 14.89C15.86 14.89 15.61 14.99 15.41 15.18L13.21 17.38C10.38 15.93 8.06 13.62 6.62 10.79L8.82 8.59C9.1 8.31 9.18 7.92 9.07 7.57C8.7 6.45 8.5 5.25 8.5 4C8.5 3.45 8.05 3 7.5 3Z"
                fill="#161C1C"
              />
            </svg>
            <span className={css.ml12}>{user?.user?.phoneNum?.value}</span>
          </div>
          <div className={classNames(css.flex_start, css.mt12)}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6ZM20 6L12 11L4 6H20ZM20 18H4V8L12 13L20 8V18Z"
                fill="#161C1C"
              />
            </svg>
            <span className={css.ml12}>{user?.user?.mail?.value}</span>
            <br />
            {/* <span className={css.ml24}>{delivery.address2?.value}</span> */}
          </div>
          <span></span>
        </div>

        <hr className={classNames(css.mb24, css.lineM, css.mt24, css.shippingPadding)} />
        <div className={css.shippingPadding}>
          <div className={css.flex}>
            <p className={css.t1}>配送</p>
          </div>
          <div className={css.flex_start}>
            <img src={`${process.env.PUBLIC_URL}/thanks_shipping.svg`} alt="" className={classNames(css.thanks_icon)} />
            <span className={css.ml24}>
              {delivery?.selectedAddress?.lName?.value || null}
              {delivery?.selectedAddress?.fName?.value || null}
            </span>
            <br />
            <span className={css.ml48}>
              {delivery?.selectedAddress?.lNameKana?.value || null}
              {delivery?.selectedAddress?.fNameKana?.value || null}
            </span>
            <br />
            <span className={css.ml48}>
              〒
              {delivery?.selectedAddress?.postalCode?.value
                ? delivery?.selectedAddress?.postalCode?.value.slice(0, 3) +
                  '-' +
                  delivery?.selectedAddress?.postalCode?.value.slice(3, 7)
                : null}
            </span>
            <br />
            <span className={css.ml48}>
              {delivery?.selectedAddress?.postalCode?.value2 || null}
              {delivery?.selectedAddress?.address1?.value || null}
              {delivery?.selectedAddress?.address2?.value || null}
            </span>
            <br />
            <span className={css.ml48}>{delivery?.selectedAddress?.address3?.value || null}</span>
            <br />
            <span className={css.ml48}>{delivery?.selectedAddress?.phoneNum?.value}</span>
          </div>
          <br />
          <div className={css.flex_start}>
            <img src={`${process.env.PUBLIC_URL}/thanks_calendar.svg`} alt="" className={classNames(css.thanks_icon)} />
            <span className={css.ml24}>
              {delivery?.dateTime?.fulldate || null}
              {delivery?.dateTime?.clockTime || null}
            </span>
            <br />
          </div>
        </div>

        {/* <div>
                    <hr className={classNames(css.mb12, css.mt24, css.lineS)} />
                    <div className={css.flex}><p className={css.t1}>配送</p></div>
                    <div className={css.flex_start}>
                        <svg width="22" height="24" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 4H16V0H2C0.9 0 0 0.9 0 2V13H2C2 14.66 3.34 16 5 16C6.66 16 8 14.66 8 13H14C14 14.66 15.34 16 17 16C18.66 16 20 14.66 20 13H22V8L19 4ZM18.5 5.5L20.46 8H16V5.5H18.5ZM5 14C4.45 14 4 13.55 4 13C4 12.45 4.45 12 5 12C5.55 12 6 12.45 6 13C6 13.55 5.55 14 5 14ZM7.22 11C6.67 10.39 5.89 10 5 10C4.11 10 3.33 10.39 2.78 11H2V2H14V11H7.22ZM17 14C16.45 14 16 13.55 16 13C16 12.45 16.45 12 17 12C17.55 12 18 12.45 18 13C18 13.55 17.55 14 17 14Z" fill="#161C1C" />
                        </svg>
                        <span className={css.ml12}>お客様情報先と同じ</span>
                    </div>
                    <div className={classNames(css.flex_start, css.mt12)}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 3H19V1H17V3H7V1H5V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM20 21H4V10H20V21ZM20 8H4V5H20V8Z" fill="#161C1C" />
                        </svg>
                        <span className={css.ml12}>dateTime.date</span>
                        <br />
                        <span className={css.ml36}>dateTime.time</span>
                    </div>
                </div> */}
        <span></span>
        <div>
          <hr className={classNames(css.mb24, css.lineM, css.mt24, css.shippingPadding)} />
          <div className={classNames(css.flex, css.shippingPadding)}>
            <p className={css.t1}>決済</p>
          </div>
          <div className={classNames(css.flex_start, css.shippingPadding)}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M20 4H4C2.89 4 2.01 4.89 2.01 6L2 18C2 19.11 2.89 20 4 20H20C21.11 20 22 19.11 22 18V6C22 4.89 21.11 4 20 4ZM20 18H4V12H20V18ZM20 8H4V6H20V8Z"
                fill="black"
              />
            </svg>
            <span className={css.ml12}>クレジットカード</span>
            <div className={css.ml36}>
              ✳︎✳︎✳︎✳︎ ✳︎✳︎✳︎✳︎ ✳︎✳︎✳︎✳︎
              {/* <svg width="35" height="24" viewBox="0 0 35 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="34.9714" height="24" rx="2" fill="#161C1C" />
                                <path d="M21.3776 5.60352H13.5938V18.3954H21.3776V5.60352Z" fill="#FF5F00" />
                                <path d="M14.3948 11.9998C14.3939 10.7681 14.6714 9.55226 15.2063 8.44436C15.7413 7.33646 16.5198 6.36552 17.4827 5.60502C16.29 4.66189 14.8577 4.07541 13.3494 3.91262C11.8411 3.74982 10.3177 4.01728 8.95335 4.68442C7.58898 5.35156 6.43868 6.39146 5.6339 7.68528C4.82912 8.9791 4.40234 10.4746 4.40234 12.001C4.40234 13.5273 4.82912 15.0228 5.6339 16.3166C6.43868 17.6105 7.58898 18.6504 8.95335 19.3175C10.3177 19.9846 11.8411 20.2521 13.3494 20.0893C14.8577 19.9265 16.29 19.34 17.4827 18.3969C16.5195 17.6362 15.7408 16.6649 15.2059 15.5565C14.6709 14.4482 14.3935 13.232 14.3948 11.9998Z" fill="#EB001B" />
                                <path d="M29.7917 17.041V16.7788H29.904V16.7246H29.6367V16.7788H29.7423V17.041H29.7917ZM30.3104 17.041V16.7246H30.2296L30.1353 16.9506L30.041 16.7246H29.9601V17.041H30.0185V16.8014L30.1061 17.0071H30.1667L30.2543 16.8014V17.041H30.3104Z" fill="#F79E1B" />
                                <path d="M30.566 12.0001C30.5659 13.5265 30.1391 15.0222 29.3341 16.316C28.5292 17.6099 27.3787 18.6498 26.0142 19.3168C24.6497 19.9839 23.1261 20.2512 21.6177 20.0881C20.1093 19.9251 18.677 19.3383 17.4844 18.3949C18.447 17.6337 19.2252 16.6625 19.7603 15.5546C20.2954 14.4466 20.5735 13.2308 20.5735 11.999C20.5735 10.7671 20.2954 9.55133 19.7603 8.44337C19.2252 7.33541 18.447 6.36421 17.4844 5.60303C18.677 4.6596 20.1093 4.07284 21.6177 3.90981C23.1261 3.74679 24.6497 4.01407 26.0142 4.68111C27.3787 5.34816 28.5292 6.38804 29.3341 7.68191C30.1391 8.97577 30.5659 10.4714 30.566 11.9978V12.0001Z" fill="#F79E1B" />
                            </svg> */}
              <span className={classNames(css.ml12)}>{card.last4}</span>
            </div>
          </div>
        </div>
        <WhiteBoxButton
          onClick={() => {
            handleLinkClick();
          }}
          text="TOPへ"
        />
      </div>
    </div>
  );
};

export default Thanks;
