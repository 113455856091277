import css from './index.module.scss';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { db } from '../zz_general/utils/configs/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { SecretAnimation } from './secretAnimation';

export function Secret() {
  const [correctAns, setCorrectAns] = useState('');
  const [ans, setAns] = useState('');
  const [question, setQuestion] = useState('');
  const [hexString, setHexString] = useState('');
  const [sendButton, setSendButton] = useState(false);
  const [achiever, setAchiever] = useState('');
  const [watchword, setWatchword] = useState('');
  const [correct, setCorrect] = useState(false);
  const [mistake, setMistake] = useState(false);
  const [mistakeCount, setMistakeCount] = useState(0);
  const [animeFlag, setAnimeFlag] = useState(0);

  const updateAnimeFlag = (value) => {
    setAnimeFlag(value);
  };

  const handleChange = (event) => {
    setAns(event.target.value);
    if (event.target.value) {
      setSendButton(true);
    } else {
      setSendButton(false);
    }
  };

  const handleClick = () => {
    setSendButton(false);
    if (ans === correctAns) {
      setCorrect(true);
    } else {
      setMistake(true);
      setMistakeCount(mistakeCount + 1);
    }
  };

  // 文字列をUTF-8のバイト文字列に変換する関数
  const stringToBinary = (str) => {
    const binary = [];
    for (let i = 0; i < str.length; i++) {
      const charCode = str.charCodeAt(i);
      if (charCode < 0x80) {
        binary.push(charCode);
      } else if (charCode < 0x800) {
        binary.push(0xc0 | (charCode >> 6));
        binary.push(0x80 | (charCode & 0x3f));
      } else {
        binary.push(0xe0 | (charCode >> 12));
        binary.push(0x80 | ((charCode >> 6) & 0x3f));
        binary.push(0x80 | (charCode & 0x3f));
      }
    }
    return binary;
  };

  // バイト文字列を16進数表現に変換する関数
  function binaryToHex(binary) {
    return binary.map((byte) => byte.toString(16).padStart(2, '0')).join('');
  }

  // 16進数を暗号文風に整形
  function capitalizeAndInsertSpace(str) {
    let result = '';
    for (let i = 0; i < str.length; i += 2) {
      const chunk = str.substr(i, 2);
      result += chunk.toUpperCase() + ' ';
    }
    return result.trim();
  }

  // クリップボードへコピー
  function copyToClipboard(value) {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        alert('クリップボードに内容をコピーしました。');
      })
      .catch((err) => {
        alert('クリップボードへのアクセスが許可されていません。');
        // eslint-disable-next-line no-console
        console.log(err);
      });
  }

  // 改行を<br>タグに変換
  const convertNewlinesToBr = (str) => {
    const str_lst = str.split('\\n');
    return str_lst.map((line, index, array) =>
      index === array.length - 1 ? (
        line
      ) : (
        <>
          {line}
          <br />
        </>
      )
    );
  };

  useEffect(() => {
    async function getAnswer() {
      const docRef = doc(db, 'apps', 'OXSo9rkE1dm9SRqfTsTj');
      const docSnap = await getDoc(docRef);
      return docSnap.data();
    }
    const promise = getAnswer();
    promise
      .then((response) => {
        setAchiever(response.achiever);
        setWatchword(response.watchword);
        const answerText = response.answer;
        setCorrectAns(answerText);
        const binary = stringToBinary(answerText);
        const hexString = binaryToHex(binary);
        setHexString(hexString);
        setQuestion(capitalizeAndInsertSpace(hexString));
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className={classNames(css.secret)}>
      <SecretAnimation animeFlag={animeFlag} setAnimeFlag={updateAnimeFlag} />
      <div className={animeFlag > 4 ? css.questionWrap : css.displayNone}>
        <div className={css.instruction}>この暗号を解いて、答えを入力してください</div>
        <h1 className={classNames(css.question)}>
          {question}
          <br />
          <div className={classNames(css.copyButtonCon)}>
            <button
              className={classNames(css.copyButton, css.button)}
              onClick={() => {
                copyToClipboard(hexString);
              }}
            >
              問題をコピー
            </button>
          </div>
        </h1>
        <br />
        <input
          placeholder="解答を記入してください"
          className={classNames(css.inputBox)}
          onChange={(e) => {
            handleChange(e);
          }}
        ></input>
        <br />
        <br />
        <button
          className={classNames(css.sendButton, css.button, sendButton ? '' : css.sendButtonOff)}
          onClick={() => {
            handleClick();
          }}
        >
          解答する
        </button>
        {correct ? (
          <div className={classNames(css.correct, css.result)}>
            <div
              onClick={() => {
                setCorrect(false);
                setSendButton(true);
              }}
              className={classNames(css.crossIcon)}
            >
              ×
            </div>
            <h1>
              正解です!!
              <br />
              <br />
              あなたは{achiever + 1}人目の正解者です。
              <br />
              <br />
              BENE REGALOの店舗で下記の注文をしてください。 <br />
              <br />「{convertNewlinesToBr(watchword)}」
              <br />
              <br />
              <div className={classNames(css.copyButtonCon)}>
                <button
                  className={classNames(css.copyButton, css.button)}
                  onClick={() => {
                    copyToClipboard(watchword);
                  }}
                >
                  あいことばをコピー
                </button>
              </div>
            </h1>
          </div>
        ) : (
          ''
        )}
        {mistake ? (
          <div className={classNames(css.mistake, css.result)}>
            <div
              onClick={() => {
                setMistake(false);
                setSendButton(true);
              }}
              className={classNames(css.crossIcon)}
            >
              ×
            </div>
            <h1>
              残念、不正解...
              <br />
              <br />
              <div className={css.hint}>
                {mistakeCount >= 1 ? <span>hint: アルファベットはFまでしかありえない</span> : ''}
                {/* <br />
                <br />
                {mistakeCount >= 2 ? <span>hint2: アルファベットはFまでしかありえない</span> : ''}
                <br />
                <br />
                {mistakeCount >= 3 ? <span>hint3: アルファベットはFまでしかありえない</span> : ''} */}
              </div>
            </h1>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
