import css from '../index.module.scss';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdOutlineKeyboardArrowUp } from 'react-icons/md';
import classNames from 'classnames';
import { useRecoilState } from 'recoil';
import { termsAndPolicyState } from './atoms';

export function Policy() {
  const [termsAndPolicy, setTermsAndPolicy] = useRecoilState(termsAndPolicyState);
  const [showIndex, setShowIndex] = useState(false);
  const [showPageTopButton, setShowPageTopButton] = useState(false);

  const updateTermsAndPolicy = (boolean) => {
    setTermsAndPolicy((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future['terms'] = boolean;
      return future;
    });
  };
  const showIndexToggle = () => {
    setShowIndex(!showIndex);
  };

  const returnTop = (how) => {
    window.scroll({
      top: 0,
      behavior: how,
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollWindow);
    return () => {
      window.removeEventListener('scroll', scrollWindow);
    };
  }, []);

  const scrollWindow = () => {
    const top = 100; //ボタンを表示させたい位置
    let scroll = 0;
    scroll = window.scrollY;
    if (top <= scroll) {
      setShowPageTopButton(true);
    } else {
      setShowPageTopButton(false);
    }
  };

  const navigate = useNavigate();
  const handleClick = () => {
    navigate(termsAndPolicy.backPage);
  };

  return (
    <div className={`${css.Policy}`}>
      <div className={showIndex ? `${css.Policy_Index}` : `${css.displayNone}`}>
        <div className={termsAndPolicy.terms ? `${css.Policy_Index_1}` : `${css.displayNone}`}>
          <div className={`${css.Policy_Index_Header}`}>
            利用規約
            <img
              src={`${process.env.PUBLIC_URL}/Vector.svg`}
              alt=""
              className={css.Policy_Img3}
              onClick={showIndexToggle}
            />
          </div>
          <br />
          <br />
          <a href="#title1" onClick={showIndexToggle}>
            第1条（適用）
          </a>
          <br />
          <br />
          <a href="#title2" onClick={showIndexToggle}>
            第2条（定義）
          </a>
          <br />
          <br />
          <a href="#title3" onClick={showIndexToggle}>
            第3条（登録）
          </a>
          <br />
          <br />
          <a href="#title4" onClick={showIndexToggle}>
            第4条（登録事項の変更）
          </a>
          <br />
          <br />
          <a href="#title5" onClick={showIndexToggle}>
            第5条（パスワード及びユーザーIDの管理）
          </a>
          <br />
          <br />
          <a href="#title6" onClick={showIndexToggle}>
            第6条（料金及び支払方法）
          </a>
          <br />
          <br />
          <a href="#title7" onClick={showIndexToggle}>
            第7条（禁止事項）
          </a>
          <br />
          <br />
          <a href="#title8" onClick={showIndexToggle}>
            第8条（本サービスの停止等）
          </a>
          <br />
          <br />
          <a href="#title9" onClick={showIndexToggle}>
            第9条（権利帰属）
          </a>
          <br />
          <br />
          <a href="#title10" onClick={showIndexToggle}>
            第10条（登録抹消等）
          </a>
          <br />
          <br />
          <a href="#title11" onClick={showIndexToggle}>
            第11条（退会）
          </a>
          <br />
          <br />
          <a href="#title12" onClick={showIndexToggle}>
            第12条（本サービスの内容の変更、終了）
          </a>
          <br />
          <br />
          <a href="#title13" onClick={showIndexToggle}>
            第13条（保証の否認及び免責）
          </a>
          <br />
          <br />
          <a href="#title14" onClick={showIndexToggle}>
            第14条（秘密保持）
          </a>
          <br />
          <br />
          <a href="#title15" onClick={showIndexToggle}>
            第15条（利用者情報の取扱い）
          </a>
          <br />
          <br />
          <a href="#title16" onClick={showIndexToggle}>
            第16条（本規約等の変更）
          </a>
          <br />
          <br />
          <a href="#title17" onClick={showIndexToggle}>
            第17条（連絡／通知）
          </a>
          <br />
          <br />
          <a href="#title18" onClick={showIndexToggle}>
            第18条（サービス利用契約上の地位の譲渡等）
          </a>
          <br />
          <br />
          <a href="#title19" onClick={showIndexToggle}>
            第19条（分離可能性）
          </a>
          <br />
          <br />
          <a href="#title20" onClick={showIndexToggle}>
            第20条（準拠法及び管轄裁判所）
          </a>
          <br />
          <br />
        </div>
        <div className={termsAndPolicy.terms ? `${css.displayNone}` : `${css.Policy_Index_2}`}>
          <div className={`${css.Policy_Index_Header}`}>
            プライバシーポリシー
            <img
              src={`${process.env.PUBLIC_URL}/Vector.svg`}
              alt=""
              className={css.Policy_Img3}
              onClick={showIndexToggle}
            />
          </div>
          <br />
          <br />
          <a href="#titleB1" onClick={showIndexToggle}>
            1. 収集する利用者情報及び収集方法
          </a>
          <br />
          <br />
          <a href="#titleB2" onClick={showIndexToggle}>
            2. 利用目的
          </a>
          <br />
          <br />
          <a href="#titleB3" onClick={showIndexToggle}>
            3. 通知・公表または同意取得の方法、利用中止要請の方法
          </a>
          <br />
          <br />
          <a href="#titleB4" onClick={showIndexToggle}>
            4. 外部送信、第三者提供、情報収集モジュールの有無
          </a>
          <br />
          <br />
          <a href="#titleB5" onClick={showIndexToggle}>
            5. 第三者提供
          </a>
          <br />
          <br />
          <a href="#titleB6" onClick={showIndexToggle}>
            6. 共同利用
          </a>
          <br />
          <br />
          <a href="#titleB7" onClick={showIndexToggle}>
            7. 個人情報の開示
          </a>
          <br />
          <br />
          <a href="#titleB8" onClick={showIndexToggle}>
            8. 個人情報の訂正及び利用停止等
          </a>
          <br />
          <br />
          <a href="#titleB9" onClick={showIndexToggle}>
            9. お問い合わせ窓口
          </a>
          <br />
          <br />
          <a href="#titleB10" onClick={showIndexToggle}>
            10. プライバシーポリシーの変更手続
          </a>
          <br />
          <br />
        </div>
      </div>
      <div className={css.header_container}>
        <div className={`${css.Policy_Header}`}>
          <img
            src={`${process.env.PUBLIC_URL}/Policy_Index_Logo.svg`}
            alt=""
            className={css.Policy_Img2}
            onClick={showIndexToggle}
          />
          <div onClick={handleClick}>
            <img src={`${process.env.PUBLIC_URL}/Vector.svg`} alt="" className={css.Policy_Img1} />
          </div>
        </div>
        <div className={`${css.Policy_Tabs}`}>
          <div
            onClick={() => {
              updateTermsAndPolicy(true);
              returnTop('auto');
            }}
            className={termsAndPolicy.terms ? `${css.Policy_Tab_1}` : `${css.deactivate1}`}
          >
            利用規約
          </div>
          <div
            onClick={() => {
              updateTermsAndPolicy(false);
              returnTop('auto');
            }}
            className={termsAndPolicy.terms ? `${css.deactivate2}` : `${css.Policy_Tab_2}`}
          >
            プライバシーポリシー
          </div>
        </div>
      </div>
      <div className={`${css.Policy_Container}`}>
        <div className={`${css.Policy_Body}`}>
          <div className={termsAndPolicy.terms ? `${css.Policy_Body_1}` : `${css.displayNone}`}>
            <div className={`${css.Policy_Body_SubTitle}`}>利用規約</div>
            <div className={`${css.Policy_Body_Text}`}>
              本利用規約（以下「本規約」と言います。）には、本サービスの提供条件及び当社と登録ユーザーの皆様との間の権利義務関係が定められています。本サービスの利用に際しては、本規約の全文をお読みいただいたうえで、本規約に同意いただく必要があります。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>重要な点</div>
            <a href=" " name="title1" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_GrayText}`}>
              どの企業、どのサービスにも利用規約は存在します。簡潔にいうと、当社と皆さまの約束になります。不当に皆さまの権利を阻害しないよう、最新の注意を払った上で設定しておりますが、納得が難しい部分があるかもしれませんので、出来る限り目を通して欲しいと考えています。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>第一条（適用）</div>
            <div className={`${css.Policy_Body_Text}`}>
              1.
              本規約は、本サービスの提供条件及び本サービスの利用に関する当社と登録ユーザーとの間の権利義務関係を定めることを目的とし、登録ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されます。
              <br />
              2. 当社が当社ウェブサイト上で掲載する本サービス利用に関する規制（
              <a href="https://bene-regalo.com" target="_blank" rel="noopener noreferrer">
                https://bene-regalo.com
              </a>
              ）は、本規約の一部を構成するものとします。 <br />
              3.
              本規約の内容と、前項の規約その他の本規約外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>重要な点</div>
            <a href=" " name="title2" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_GrayText}`}>
              利用規約は当社と皆さまとの間で交わされる約束の内容を明確するものです。事前に権利と義務の境界線を出来る限り明確にすることで無駄な争い等を避ける意図で設定されています。
              <br />
              このページ内に記載されていない規制も規約の一部と考えます。ただし、この規約と異なる場合は、この規約を優先して適応します。また、重要な点での記載においてもこの規約と解釈が異なる場合、この規約を優先して適応します。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>第二条（定義）</div>
            <div className={`${css.Policy_Body_Text}`}>
              本規約において使用する以下の用語は、各々以下に定める意味を有するものとします。
              <br />
              4.
              「サービス利用契約」とは、本規約を契約条件として当社と登録ユーザーの間で締結される、本サービスの利用契約を意味します。
              <br />
              5.
              「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）を意味します。
              <br />
              6.
              「投稿データ」とは、登録ユーザーが本サービスを利用して投稿その他送信するコンテンツ（文章、画像、動画その他のデータを含みますがこれらに限りません。）を意味します。
              <br />
              7. 「当社」とは、株式会社Eight Goodを意味します。
              <br />
              8.
              「当社ウェブサイト」とは、そのドメインが「eightgood.com」、「bene-regalo.com」である、当社が運営するウェブサイト（理由の如何を問わず、当社のウェブサイトのドメインまたは内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。
              <br />
              9.
              「登録ユーザー」とは、第3条（登録）に基づいて本サービスの利用者としての登録がなされた個人または法人を意味します。
              <br />
              10. 「本サービス」とは、当社が提供するBENE
              REGALOという名称のサービス（理由の如何を問わずサービスの名称または内容が変更された場合は、当該変更後のサービスを含みます。）を意味します。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>重要な点</div>
            <a href=" " name="title3" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_GrayText}`}>
              この規約で多く使われる言葉を短く表記するために定義をまとめています。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>第3条（登録）</div>
            <div className={`${css.Policy_Body_Text}`}>
              11.
              本サービスの利用を希望する者（以下「登録希望者」といいます。）は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録事項」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。
              <br />
              12.
              当社は、当社の基準に従って、第１項に基づいて登録申請を行った登録希望者（以下「登録申請者」といいます。）の登録の可否を判断し、当社が登録を認める場合にはその旨を登録申請者に通知します。登録申請者の登録ユーザーとしての登録は、当社が本項の通知を行ったことをもって完了したものとします。
              <br />
              13.
              前項に定める登録の完了時に、サービス利用契約が登録ユーザーと当社の間に成立し、登録ユーザーは本サービスを本規約に従い利用することができるようになります。
              <br />
              14.
              当社は、登録申請者が、以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあり、またその理由について一切開示義務を負いません。
              <br />
              （1）当社に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合
              <br />
              （2）未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合
              <br />
              （3）反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合
              <br />
              （4）過去当社との契約に違反した者またはその関係者であると当社が判断した場合
              <br />
              （5）第10条に定める措置を受けたことがある場合
              <br />
              （6）その他、登録を適当でないと当社が判断した場合
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>重要な点</div>
            <a href=" " name="title4" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_GrayText}`}>
              当社の設定した全ての規約に同意をすることがサービスを利用する条件です。偉そうなことを言ってしまい申し訳ありません。利用していただける皆さまに心から感謝しています。ただ、いくつかの条件に当てはまった場合は利用出来なくなる場合があります。「嘘をつかない、親の同意なしに大金を使わない、反社会的勢力に加担しない、当社が想定も出来ないほどの悪いことをしない」を心掛けていただけると利用する皆さまが優しい、素晴らしいサービスになります。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>第4条（登録事項の変更）</div>
            <div className={`${css.Policy_Body_Text}`}>
              登録ユーザーは、登録事項に変更があった場合、当社の定める方法により当該変更事項を遅滞なく当社に通知するものとします。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>重要な点</div>
            <a href=" " name="title5" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_GrayText}`}>
              何か登録事項に変更があったときは、出来る限り早く教えてくれると嬉しいです。連絡先は何か重要な事情があった際に直接お伝えする手段がなくなってしまうので、教えてくれると跳び上がって喜びます。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>第5条（パスワード及びユーザーIDの管理）</div>
            <div className={`${css.Policy_Body_Text}`}>
              15.
              登録ユーザーは、自己の責任において、本サービスに関するパスワード及びユーザーIDを適切に管理及び保管するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更、売買等をしてはならないものとします。
              <br />
              16.
              パスワードまたはユーザーIDの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任は登録ユーザーが負うものとします。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>重要な点</div>
            <a href=" " name="title6" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_GrayText}`}>
              アカウント情報はご自身で管理してください。また、トラブルの原因にもなりますのでアカウントの共有はご遠慮していただけると嬉しいです。当社が関係のない範囲でのアカウント情報による損失はご自身で責任を負っていただきます。十分にご注意ください。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>第6条（料金及び支払方法）</div>
            <div className={`${css.Policy_Body_Text}`}>
              17.
              登録ユーザーは、本サービス利用の対価として、別途当社が定めた利用料金を、当社が指定する支払方法により当社に支払うものとします。
              <br />
              18.
              登録ユーザーが利用料金の支払を遅滞した場合、登録ユーザーは年14.6％の割合による遅延損害金を当社に支払うものとします。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>重要な点</div>
            <a href=" " name="title7" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_GrayText}`}>
              サービス利用代金のお支払いをお願いしています。何卒お願いします。遅延した場合の料率「14.6%」は日割り計算をしやすい（365で割るとちょうど0.04）のでよく使われているようで、そちらを採用しております。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>第7条（禁止事項）</div>
            <div className={`${css.Policy_Body_Text}`}>
              登録ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると当社が判断する行為をしてはなりません。
              <br />
              （1）法令に違反する行為または犯罪行為に関連する行為
              <br />
              （2）当社、本サービスの他の利用者またはその他の第三者に対する詐欺または脅迫行為
              <br />
              （3）公序良俗に反する行為
              <br />
              （4）当社、本サービスの他の利用者またはその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為
              <br />
              （5）本サービスを通じ、以下に該当し、または該当すると当社が判断する情報を当社または本サービスの他の利用者に送信すること
              <br />
              （6）過度に暴力的または残虐な表現を含む情報
              <br />
              （7）コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報
              <br />
              （8）当社、本サービスの他の利用者またはその他の第三者の名誉または信用を毀損する表現を含む情報
              <br />
              （9）過度にわいせつな表現を含む情報
              <br />
              （10）差別を助長する表現を含む情報
              <br />
              （11）自殺、自傷行為を助長する表現を含む情報
              <br />
              （12）薬物の不適切な利用を助長する表現を含む情報
              <br />
              （13）反社会的な表現を含む情報
              <br />
              （14）チェーンメール等の第三者への情報の拡散を求める情報
              <br />
              （15）他人に不快感を与える表現を含む情報
              <br />
              19. 本サービスのネットワークまたはシステム等に過度な負荷をかける行為
              <br />
              20. 当社が提供するソフトウェアその他のシステムに対するリバースエンジニアリングその他の解析行為
              <br />
              21. 本サービスの運営を妨害するおそれのある行為
              <br />
              22. 当社のネットワークまたはシステム等への不正アクセス
              <br />
              23. 第三者に成りすます行為
              <br />
              24. 本サービスの他の利用者のIDまたはパスワードを利用する行為
              <br />
              25. 当社が事前に許諾しない本サービス上での宣伝、広告、勧誘、または営業行為
              <br />
              26. 本サービスの他の利用者の情報の収集
              <br />
              27. 当社、本サービスの他の利用者またはその他の第三者に不利益、損害、不快感を与える行為
              <br />
              28. 当社ウェブサイト上で掲載する本サービス利用に関するルール（http://.......）に抵触する行為
              <br />
              29. 反社会的勢力等への利益供与
              <br />
              30. 面識のない人との出会いを目的とした行為 <br />
              31. 前各号の行為を直接または間接に惹起し、または容易にする行為
              <br />
              32. 前各号の行為を試みること
              <br />
              33. その他、当社が不適切と判断する行為
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>重要な点</div>
            <a href=" " name="title8" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_GrayText}`}>
              多くて申し訳ありません。他人に迷惑をかけるような行動は慎みましょう。周りの人に優しくなりましょう。きっとその優しさは返ってくることでしょう。
              <br />
              また、規制があるため、出会いを目的としてのご利用はお控えください。
              <br />
              禁止事項にある行為を発見した場合、予告なくサービスの利用を停止する場合があります。
              <br />
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>第8条（本サービスの停止等）</div>
            <div className={`${css.Policy_Body_Text}`}>
              当社は、以下のいずれかに該当する場合には、登録ユーザーに事前に通知することなく、本サービスの全部または一部の提供を停止または中断することができるものとします。
              <br />
              34. 本サービスに係るコンピューター・システムの点検または保守作業を緊急に行う場合
              <br />
              35.
              コンピューター、通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキング等により本サービスの運営ができなくなった場合
              <br />
              36. 地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合
              <br />
              37. その他、当社が停止または中断を必要と判断した場合
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>重要な点</div>
            <a href=" " name="title9" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_GrayText}`}>
              やむを得ない場合、予告なくサービスの全てまたは一部を停止、中断する場合があります。なるべく皆さまにご迷惑をおかけしないよう対応します。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>第9条（権利帰属）</div>
            <div className={`${css.Policy_Body_Text}`}>
              38.
              当社ウェブサイト及び本サービスに関する知的財産権は全て当社または当社にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、当社ウェブサイトまたは本サービスに関する当社または当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。
              <br />
              39.
              登録ユーザーは、投稿データについて、自らが投稿その他送信することについての適法な権利を有していること、及び投稿データが第三者の権利を侵害していないことについて、当社に対し表明し、保証するものとします。
              <br />
              40.
              登録ユーザーは、投稿データについて、当社に対し、世界的、非独占的、無償、サブライセンス可能かつ譲渡可能な使用、複製、配布、派生著作物の作成、表示及び実行に関するライセンスを付与します。また、他の登録ユーザーに対しても、本サービスを利用して登録ユーザーが投稿その他送信した投稿データの使用、複製、配布、派生著作物を作成、表示及び実行することについての非独占的なライセンスを付与します。
              <br />
              41.
              登録ユーザーは、当社及び当社から権利を承継しまたは許諾された者に対して著作者人格権を行使しないことに同意するものとします。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>重要な点</div>
            <a href=" " name="title10" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_GrayText}`}>
              当社のサイト、サービスに関する名前やデザインなどは当社または当社に権利を許諾している者に権利があります。サービスの利用によってその権利が皆さまに許諾されるわけではありません。
              <br />
              皆さんが投稿するデータは他人の権利を侵害していないか十分にご確認の上、ご使用ください。
              <br />
              投稿データはより良いサービスのため、当社に権利を付与していただきます。プライバシーポリシーにて出来る限り詳細について記載いたしますので、ご理解いただけますと嬉しいです。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>第10条（登録抹消等）</div>
            <div className={`${css.Policy_Body_Text}`}>
              42.
              当社は、登録ユーザーが、以下の各号のいずれかの事由に該当する場合は、事前に通知または催告することなく、投稿データを削除もしくは非表示にし、当該登録ユーザーについて本サービスの利用を一時的に停止し、または登録ユーザーとしての登録を抹消することができます。
              <br />
              （1）本規約のいずれかの条項に違反した場合
              <br />
              （2）登録事項に虚偽の事実があることが判明した場合
              <br />
              （3）支払停止もしくは支払不能となり、または破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合
              <br />
              （4）当社からの問い合わせその他の回答を求める連絡に対して30日間以上応答がない場合
              <br />
              （5）第3条第4項各号に該当する場合
              <br />
              （6）その他、当社が本サービスの利用または登録ユーザーとしての登録の継続を適当でないと判断した場合
              <br />
              43.
              前項各号のいずれかの事由に該当した場合、登録ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>重要な点</div>
            <a href=" " name="title11" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_GrayText}`}>
              約束を破る、嘘をつくなどはお控えください。誰も登録抹消されることがないことが理想です。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>第11条（退会）</div>
            <div className={`${css.Policy_Body_Text}`}>
              44.
              登録ユーザーは、当社所定の手続の完了により、本サービスから退会し、自己の登録ユーザーとしての登録を抹消することができます。
              <br />
              45.
              退会にあたり、当社に対して負っている債務が有る場合は、登録ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
              <br />
              46. 退会後の利用者情報の取扱いについては、第15条の規定に従うものとします。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>重要な点</div>
            <a href=" " name="title12" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_GrayText}`}>
              皆さんは決められた手続きをとって解約することが出来ます。その場合、予め発生した費用に関してはお支払いください。ご登録時にいただいた情報についてはこの規約の第15条に従って適切に取扱いさせていただきます。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>第12条（本サービスの内容の変更、終了）</div>
            <div className={`${css.Policy_Body_Text}`}>
              47. 当社は、当社の都合により、本サービスの内容を変更し、または提供を終了することができます。 <br />
              48. 当社が本サービスの提供を終了する場合、当社は登録ユーザーに事前に通知するものとします。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>重要な点</div>
            <a href=" " name="title13" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_GrayText}`}>
              状況に応じて、サービスの変更をする場合が考えられます。皆さんにご理解をいただいた上で変更手続き等させていただきます。また、なるべく継続するよう最大限努力いたしますが、サービスが終了してしまう場合は、事前にご連絡させていただきます。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>第13条（保証の否認及び免責）</div>
            <div className={`${css.Policy_Body_Text}`}>
              49.
              当社は、本サービスが登録ユーザーの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、登録ユーザーによる本サービスの利用が登録ユーザーに適用のある法令または業界団体の内部規則等に適合すること、継続的に利用できること、及び不具合が生じないことについて、明示又は黙示を問わず何ら保証するものではありません。
              <br />
              50.
              当社は、本サービスに関して登録ユーザーが被った損害につき、過去【12ヶ月】間に登録ユーザーが当社に支払った対価の金額を超えて賠償する責任を負わないものとし、また、付随的損害、間接損害、特別損害、将来の損害及び逸失利益にかかる損害については、賠償する責任を負わないものとします。
              <br />
              51.
              本サービスまたは当社ウェブサイトに関連して登録ユーザーと他の登録ユーザーまたは第三者との間において生じた取引、連絡、紛争等については、登録ユーザーが自己の責任によって解決するものとします。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>重要な点</div>
            <a href=" " name="title14" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_GrayText}`}>
              皆さんの全ての期待やニーズに答えられるサービスであることは言えません。このサービスが皆さんに損害を与えてしまった場合は、過去1年間でお支払いいただいた金額を上限として損害賠償をさせていただきます。なお、皆さんの中での争いは当事者間でご解決いただくようお願いします。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>第14条（秘密保持）</div>
            <div className={`${css.Policy_Body_Text}`}>
              登録ユーザーは、本サービスに関連して当社が登録ユーザーに対して秘密に取扱うことを求めて開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密に取扱うものとします。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>重要な点</div>
            <a href=" " name="title15" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_GrayText}`}>
              秘密は秘密にしておいてください。悪い秘密の一切ない運営を目指します。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>第15条（利用者情報の取扱い）</div>
            <div className={`${css.Policy_Body_Text}`}>
              52.
              当社による登録ユーザーの利用者情報の取扱いについては、別途当社プライバシーポリシー（https://★★★★）の定めによるものとし、登録ユーザーはこのプライバシーポリシーに従って当社が登録ユーザーの利用者情報を取扱うことについて同意するものとします。
              <br />
              53.
              当社は、登録ユーザーが当社に提供した情報、データ等を、個人を特定できない形での統計的な情報として、当社の裁量で、利用及び公開することができるものとし、登録ユーザーはこれに異議を唱えないものとします。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>重要な点</div>
            <a href=" " name="title16" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_GrayText}`}>
              皆さんの利用者情報はプライバシーポリシーに沿って、取扱います。サービス運営のために皆さんからいただいた情報の一部を個人が特定できない形でデータとして利用、公開する場合があります。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>第16条（本規約等の変更）</div>
            <div className={`${css.Policy_Body_Text}`}>
              54.
              当社は、当社が必要と認めた場合は、本規約を変更できるものとします。本契約を変更する場合、変更後の本規約の施行時期及び内容を当社ウェブサイト上での掲示その他の適切な方法により周知し、または登録ユーザーに通知します。但し、法令上登録ユーザーの同意が必要となるような内容の変更の場合は、当社所定の方法で登録ユーザーの同意を得るものとします。改訂版の発効後にBENE
              REGALOサービスにアクセスしたり、利用を継続したりすると、利用規約の改訂版に同意したものと見なされます。改訂版の利用規約に同意しない場合は、BENE
              REGALOサービスの利用を停止してください。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>重要な点</div>
            <a href=" " name="title17" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_GrayText}`}>
              状況に応じて、この規約は変更する場合があります。その変更が大幅な場合は、HPやサービス内などの皆さんが見やすい方法にて事前にご連絡をさせていただきます。改訂版の利用規約に同意しない場合は、サービスの利用を停止してください。重要な点については、分かりやすさを重視し告知なく日々修正しています。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>第17条（連絡／通知）</div>
            <a href=" " name="title18" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_Text}`}>
              55.
              本サービスに関する問い合わせその他登録ユーザーから当社に対する連絡または通知、及び本規約の変更に関する通知その他当社から登録ユーザーに対する連絡または通知は、当社の定める方法で行うものとします。
              <br />
              56.
              当社が登録事項に含まれるメールアドレスその他の連絡先に連絡または通知を行った場合、登録ユーザーは当該連絡または通知を受領したものとみなします。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>第18条（サービス利用契約上の地位の譲渡等）</div>
            <div className={`${css.Policy_Body_Text}`}>
              57.
              登録ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
              <br />
              58.
              当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びに登録ユーザーの登録事項その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、登録ユーザーは、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>重要な点</div>
            <a href=" " name="title19" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_GrayText}`}>
              勝手に他の人にサービスの権利や義務を譲ったり、処分したりしないでください。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>第19条（分離可能性）</div>
            <div className={`${css.Policy_Body_Text}`}>
              本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>重要な点</div>
            <a href=" " name="title20" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_GrayText}`}>
              この規約が万が一、他の法律などによって無効とされた場合でも、該当部分以外は問題ないとします。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>第20条（準拠法及び管轄裁判所）</div>
            <div className={`${css.Policy_Body_Text}`}>
              59. 本規約及びサービス利用契約の準拠法は日本法とします。
              <br />
              60.
              本規約またはサービス利用契約に起因し、または関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>重要な点</div>
            <div className={`${css.Policy_Body_GrayText}`}>
              日本の法律を守ります。もし裁判になった場合には東京地方裁判所で行います。
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
          <div className={termsAndPolicy.terms ? `${css.displayNone}` : `${css.Policy_Body_2}`}>
            <a href=" " name="titleB1" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_Text}`}>
              株式会社Eight
              Good（エイトグッド）（以下「当社」といいます。）は、当社の提供するサービス（以下「本サービス」といいます。）における、ユーザーについての個人情報を含む利用者情報の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>1. 収集する利用者情報及び収集方法</div>
            <div className={`${css.Policy_Body_Text}`}>
              本ポリシーにおいて、「利用者情報」とは、ユーザーの識別に係る情報、通信サービス上の行動履歴、その他ユーザーまたはユーザーの端末に関連して生成または蓄積された情報であって、本ポリシーに基づき当社が収集するものを意味するものとします。
              本サービスにおいて当社が収集する利用者情報は、その収集方法に応じて、以下のようなものとなります。
            </div>
            <div className={`${css.Policy_Body_Text}`}>
              1. ユーザーからご提供いただく情報
              <br />
              本サービスを利用するために、または本サービスの利用を通じてユーザーからご提供いただく情報は以下のとおりです。
              <br />
              ・氏名、生年月日、性別、職業等プロフィールに関する情報
              <br />
              ・メールアドレス、電話番号、住所等連絡先に関する情報
              <br />
              ・クレジットカード情報、銀行口座情報、電子マネー情報等決済手段に関する情報
              <br />
              ・ユーザーの肖像を含む静止画情報
              <br />
              ・入力フォームその他当社が定める方法を通じてユーザーが入力または送信する情報
            </div>
            <div className={`${css.Policy_Body_Text}`}>
              2.
              ユーザーが本サービスの利用において、他のサービスと連携を許可することにより、当該他のサービスからご提供いただく情報
              <br />
              ユーザーが、本サービスを利用するにあたり、ソーシャルネットワーキングサービス等の他のサービスとの連携を許可した場合には、その許可の際にご同意いただいた内容に基づき、以下の情報を当該外部サービスから収集します。
              <br />
              ・当該外部サービスでユーザーが利用するID
              <br />
              ・その他当該外部サービスのプライバシー設定によりユーザーが連携先に開示を認めた情報
            </div>
            <div className={`${css.Policy_Body_Text}`}>
              3. ユーザーが本サービスを利用するにあたって、当社が収集する情報
              <br />
              当社は、本サービスへのアクセス状況やそのご利用方法に関する情報を収集することがあります。これには以下の情報が含まれます。
              <br />
              ・リファラ
              <br />
              ・IPアドレス
              <br />
              ・サーバーアクセスログに関する情報
              <br />
              ・Cookie、ADID、IDFAその他の識別子
            </div>
            <a href=" " name="titleB2" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_Text}`}>
              4. ユーザーが本サービスを利用するにあたって、当社がユーザーの個別同意に基づいて収集する情報
              <br />
              当社は、ユーザーが3-1に定める方法により個別に同意した場合、当社は以下の情報を利用中の端末から収集します。
              <br />
              ・位置情報
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>2. 利用目的</div>
            <div className={`${css.Policy_Body_Text}`}>
              本サービスのサービス提供にかかわる利用者情報の具体的な利用目的は以下のとおりです。
            </div>
            <a href=" " name="titleB3" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_Text}`}>
              1.
              本サービスに関する登録の受付、本人確認、ユーザー認証、ユーザー設定の記録、利用料金の決済計算等本サービスの提供、維持、保護及び改善のため
              <br />
              2. ユーザーのトラフィック測定及び行動測定のため
              <br />
              3. 広告の配信、表示及び効果測定のため
              <br />
              4. 本サービスに関するご案内、お問い合わせ等への対応のため
              <br />
              5. 本サービスに関する当社の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため
              <br />
              6. 本サービスに関する規約等の変更などを通知するため
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>3. 通知・公表または同意取得の方法、利用中止要請の方法</div>
            <div className={`${css.Policy_Body_Text}`}>
              3-1. 以下の利用者情報については、その収集が行われる前にユーザーの同意を得るものとします。 ・位置情報
            </div>
            <a href=" " name="titleB4" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_Text}`}>
              3-2.
              ユーザーは、本サービスの所定の設定を行うことにより、利用者情報の全部または一部についてその収集又は利用の停止を求めることができ、この場合、当社は速やかに、当社の定めるところに従い、その利用を停止します。なお利用者情報の項目によっては、その収集または利用が本サービスの前提となるため、当社所定の方法により本サービスを退会した場合に限り、当社はその収集又は利用を停止します。
            </div>
            <div className={`${css.Policy_Body_Text}`}>3-3. Cookieによる追跡のオプトアウト（無効化）の方法</div>
            <div className={`${css.Policy_Body_SubTitle}`}>4. 外部送信、第三者提供、情報収集モジュールの有無</div>
            <a href=" " name="titleB5" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_Text}`}>
              4-1
              本サービスでは、以下の提携先が、ユーザーの端末にCookieを保存し、これを利用して利用者情報を蓄積及び利用している場合があります。
            </div>
            <div className={`${css.Policy_Body_Text}`}>
              4-2
              本サービスには以下の情報収集モジュールが組み込まれています。これに伴い、以下のとおり情報収集モジュール提供者（日本国外にある者を含みます。）への利用者情報の提供を行います。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>5. 第三者提供</div>
            <div className={`${css.Policy_Body_Text}`}>
              当社は、利用者情報のうち、個人情報については、あらかじめユーザーの同意を得ないで、第三者（日本国外にある者を含みます。）に提供しません。但し、次に掲げる必要があり第三者（日本国外にある者を含みます。）に提供する場合はこの限りではありません。
            </div>
            <a href=" " name="titleB6" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_Text}`}>
              1. 当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
              <br />
              2. 合併その他の事由による事業の承継に伴って個人情報が提供される場合
              <br />
              3. 第4項の定めに従って、提携先または情報収集モジュール提供者へ個人情報が提供される場合
              <br />
              4.
              国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合
              <br />
              5. その他、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）その他の法令で認められる場合
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>6. 共同利用</div>
            <div className={`${css.Policy_Body_Text}`}>当社は、以下のとおりユーザーの個人情報を共同利用します。</div>
            <a href=" " name="titleB7" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_Text}`}>
              1. 共同利用をする旨
              <br />
              2. 共同して利用される利用者情報の項目
              <br />
              3. 共同して利用する者の範囲
              <br />
              4. 利用する者の利用目的及び当該利用者情報の管理について責任を有する者の氏名または名称
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>7. 個人情報の開示</div>
            <a href=" " name="titleB8" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_Text}`}>
              当社は、ユーザーから、個人情報保護法の定めに基づき個人情報の開示を求められたときは、ユーザーご本人からのご請求であることを確認の上で、ユーザーに対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。なお、個人情報の開示につきましては、手数料（1件あたり1,000円）を頂戴しておりますので、あらかじめ御了承ください。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>8. 個人情報の訂正及び利用停止等</div>
            <div className={`${css.Policy_Body_Text}`}>
              8-1.
              当社は、ユーザーから、(1)個人情報が真実でないという理由によって個人情報保護法の定めに基づきその内容の訂正を求められた場合、及び(2)あらかじめ公表された利用目的の範囲を超えて取扱われているという理由または偽りその他不正の手段により収集されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止を求められた場合には、ユーザーご本人からのご請求であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正または利用停止を行い、その旨をユーザーに通知します。なお、訂正または利用停止を行わない旨の決定をしたときは、ユーザーに対しその旨を通知いたします。
            </div>
            <a href=" " name="titleB9" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_Text}`}>
              8-2.
              当社は、ユーザーから、ユーザーの個人情報について消去を求められた場合、当社が当該請求に応じる必要があると判断した場合は、ユーザーご本人からのご請求であることを確認の上で、個人情報の消去を行い、その旨をユーザーに通知します。
            </div>
            <div className={`${css.Policy_Body_Text}`}>
              8-3.
              個人情報保護法その他の法令により、当社が訂正等または利用停止等の義務を負わない場合は、8-1および8-2の規定は適用されません。
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>9. お問い合わせ窓口</div>
            <div className={`${css.Policy_Body_Text}`}>
              ご意見、ご質問、苦情のお申出その他利用者情報の取扱いに関するお問い合わせは、下記の窓口までお願いいたします。
            </div>
            <a href=" " name="titleB10" className={`${css.anchor}`}></a>
            <div className={`${css.Policy_Body_Text}`}>
              住所：〒197-0825 東京都あきる野市雨間308-23 パルテールIII 203
              <br />
              株式会社Eight Good
              <br />
              個人情報取扱責任者：井下田 淳
              <br />
              連絡先：privacy@eightgood.com
            </div>
            <div className={`${css.Policy_Body_SubTitle}`}>10. プライバシーポリシーの変更手続</div>
            <div className={`${css.Policy_Body_Text}`}>
              当社は、必要に応じて、本ポリシーを変更します。但し、法令上ユーザーの同意が必要となるような本ポリシーの変更を行う場合、変更後の本ポリシーは、当社所定の方法で変更に同意したユーザーに対してのみ適用されるものとします。なお、当社は、本ポリシーを変更する場合には、変更後の本ポリシーの施行時期及び内容を当社のウェブサイト上での表示その他の適切な方法により周知し、またはユーザーに通知します。
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
      <div className={css.Policy_and_Transaction_footer}>
        <div
          onClick={() => {
            returnTop('smooth');
          }}
          className={showPageTopButton ? css.pageTop_Button : css.displayNone}
        >
          <p>
            <MdOutlineKeyboardArrowUp className={classNames(css.vertAlM, css.wh24)} />
            <div>Page Top</div>
          </p>
        </div>
        <div>
          <div onClick={handleClick} className={css.return_button}>
            ← 戻る
          </div>
          {/* <Link to={'/'} className={css.return_button}>
                            ← 戻る
                        </Link> */}
        </div>
      </div>
    </div>
  );
}
